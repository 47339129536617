import { z } from "zod";

/**
 * A transformed file object type that works with the file upload service within
 * the Data Platform API
 * @see {@link https://admin-staging.dataplatform.validere.xyz/docs#/Files}
 */
export const FileUploadedSchema = z
  .object({
    ref: z.string(),
    name: z.string(),
  })
  .describe("Represents a file uploaded to the Data Platform API");

export type FileUploadedType = z.infer<typeof FileUploadedSchema>;
