import { useUpdateCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import useLocalization from "#src/hooks/useLocalization";
import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  RadioInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  AttributeDataType,
  CustomAttributeSchema,
  CustomAttributeType,
} from "@validereinc/domain";
import React, { useEffect } from "react";
import { FlowTypeDropdownInput } from "../Dropdowns";

export const EditCustomAttributeDialog = ({
  isOpen,
  onClose,
  assetType,
  itemToUpdate,
}: {
  isOpen: boolean;
  onClose: () => void;
  assetType: AssetTypeType;
  itemToUpdate: CustomAttributeType;
}) => {
  const { localize } = useLocalization();
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  const form = useForm({
    defaultValues: {
      display_name: itemToUpdate?.display_name,
      description: itemToUpdate?.description,
      is_required: itemToUpdate?.is_required,
      entity_subtypes: itemToUpdate?.entity_subtypes,
      pick_list_values: Array.isArray(itemToUpdate?.pick_list_values)
        ? itemToUpdate?.pick_list_values.join(", ")
        : itemToUpdate?.pick_list_values,
      lookup_entity_type: itemToUpdate?.lookup_entity_type,
      unit: itemToUpdate?.unit,
    },
  });

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const onSuccess = () => {
    onClose?.();
  };

  const dataType = itemToUpdate?.data_type;

  const createCustomAttribute = useUpdateCustomAttributes({ onSuccess });
  const handleSubmit = form.handleSubmit((values) => {
    const {
      display_name,
      description,
      entity_subtypes,
      pick_list_values,
      lookup_entity_type,
      is_required,
    } = values;
    const field_name = itemToUpdate.field_name;
    const entity_type = assetType;

    createCustomAttribute.mutate({
      id: field_name,
      data: {
        entity_type,
        display_name,
        description,
        entity_subtypes: entity_subtypes?.length ? entity_subtypes : undefined,
        is_required,
        lookup_entity_type,
        pick_list_values: pick_list_values
          ? pick_list_values.split(",").map((item) => item.trim())
          : undefined,
      },
    });
  });
  const assetTypeOptions = Object.entries(AssetType).map(([key, value]) => ({
    label: value,
    id: key,
  }));

  return (
    <Dialog
      title="Edit Custom Attribute"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="edit-custom-attribute"
          variant="primary"
          onClick={handleSubmit}
        >
          Edit
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name={CustomAttributeSchema.keyof().Enum.display_name}
          label="Display Name"
          isRequired
          type="text"
        />
        <TextInput
          name={CustomAttributeSchema.keyof().Enum.description}
          label="Description"
          type="text"
        />
        {assetType === AssetType.EQUIPMENT ? (
          <DropdownInput
            name={CustomAttributeSchema.keyof().Enum.entity_subtypes}
            label={`${localize("Equipment")} Type`}
            options={equipmentTypes}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown
          />
        ) : null}
        {assetType === AssetType.FLOW ? (
          <FlowTypeDropdownInput
            name={CustomAttributeSchema.keyof().Enum.entity_subtypes}
            label={`${localize("Flow")} Type`}
            isRequired={false}
            isOptionalTextShown={true}
            description="If no flow type is specified the custom attribute will be applicable for all flow types."
          />
        ) : null}
        <RadioInput
          name={CustomAttributeSchema.keyof().Enum.is_required}
          label="Is Required"
          isRequired
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          valueKey="value"
          labelKey="label"
        />
        {dataType == AttributeDataType.PICK_LIST ? (
          <TextInput
            name={CustomAttributeSchema.keyof().Enum.pick_list_values}
            label="Pick List Values"
            isRequired
            description="The values must be comma seperated values. e.g: Option 1, Option 2"
            type="text"
          />
        ) : (
          []
        )}
        {dataType == AttributeDataType.LOOKUP ? (
          <DropdownInput
            name={CustomAttributeSchema.keyof().Enum.lookup_entity_type}
            label="Lookup Asset"
            options={assetTypeOptions}
            valueKey="label"
            labelKey="label"
            isDisabled
          />
        ) : (
          []
        )}
        {dataType == AttributeDataType.NUMBER ? (
          <TextInput
            name={CustomAttributeSchema.keyof().Enum.unit}
            label="Unit"
            isDisabled
            type="text"
          />
        ) : (
          []
        )}
      </Form>
    </Dialog>
  );
};
