import FileService from "#src/components/Services/FileService";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { type FileUploadInputProps } from "@validereinc/common-components";
import { FileUploadedSchema } from "@validereinc/domain";
import { getMimeTypeFromFileName } from "@validereinc/utilities";

export const useFile = (params?: {
  uploadConfig?: { maxPolls?: number; pollSleepTime?: number };
}) => {
  const { claims } = useAuthenticatedContext();

  const handleFileDownload: FileUploadInputProps["downloadFile"] = async (
    value: unknown | undefined,
    { setStatus }
  ) => {
    if (!value) {
      return;
    }

    const valueVerified = FileUploadedSchema.safeParse(value);

    if (!valueVerified.success)
      throw new Error("Value is invalid file uploaded shape");

    const mimeType = getMimeTypeFromFileName(valueVerified.data.name);

    if (!mimeType) throw new Error("File name is invalid");

    setStatus({ percentComplete: 1, label: "Verified..." });

    const response = await FileService.getFileDownloadUrl(
      valueVerified.data.ref
    );

    setStatus({ percentComplete: 25, label: "Fetched file data..." });

    if (!response) {
      throw new Error("Could not get file url");
    }

    const {
      data: {
        data: [fileData],
      },
    } = response;

    setStatus({ percentComplete: 50, label: "Extracted file data..." });

    if (!fileData) {
      throw new Error("Could not extract file data");
    }

    setStatus({ percentComplete: 55, label: "Verifying integrity..." });

    if (fileData.file_scan_status !== "safe") {
      throw new Error("The file download link is not available.");
    }

    const { uri: downloadUrl } = fileData;

    if (!downloadUrl) {
      throw new Error("The file download URL does not exist.");
    }

    setStatus({ percentComplete: 60, label: "Fetching file..." });

    const data = await fetch(downloadUrl).then((r) => r.blob());

    setStatus({ percentComplete: 100, label: "Downloaded." });

    return new File([data], valueVerified.data.name, {
      type: mimeType,
    });
  };

  const handleFileUpload: FileUploadInputProps["uploadFile"] = async (
    file,
    { setStatus, signal }
  ) => {
    if (!file) return;

    const { name, type: contentType, lastModified } = file;

    setStatus({ percentComplete: 0, label: "Uploading..." });

    const {
      status: createFileStatus,
      data: {
        data: [uploadedFileData],
      },
    } = await FileService.createFile({
      name,
      contentType,
      description:
        "A user-uploaded file through a file input component on the web",
      tags: {
        lastModified: new Date(lastModified).toISOString(),
      },
      companyId: claims.company.id,
      signal,
    });

    if (createFileStatus !== 200 && createFileStatus !== 201) {
      throw new Error("Could not create an entry in the file upload service");
    }

    // extract the ID and upload URL of the file entry in the file upload service
    const { file_id: fileId, uri: uploadUrl } = uploadedFileData;

    // upload the file to the pre-signed upload URL
    const { status: uploadFileStatus } = await FileService.uploadFile({
      url: uploadUrl,
      fileBlob: file,
      signal,
    });

    if (uploadFileStatus !== 200 && uploadFileStatus !== 201) {
      throw new Error(
        "Could not upload file to the created entry in file upload service"
      );
    }

    // poll and check to see if the file scanning in the file upload service has completed
    setStatus({ percentComplete: 33, label: "Scanning..." });

    let scanStatus = "unknown";
    let pollLimit = params?.uploadConfig?.maxPolls ?? 10;

    while (pollLimit > 0 && !["safe", "unsafe"].includes(scanStatus)) {
      const {
        data: {
          data: [{ file_scan_status }],
        },
      } = await FileService.getFileDownloadUrl(fileId, signal);

      scanStatus = file_scan_status;

      // scan status re-check polling interval
      await new Promise((res) =>
        setTimeout(res, params?.uploadConfig?.pollSleepTime ?? 1000)
      );
      pollLimit -= 1;
    }

    // verify based on the scan result
    setStatus({ percentComplete: 66, label: "Verifying..." });

    if (scanStatus !== "safe") {
      throw new Error(
        "The uploaded file has been deemed as unsafe or failed to complete verification"
      );
    }

    setStatus({ percentComplete: 100, label: "Complete" });

    return {
      ref: fileId,
      name,
    };
  };

  return { handleFileUpload, handleFileDownload };
};
