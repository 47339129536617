import { useNavigate, useParams } from "#routers/hooks";
import { linkToEquipmentDetail, linkToFacilities } from "#routers/links";
import {
  TemplatedConfigurationQueueType,
  templatedConfigurationRunQueueName,
} from "#src/batteries-included-components/Banners/TemplatedConfigurationRunStatus";
import EquipmentDetailsFormStep from "#src/batteries-included-components/Panels/FormPanels/EquipmentFormPanel/EquipmentDetailsFormStep";
import EquipmentOverviewFormStep from "#src/batteries-included-components/Panels/FormPanels/EquipmentFormPanel/EquipmentOverviewFormStep";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import {
  useCreateOneEquipment,
  useListEquipmentTypes,
} from "#src/components/hooks/adapters/useEquipment";
import config from "#src/config";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useQueueUnique } from "#src/hooks/useQueue";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  EquipmentCreateMode,
  EquipmentCreateModeType,
  EquipmentCreateRoute,
} from "#src/routes/organization/equipment";
import { sanitizeCustomAttributeFormInputs } from "#src/utils/customAttributes";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { getTimeStringFromDate } from "#src/utils/timeFormatter";
import { Link, Page, Panel, useToast } from "@validereinc/common-components";
import { AssetType, EquipmentSchema, Resources } from "@validereinc/domain";
import {
  parseAsLatitudeDegree,
  parseAsLongitudeDegree,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import pick from "lodash/pick";
import React from "react";
import { EquipmentListRoute } from "..";
import { useCreateOneTemplatedConfigurationRun } from "../../../../components/hooks/adapters/useTemplatedConfigurations";
import styles from "./CreateEquipmentPage.module.scss";

const cx = classNames.bind(styles);

const CreateEquipmentPageContent = () => {
  const { filterConfigStorageKey } = useStorageKey("asset-builder");
  const [areTemplatedConfigurationsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
  });
  const { mode } = useParams<{ mode: EquipmentCreateModeType }>();
  const [breadcrumbs] = useBreadcrumbsFromRoute(EquipmentCreateRoute);
  const { stepper, footer } = useMultiStepFormContext();
  const { localize } = useLocalization();

  const shouldUseAssetBuilder =
    areTemplatedConfigurationsAvailable &&
    mode === EquipmentCreateMode.ASSET_BUILDER;

  return (
    <Page
      title={`Create ${localize(AssetType.EQUIPMENT)}`}
      category={
        shouldUseAssetBuilder
          ? "Asset Builder"
          : `Quick Create ${localize(AssetType.EQUIPMENT)}`
      }
      shouldCenterContent
      breadcrumbs={breadcrumbs}
      footer={footer}
    >
      <Panel
        className={cx("container")}
        isFluidY={false}
      >
        <div className={cx("stepper-container")}>{stepper}</div>
        <div className={cx("form-container")}>
          <EquipmentOverviewFormStep
            filterConfigStorageKey={filterConfigStorageKey}
            shouldUseAssetBuilder={shouldUseAssetBuilder}
          />
          <EquipmentDetailsFormStep />
        </div>
      </Panel>
    </Page>
  );
};

export const CreateEquipmentPage = () => {
  const { mode } = useParams<{
    mode: EquipmentCreateModeType;
  }>();

  const urlSearchParams = new URLSearchParams(document.location.search);
  const facilityId = urlSearchParams.get("facilityId");

  const { toast } = useToast();
  const navigate = useNavigate();
  const { localize } = useLocalization();

  const onCancel = () => {
    if (facilityId) {
      navigate({ pathname: linkToFacilities(facilityId), replace: true });
    } else {
      navigate(EquipmentListRoute.toLinkParts({ replace: true }));
    }
  };

  const redirectAfterSuccess = () => {
    if (facilityId) {
      navigate({
        pathname: linkToFacilities(facilityId),
        query: { section: "equipment" },
      });
    } else {
      navigate(EquipmentListRoute.toLinkParts({ replace: true }));
    }
  };

  const customAttributes =
    useListCustomAttributes({ filters: { entity_type: AssetType.EQUIPMENT } })
      .data?.data ?? [];

  const createEquipmentMutation = useCreateOneEquipment({
    onSuccess: (data) => {
      toast.push({
        intent: "success",
        description: (
          <>
            Successfully created {localize(AssetType.EQUIPMENT)}.
            {mode === EquipmentCreateMode.QUICK_CREATE ? (
              <>
                {" "}
                <Link
                  label="View Details"
                  underline="always"
                  onClick={() =>
                    navigate({
                      pathname: linkToEquipmentDetail(data.data.id),
                    })
                  }
                />
              </>
            ) : null}
          </>
        ),
      });
      redirectAfterSuccess();
    },
    noAlerts: true,
  });

  const [queue, setQueue] = useQueueUnique<TemplatedConfigurationQueueType>(
    [],
    templatedConfigurationRunQueueName(Resources.EQUIPMENT),
    (tx) => tx.job.id
  );

  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  const createTemplatedConfigurationRun = useCreateOneTemplatedConfigurationRun(
    {
      onSuccess: (data, variables) => {
        const response = data.data;

        const primaryResourceId =
          Object.keys(variables?.data?.custom_attribute_configuration)?.[0] ??
          "";

        const equipmentTypeUsedForTemplatedRunCreation = primaryResourceId
          ? response?.custom_attribute_configuration?.[primaryResourceId]
              .entity_subtype
          : "";

        const equipmentTypeDefinitionUsedForTemplatedRunCreation =
          equipmentTypes.find(
            (equipmentType) =>
              equipmentType.id === equipmentTypeUsedForTemplatedRunCreation
          );

        setQueue(
          queue.insert({
            job: {
              id: response.id,
              templateName: response.templated_configuration_name,
            },
            resourceDetails: {
              type: equipmentTypeDefinitionUsedForTemplatedRunCreation,
            },
          })
        );
        redirectAfterSuccess();
      },
    }
  );

  const onSubmit = (values: any[]) => {
    const allValues = values.reduce(
      (total, current) => ({ ...total, ...current }),
      {}
    );

    if (allValues.template_name) {
      createTemplatedConfigurationRun.mutate({
        data: {
          ...pick(allValues, "inputs", "custom_attribute_inputs"),
          custom_attribute_configuration: {
            [allValues.main_resource_id ?? ""]: {
              entity_subtype: allValues.type_id,
            },
          },
        },
        meta: {
          templated_configuration_name: allValues.template_name,
        },
      });
    } else {
      createEquipmentMutation.mutate({
        data: {
          ...pick(
            allValues,
            EquipmentSchema.keyof().Enum.effective_date,
            EquipmentSchema.keyof().Enum.facility_id,
            EquipmentSchema.keyof().Enum.name,
            EquipmentSchema.keyof().Enum.status,
            EquipmentSchema.keyof().Enum.type_id
          ),
          effective_date: getTimeStringFromDate(
            allValues.effective_date,
            config.DATE_FORMAT
          ),
          latitude: parseAsLatitudeDegree(
            String(allValues?.coordinations?.[0])
          ),
          longitude: parseAsLongitudeDegree(
            String(allValues?.coordinations?.[1])
          ),
          custom_attributes: sanitizeCustomAttributeFormInputs(
            allValues?.custom_attributes ?? {},
            customAttributes
          ),
        },
      });
    }
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Overview",
        },
        {
          label: "Details",
        },
      ]}
      orientation="vertical"
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting:
          createEquipmentMutation.isLoading ||
          createTemplatedConfigurationRun.isLoading,
      }}
      submissionActionText="Create"
      stepperClassName={cx("sticky-stepper")}
    >
      <CreateEquipmentPageContent />
    </MultiStepFormProvider>
  );
};

export default CreateEquipmentPage;
