import { UserGroupsDropdownInput } from "#src/batteries-included-components/Dropdowns/UserGroupsDropdownInput";
import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import {
  FilterArea,
  useFilterAreaContentContext,
  useFilterAreaContext,
} from "#src/components/FilterArea";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  DateSelectorInput,
  DropdownInput,
  PillToggleGroup,
  PillToggleVariants,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import { RolesAdapter, UserStatusOptions } from "@validereinc/domain";
import React from "react";
import { z } from "zod";

const UsersStatusPillToggles = ({
  onChange,
}: {
  onChange: (val: string[]) => void;
}) => {
  const { storedFilters } = useFilterAreaContext<{
    status: string[];
  }>();
  const pillToggles = [
    {
      name: "All",
      label: "All",
      value: "",
      isSelected:
        !storedFilters.status?.length || storedFilters.status?.includes(""),
      shouldSelectAll: true,
    },
    ...UserStatusOptions.map((option) => ({
      name: option.name,
      label: option.name,
      value: option.status,
      variant:
        option.status === "active"
          ? PillToggleVariants.GOOD
          : PillToggleVariants.NEUTRAL,
      isSelected: storedFilters.status?.includes(option.status) ?? false,
    })),
  ];

  return (
    <PillToggleGroup
      name="status"
      pills={pillToggles}
      onChange={(val) => onChange(val)}
    />
  );
};

export const UsersTableTitleDecorationFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        status: ["active"],
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Users">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
              <UsersStatusPillToggles
                onChange={(val) => handleOnChange(val, "status")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const UsersTableFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{}}
    >
      <FilterArea.Container aria-label="Filters for Flow Records">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <TextInput
              name="name"
              key="name"
              placeholder="Search Users"
              type="search"
              isInline
              onChange={(val) => handleOnChange(val, "name")}
            />
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const UserActivityLogFilterSchema = z
  .object({
    resource_id: z.string(),
    user_id: z.string(),
    logged_by: z.string(),
    logged_at: z.object({
      from: z.string().datetime(),
      to: z.string().datetime(),
    }),
  })
  .partial();
export const UserActivityLogFilterSchemaKeys =
  UserActivityLogFilterSchema.keyof().Enum;
export type UserActivityLogFiltersType = z.infer<
  typeof UserActivityLogFilterSchema
>;

export const UserActivityLogFilterAreaContent = ({
  shouldShowUserGroupsDropdown,
  shouldShowRolesDropdown,
}: {
  shouldShowUserGroupsDropdown?: boolean;
  shouldShowRolesDropdown?: boolean;
}) => {
  const { handleOnChange } = useFilterAreaContentContext();

  return (
    <>
      <UsersDropdownInput
        style={{ marginBottom: 0 }}
        name={UserActivityLogFilterSchemaKeys.user_id}
        label="Users"
        placeholder="Select Users..."
        description="Filter by User"
        isInline
        isOptionalTextShown={false}
        isLabelShown={false}
        isMulti
        onChange={(val: string) =>
          handleOnChange(val, UserActivityLogFilterSchemaKeys.user_id)
        }
      />
      {shouldShowUserGroupsDropdown ? (
        <UserGroupsDropdownInput
          style={{ marginBottom: 0 }}
          name={UserActivityLogFilterSchemaKeys.resource_id}
          label="User Groups"
          placeholder="Select User Groups..."
          description="Filter by User Group"
          isInline
          isOptionalTextShown={false}
          isLabelShown={false}
          isMulti
          onChange={(val: string) =>
            handleOnChange(val, UserActivityLogFilterSchemaKeys.resource_id)
          }
        />
      ) : null}
      {shouldShowRolesDropdown ? (
        <DropdownInput
          name={UserActivityLogFilterSchemaKeys.resource_id}
          onFetchData={async (payload) => {
            let { data } = await RolesAdapter.getList({
              ...payload,
              pageSize: 500,
              filters: {
                ...(payload.searchTerm
                  ? {
                      name: payload.searchTerm,
                    }
                  : {}),
              },
            });

            // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
            if (Array.isArray(payload.value)) {
              data = data.filter((d) => payload.value.includes(d.id));
            }

            return data;
          }}
          labelKey="name"
          valueKey="id"
          placeholder="Select Roles..."
          label="Roles"
          description="Filter by Role"
          isOptionalTextShown={false}
          isLabelShown={false}
          isInline
          isMulti
          onChange={(val) =>
            handleOnChange(val, UserActivityLogFilterSchemaKeys.resource_id)
          }
        />
      ) : null}
      <UsersDropdownInput
        style={{ marginBottom: 0 }}
        name={UserActivityLogFilterSchemaKeys.logged_by}
        label="Logged By"
        placeholder="Select Logged By..."
        description="Filter by Logged By"
        isInline
        isOptionalTextShown={false}
        isLabelShown={false}
        onChange={(val: string) =>
          handleOnChange(val, UserActivityLogFilterSchemaKeys.logged_by)
        }
      />
      <DateSelectorInput
        name={UserActivityLogFilterSchemaKeys.logged_at}
        variant="day"
        label="Logged At"
        description="Filter by Logged At"
        isLabelShown={false}
        isRange
        isInline
        onChange={(val) =>
          handleOnChange(val, UserActivityLogFilterSchemaKeys.logged_at)
        }
      />
    </>
  );
};

export const UserActivityLogFilterArea = ({
  viewConfigStorageKey,
  shouldShowUserGroupsDropdown,
  shouldShowRolesDropdown,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  shouldShowUserGroupsDropdown?: boolean;
  shouldShowRolesDropdown?: boolean;
}) => {
  return (
    <FilterArea.Root
      storageKey={viewConfigStorageKey}
      defaultValues={{
        logged_at: DEFAULT_DATE_RANGES.lastThreeMonths,
        resource_id: "",
      }}
      applyDefaultValues
      shouldPrioritizeStoredFiltersWhenApplyingDefaultValues
    >
      <FilterArea.Container style={{ marginBottom: 16 }}>
        <FilterArea.Content>
          {() => (
            <div
              style={{
                display: "flex",
                gap: 8,
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <UserActivityLogFilterAreaContent
                shouldShowUserGroupsDropdown={shouldShowUserGroupsDropdown}
                shouldShowRolesDropdown={shouldShowRolesDropdown}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
