import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns";
import { AssetBuilderFiltersType } from "#src/batteries-included-components/FilterAreas/AssetBuilderFilterAreas/AssetBuilderFilterAreas";
import { AssetTemplateRadioInput } from "#src/batteries-included-components/Forms/Fields/AssetTemplateRadioInput";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import { useListTemplatedConfigurations } from "#src/components/hooks/adapters/useTemplatedConfigurations";
import useLocalization from "#src/hooks/useLocalization";
import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  DropdownInput,
  Form,
  Panel,
  type StorageKeys,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  EquipmentSchema,
  Resources,
  TemplatedConfigurationSchema,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import styles from "./EquipmentFormPanel.module.scss";
const cx = classNames.bind(styles);

const SECTION_DESCRIPTION =
  "Quick Create will create only the single equipment whose details are specified below.";

const EquipmentOverviewFormStep = ({
  filterConfigStorageKey,
  shouldUseAssetBuilder,
}: {
  shouldUseAssetBuilder?: boolean;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const urlSearchParams = new URLSearchParams(document.location.search);
  const facilityId = urlSearchParams.get("facilityId");

  const { currentStep, updateStep } = useMultiStepFormContext();
  const STEP_NUMBER = 1;

  const [filters] = useSessionStickyState<AssetBuilderFiltersType>(
    {} as AssetBuilderFiltersType,
    filterConfigStorageKey
  );
  const { displayName, sortDirection } = filters;

  const equipmentTypesQuery = useListEquipmentTypes({});

  const { localize } = useLocalization();

  const form = useForm<{
    asset_type: AssetTypeType;
    name?: string;
    type_id: string;
    template_name?: string;
    facility_id?: string;
  }>({
    defaultValues: {
      asset_type: AssetType.EQUIPMENT,
      name: "",
      type_id: "",
      template_name: "",
      facility_id: facilityId ?? "",
    },
  });

  const formValues = form.watch();

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState: () => ({
        isSubmitting: false,
        isValid:
          !!formValues.type_id &&
          (!shouldUseAssetBuilder
            ? !!formValues.facility_id && !!formValues.name
            : true),
      }),
    });
  }, [JSON.stringify(formValues)]);

  const isTemplateQueryDisabled = !shouldUseAssetBuilder;

  const availableTemplatesQuery = useListTemplatedConfigurations(
    {
      filters: {
        primary_resource_type: Resources.EQUIPMENT,
        equipment_type_id: formValues.type_id,
        display_name: displayName,
      },
      sortDirection,
      sortBy: TemplatedConfigurationSchema.keyof().Enum.display_name,
    },
    { enabled: !isTemplateQueryDisabled }
  );

  const availableTemplatesData = availableTemplatesQuery.data?.data;

  const availableTemplatesOptions =
    availableTemplatesData?.map((template) => {
      return {
        title: template.display_name,
        description: template.description ?? "",
        value: template.name,
      };
    }) ?? [];

  const handleTypeIdChange = () => {
    form.setValue("template_name", "");
  };

  const handleTemplateNameChange = (templateName: string) => {
    const equipmentTypeIds = availableTemplatesData
      ?.find((template) => template.name === templateName)
      ?.equipment_types.map((equipmentType) => equipmentType.id);
    if (equipmentTypeIds?.includes(formValues.type_id)) {
      return;
    }

    const newTypeId =
      availableTemplatesData?.find((template) => template.name === templateName)
        ?.equipment_types[0].id ?? "";

    form.setValue(EquipmentSchema.keyof().Enum.type_id, newTypeId, {
      shouldValidate: true,
    });
  };

  if (currentStep !== STEP_NUMBER) return null;

  return (
    <Panel className={cx("panel")}>
      <Form {...form}>
        <h4 className={cx("section-title")}>Overview</h4>
        <p className={cx("section-description")}>{SECTION_DESCRIPTION}</p>
        <div className={cx("basic-info-container")}>
          {shouldUseAssetBuilder ? (
            <DropdownInput
              label="Asset Type"
              name="asset_type"
              options={[
                { label: localize(AssetType.EQUIPMENT), value: "equipment" },
              ]}
              labelKey="label"
              valueKey="value"
              isRequired
              isDisabled
              isFluid
            />
          ) : (
            <TextInput
              label="Name"
              name="name"
              placeholder="Enter Name..."
              isFluid
              isRequired
            />
          )}

          <DropdownInput
            label="Type"
            name={EquipmentSchema.keyof().Enum.type_id}
            options={equipmentTypesQuery.data?.data ?? []}
            placeholder={`Select ${localize("equipment")} Type...`}
            isSortedAlphabetically
            valueKey="id"
            labelKey="name"
            onChange={handleTypeIdChange}
            isRequired
            isFluid
            isLoading={equipmentTypesQuery.isLoading}
          />

          {shouldUseAssetBuilder ? (
            <>
              <AssetTemplateRadioInput
                filterConfigStorageKey={filterConfigStorageKey}
                isTemplateQueryDisabled={isTemplateQueryDisabled}
                availableTemplatesQueryIsLoading={
                  availableTemplatesQuery.isLoading
                }
                availableTemplatesOptions={availableTemplatesOptions}
                handleOnChange={(templateName: string) =>
                  handleTemplateNameChange(templateName)
                }
                isOptionSelected={Boolean(formValues.template_name)}
              />
            </>
          ) : (
            <FacilityDropdownInput
              name="facility_id"
              placeholder={`Select ${localize(`${AssetType.FACILITY}`)}...`}
              isFluid
              isDisabled={Boolean(facilityId)}
              isMulti={false}
              isOptionalTextShown={false}
              isRequired
            />
          )}
        </div>
      </Form>
    </Panel>
  );
};

export default EquipmentOverviewFormStep;
