import CustomAttributeField from "#src/components/Common/CustomAttributeField";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import {
  useGetOneTemplatedConfiguration,
  useGetTemplatedConfigurationInputSchema,
} from "#src/components/hooks/adapters/useTemplatedConfigurations";
import { EQUIPMENT_STATUS_OPTIONS } from "#src/constants";
import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import {
  Accordion,
  Form,
  Panel,
  useForm,
} from "@validereinc/common-components";
import { AssetType, EquipmentSchema, Resources } from "@validereinc/domain";
import { TemplatedConfigurations } from "@validereinc/domain-controllers";
import classNames from "classnames/bind";
import React, { useEffect, useMemo, useRef } from "react";
import { useCustomAttributeSectionsDefinition } from "./EquipmentFormPanel.helpers";
import styles from "./EquipmentFormPanel.module.scss";

const cx = classNames.bind(styles);

const EquipmentDetailsFormStep = () => {
  const today = useRef(new Date());

  // Form steps logic
  const { currentStep, updateStep, getStep } = useMultiStepFormContext();
  const STEP_NUMBER = 2;
  const isStepActive = currentStep === STEP_NUMBER;
  const prevStep = getStep(1);
  const prevStepValues = prevStep?.getValues();

  // Form logic
  const form = useForm();
  const getFormState = useMemoizedFormState(form);

  const isUsingTemplate = !!prevStepValues?.template_name as boolean;

  // Queries:
  const customAttributesQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.EQUIPMENT },
  });

  const customAttributes = customAttributesQuery.data?.data ?? [];

  const templatedConfigurationQuery = useGetOneTemplatedConfiguration(
    {
      id: (prevStepValues?.template_name as string) ?? "",
    },
    {
      enabled: !!prevStepValues?.template_name,
    }
  );

  const templatedConfiguration = templatedConfigurationQuery.data?.data;

  const mainResourceId = useMemo(
    () =>
      TemplatedConfigurations.logic.getResourceIdForResource(
        Resources.EQUIPMENT,
        templatedConfiguration
      ) ?? "",
    [templatedConfiguration?.display_name]
  );

  const isInputSchemaQueryEnabled =
    !!prevStepValues?.template_name && !!mainResourceId;

  const inputSchemaQuery = useGetTemplatedConfigurationInputSchema(
    {
      id: (prevStepValues?.template_name as string) ?? "",
      meta: {
        custom_attribute_configuration: {
          [mainResourceId]: {
            entity_subtype: (prevStepValues?.type_id as string) ?? "",
          },
        },
      },
    },
    {
      enabled: isInputSchemaQueryEnabled,
    }
  );

  const inputSchema = inputSchemaQuery.data?.data;

  const customAttributeSectionsDefinition =
    useCustomAttributeSectionsDefinition({
      templatedConfiguration,
      inputSchema,
      primaryResourceType: Resources.EQUIPMENT,
    });

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState,
    });
  }, [getFormState]);

  useEffect(() => {
    form.setValue(
      EquipmentSchema.keyof().Enum.effective_date,
      isUsingTemplate ? undefined : today.current
    );
  }, [isUsingTemplate]);

  useEffect(() => {
    if (!mainResourceId) return;
    form.setValue("main_resource_id", mainResourceId);
  }, [mainResourceId]);

  const isInputPanelLoaded =
    (isUsingTemplate &&
      isInputSchemaQueryEnabled &&
      !inputSchemaQuery.isLoading) ||
    (!isUsingTemplate && !customAttributesQuery.isLoading);

  const staticFieldsToDisplay = [
    {
      display_name: "Status",
      field_name: EquipmentSchema.keyof().Enum.status,
      data_type: "pick-list",
      pick_list_values: EQUIPMENT_STATUS_OPTIONS.map((option) => ({
        id: option.value,
        name: option.label,
      })),
      is_required: true,
    },
    {
      field_name: EquipmentSchema.keyof().Enum.effective_date,
      display_name: "Effective Date",
      is_required: true,
      data_type: "date",
    },
    {
      field_name: "coordinations",
      display_name: "Coordinations",
      is_required: false,
      data_type: "geo_point",
    },
    ...customAttributes
      .filter((custom_attribute) =>
        custom_attribute.entity_subtypes
          ? custom_attribute.entity_subtypes.includes(
              prevStepValues?.type_id as string
            )
          : true
      )
      .sort((a, b) => Number(b.is_required) - Number(a.is_required))
      .map((custom_attribute) => {
        return {
          ...custom_attribute,
          field_name: `custom_attributes.${custom_attribute.field_name}`,
        } as typeof custom_attribute;
      }),
  ];

  const orderedInputSchemaInputs = [
    ...Object.entries(inputSchema?.inputs ?? {}),
  ].sort(([_, valueA], [__, valueB]) => {
    // If order is missing for both values, keep the order as is.
    if (
      typeof valueA.order === "undefined" &&
      typeof valueB.order === "undefined"
    )
      return 0;

    // If there's at least one order value available, one of them can be Infinity.
    const orderA = valueA.order ?? Infinity;
    const orderB = valueB.order ?? Infinity;
    return orderA - orderB;
  });

  if (!isStepActive) return null;

  return (
    <Panel
      className={cx("panel")}
      loaded={isInputPanelLoaded}
    >
      <Form {...form}>
        <h4 className={cx("section-title")}>Details</h4>
        <div className={cx("basic-info-container")}>
          {isUsingTemplate && (
            <>
              {orderedInputSchemaInputs.map(([attributeId, attributeBody]) => (
                <CustomAttributeField
                  key={`inputs.${attributeId}`}
                  name={`inputs.${attributeId}`}
                  attribute={attributeBody}
                  subtype={(prevStepValues?.type_id as string) ?? null}
                />
              ))}
              <Accordion>
                {customAttributeSectionsDefinition.map((section) => {
                  // Show required fields first
                  const sortedFields = Object.entries(section.fields).sort(
                    ([_, fieldA], [__, fieldB]) =>
                      Number(fieldB.is_required) - Number(fieldA.is_required)
                  );

                  return (
                    <Accordion.AccordionPanel
                      title={section.title}
                      dataKey={section.resourceId}
                      key={section.resourceId}
                    >
                      {sortedFields.map(([fieldKey, field]) => (
                        <CustomAttributeField
                          key={`custom_attribute_inputs.${section.resourceId}.${fieldKey}`}
                          name={`custom_attribute_inputs.${section.resourceId}.${fieldKey}`}
                          attribute={field}
                          subtype={(prevStepValues?.type_id as string) ?? null}
                        />
                      ))}
                    </Accordion.AccordionPanel>
                  );
                })}
              </Accordion>
            </>
          )}
          {!isUsingTemplate && (
            <>
              {staticFieldsToDisplay.map((field) => {
                return (
                  <CustomAttributeField
                    key={field.field_name}
                    name={field.field_name}
                    attribute={field}
                    subtype={(prevStepValues?.type_id as string) ?? null}
                  />
                );
              })}
            </>
          )}
        </div>
      </Form>
    </Panel>
  );
};

export default EquipmentDetailsFormStep;
