import {
  Button,
  FormInputWrapper,
  SelectInput,
  TextInput,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { validateSampleDate } from "./CreateChainOfCustodyHelper";
import {
  ADD_EXISTING_SAMPLE_MODAL_KEY,
  ADD_NEW_SAMPLE_MODAL_KEY,
  EDIT_SAMPLE_MODAL_KEY,
} from "./SampleModals/ModalConstants";
import SelectedSampleTable from "./SelectedSampleTable";

const CreateChainOfCustodyForm = ({
  form,
  formState,
  availableIssuers,
  availableSites,
  onModalToggle,
  onRemoveSample,
}) => {
  const onEditSampleClick = (sample) => {
    onModalToggle(EDIT_SAMPLE_MODAL_KEY, sample);
  };

  const onRemoveSampleClick = (sample) => {
    onRemoveSample(sample);
  };

  const sharedProps = {
    isDisabled: formState !== "enabled",
    showIcon: true,
  };

  return (
    <>
      <TextInput
        name="name"
        label="Name"
        isRequired
        {...sharedProps}
      />

      <SelectInput
        {...sharedProps}
        name="type"
        label="Issue to"
        options={availableIssuers}
        isRequired
        ignoreCase
      />

      <SelectInput
        {...sharedProps}
        name="site"
        label="Site"
        labelKey="name"
        options={availableSites}
        isRequired
        ignoreCase
      />

      <FormInputWrapper
        {...sharedProps}
        name="samples"
        label="Sample"
        as={({ isValidated, error, isDisabled }) => (
          <>
            <div className="createChainOfCustody__actionRow">
              <div className="buttonContainer">
                <Button
                  onClick={() => onModalToggle(ADD_NEW_SAMPLE_MODAL_KEY)}
                  disabled={formState !== "enabled"}
                >
                  Add New
                </Button>

                <Button
                  onClick={() => onModalToggle(ADD_EXISTING_SAMPLE_MODAL_KEY)}
                  disabled={formState !== "enabled"}
                >
                  Add Existing
                </Button>
              </div>

              <div className="iconContainer">
                {isValidated &&
                  (!error ? (
                    <div className={isDisabled ? "disabled" : "success"}>
                      <FontAwesome name="check" />
                    </div>
                  ) : (
                    <div className="error">
                      <FontAwesome name="exclamation-circle" />
                    </div>
                  ))}
              </div>
            </div>

            <SelectedSampleTable
              samples={form.getValues("samples") ?? []}
              onEditSampleClick={onEditSampleClick}
              onRemoveSampleClick={onRemoveSampleClick}
              disabled={formState !== "enabled"}
            />
          </>
        )}
        validate={{
          isSampleContainDate: (samples) => validateSampleDate(samples),
        }}
        showIcon
        isRequired
      />
    </>
  );
};

CreateChainOfCustodyForm.propTypes = {
  form: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  availableIssuers: PropTypes.array.isRequired,
  availableSites: PropTypes.array.isRequired,
  onModalToggle: PropTypes.func.isRequired,
  onRemoveSample: PropTypes.func.isRequired,
};

export default CreateChainOfCustodyForm;
