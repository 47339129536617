import { useNavigate, useParams } from "#routers/hooks";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  EquipmentCreateMode,
  EquipmentCreateRoute,
} from "#src/routes/organization/equipment";
import {
  Button,
  colours,
  DropdownMenu,
  DropdownMenuOption,
  Icon,
  type IconProps,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
import styles from "./CreateEquipmentDropdownMenu.module.scss";

const cx = classNames.bind(styles);

export const CreateEquipmentDropdownMenu = ({
  shouldUseLightVariant,
}: {
  shouldUseLightVariant?: boolean;
}) => {
  const navigate = useNavigate();
  const { facilityId } = useParams<{ facilityId?: string }>();
  const { localize } = useLocalization();
  const [areTemplatedConfigurationsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
  });
  const createEquipmentButtonRef = useRef<HTMLButtonElement | null>(null);
  const [createEquipmentButtonWidth, setCreateEquipmentButtonWidth] =
    useState(0);

  const createEquipmentOptions: DropdownMenuOption[] = [
    ...(areTemplatedConfigurationsAvailable
      ? [
          {
            label: "Asset Builder",
            onClick: () => {
              navigate({
                pathname: EquipmentCreateRoute.toLink({
                  pathParams: { mode: EquipmentCreateMode.ASSET_BUILDER },
                }),
                query: facilityId ? { facilityId } : {},
              });
            },
            slotLeft: ({ iconProps }: { iconProps: Partial<IconProps> }) => (
              <Icon
                variant="sparkle"
                colour={colours.colours.primary["300"]}
                weight="fill"
                {...iconProps}
              />
            ),
          },
        ]
      : []),
    {
      label: "Quick Create",
      onClick: () => {
        if (facilityId) {
          navigate({
            pathname: EquipmentCreateRoute.toLink({
              pathParams: { mode: EquipmentCreateMode.QUICK_CREATE },
            }),
            query: facilityId ? { facilityId } : {},
          });
        } else {
          navigate({
            pathname: EquipmentCreateRoute.toLink({
              pathParams: { mode: EquipmentCreateMode.QUICK_CREATE },
            }),
          });
        }
      },
      slotLeft: ({ iconProps }) => (
        <Icon
          variant="plus-circle"
          {...iconProps}
        />
      ),
    },
  ];

  // We need to programatically get the Create Equipment button width as its text can vary.
  // We need to pass it as a style for the DropdownMenu so the menu is the same width as the button.
  useEffect(() => {
    const measureCreateEquipmentButtonWidth = () => {
      if (createEquipmentButtonRef.current) {
        setCreateEquipmentButtonWidth(
          createEquipmentButtonRef.current.offsetWidth
        );
      }
    };
    measureCreateEquipmentButtonWidth();
  }, []);

  return (
    <DropdownMenu
      contentProps={{
        style: { width: `${createEquipmentButtonWidth}px` },
      }}
      options={createEquipmentOptions}
    >
      <Button
        ref={createEquipmentButtonRef}
        className={cx("create-equipment-button", {
          "light-variant": shouldUseLightVariant,
        })}
        variant={shouldUseLightVariant ? "outline" : "primary"}
        slotRight={
          <Icon
            variant="triangle-down"
            size={12}
            colour={
              shouldUseLightVariant
                ? colours.colours.text["600"]
                : colours.colours.text["000"]
            }
          />
        }
        slotLeft={
          <Icon
            variant="plus-circle"
            size={24}
          />
        }
      >
        Create {localize("Equipment")}
      </Button>
    </DropdownMenu>
  );
};
