import { Banner, BannerProps } from "@validereinc/common-components";
import { SystemActionTypeType, WorkflowTaskTypes } from "@validereinc/domain";
import React from "react";

type SystemActionProgressBannerContentType = {
  titleText: BannerProps["titleText"];
  description?: BannerProps["descriptionText"];
};

const SYSTEM_ACTION_PROGRESS_BANNER_CONTENT: Record<
  SystemActionTypeType,
  SystemActionProgressBannerContentType
> = {
  [WorkflowTaskTypes.RUN_TEMPLATED_REPORT]: {
    titleText: "Running the report...",
  },
  [WorkflowTaskTypes.ASSESS_TEMPLATED_REPORT_FOR_COMMENTS]: {
    titleText: "Checking for comments...",
  },
  [WorkflowTaskTypes.RUN_DEFAULT_RECORD_VALUE_CONFIGURATION]: {
    titleText: "Applying record automation...",
  },
  [WorkflowTaskTypes.LOCK_RECORD]: {
    titleText: "Locking records...",
  },
  [WorkflowTaskTypes.CREATE_EVENT]: {
    titleText: "Creating the event...",
    description:
      "You may view event details below and go to the event page after the automation completes.",
  },
  [WorkflowTaskTypes.CREATE_FORM]: {
    titleText: "Creating the form...",
    description:
      "You may view submission details below and go to the submission page after the automation completes.",
  },
  [WorkflowTaskTypes.FORM_CHOICE]: {
    titleText: "Scanning form...",
    description:
      "Scanning the form to select the correct path based off the choice selection.",
  },
  [WorkflowTaskTypes.DELAY]: {
    titleText: "This delay is currently running",
  },
};

export const SystemActionProgressBanner = ({
  workflowStepType,
}: {
  workflowStepType: SystemActionTypeType;
}) => {
  const { titleText, description } = SYSTEM_ACTION_PROGRESS_BANNER_CONTENT[
    workflowStepType
  ] ?? {
    titleText: "",
  };

  return (
    <Banner
      titleText={titleText}
      {...(description ? { description } : {})}
      color="loading"
      variant="loading"
    />
  );
};
