import { z } from "zod";
import { Resources, ResourcesSchema } from "../util";
import { AttributeDataType } from "./AttributeSchemas";
import { CustomAttributeSchema } from "./CustomAttributeSchemas";
import { DomainModelMetaSchema } from "./DomainModelSchemas";
import { EquipmentTypeSchema } from "./EquipmentSchemas";

export const TemplatedConfigurationSchema = z
  .object({
    name: z.string(),
    display_name: z.string(),
    primary_resource_type: ResourcesSchema,
    other_resource_types: z.array(ResourcesSchema),
    equipment_types: z.array(EquipmentTypeSchema),
    description: z.string().nullable(),
    configuration: z.object({
      custom_attribute_configuration: z.record(
        z.string(),
        z.object({
          entity_type: ResourcesSchema,
          entity_subtypes: z.array(z.string()),
        })
      ),
    }),
  })
  .merge(DomainModelMetaSchema)
  .describe("Schema for Templated Configuration resource type");

export const TemplatedConfigurationFilterSchema =
  TemplatedConfigurationSchema.pick({
    name: true,
    created_at: true,
    updated_at: true,
    display_name: true,
    primary_resource_type: true,
  })
    .merge(
      z.object({
        equipment_type_id: z.string(),
      })
    )
    .partial();

export const TemplatedConfigurationLookupInputSchema = z.enum([
  Resources.FACILITY,
  Resources.EQUIPMENT,
  Resources.DEVICE,
  Resources.FLOW,
  Resources.ASSET_GROUP,
  Resources.FORM_SCHEMA,
]);

export const TemplateInputItemSchema = z.object({
  display_name: z.string(),
  description: z.string(),
  lookup_entity_attribute: z.string().optional(),
  data_type: z.enum([
    AttributeDataType.NUMBER,
    AttributeDataType.STRING,
    AttributeDataType.GEO_POINT,
    AttributeDataType.BOOLEAN,
    AttributeDataType.INTEGER,
    AttributeDataType.PICK_LIST,
    AttributeDataType.MULTI_PICK_LIST,
    AttributeDataType.NUMBER_ARRAY,
    AttributeDataType.LOOKUP,
    AttributeDataType.DATE,
    AttributeDataType.DATE_TIME,
    AttributeDataType.FILE,
    AttributeDataType.DATE_TIME_RANGE,
  ]),
  is_required: z.boolean(),
  pick_list_values: z.array(z.string()).optional(),
  lookup_entity_type: TemplatedConfigurationLookupInputSchema.optional(),
  substitutions: z.array(z.string()).optional(),
  minimum: z.number().optional(),
  maximum: z.number().optional(),
  order: z.number().optional(),
});

const TemplateInputSchema = z.record(z.string(), TemplateInputItemSchema);

const TemplateCustomAttributeInputsSchema = z.record(
  z.string(),
  z.record(z.string(), CustomAttributeSchema)
);

export const TemplatedConfigurationInputSchema = z.object({
  inputs: TemplateInputSchema,
  custom_attribute_inputs: TemplateCustomAttributeInputsSchema,
});

export const TemplatedConfigurationInputCustomAttributeConfigurationSchema =
  z.record(
    z.string(),
    z.object({
      entity_subtype: z.string(),
    })
  );

export type TemplatedConfigurationType = z.infer<
  typeof TemplatedConfigurationSchema
>;
export type TemplatedConfigurationFilterType = z.infer<
  typeof TemplatedConfigurationFilterSchema
>;
export type TemplatedConfigurationInputType = z.infer<
  typeof TemplatedConfigurationInputSchema
>;
export type TemplatedConfigurationInputCustomAttributeConfigurationType =
  z.infer<typeof TemplatedConfigurationInputCustomAttributeConfigurationSchema>;
