import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { EventContext } from "#src/contexts/EventContext";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import { DataTable, Pill } from "@validereinc/common-components";
import { EventsDomain, FormCategoryAdapter } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RoutingLink } from "../RoutingLink";

const pillMapping = {
  validated: "success",
  invalidated: "error",
  pending: "warning",
  submitted: "info",
};

export const EventFormSubmissionsTable = () => {
  const [formCategories, setFormCategories] = useState([]);

  const { event, isLoading, onRefetchEvent } = useContext(EventContext) || {};

  const formattedFormSubmissions = useMemo(() => {
    if (!formCategories?.length || !event?.form_submissions?.data?.length) {
      return [];
    }

    return event.form_submissions.data.map(
      ({ form_schema, ...restFormSubmission }) => ({
        ...restFormSubmission,
        form_schema,
        form_category: formCategories.find(
          ({ id }) => id === form_schema?.form_category_id
        ),
      })
    );
  }, [event?.form_submissions?.data, formCategories]);

  const onFetchAncillaryData = async () => {
    const { data: newFormCategories } = await FormCategoryAdapter.getList({
      page: 1,
      pageSize: 1000,
    });

    setFormCategories(newFormCategories);
  };

  const onRemove = async ({ formSubmissionId }) => {
    await EventsDomain.formSubmissions.removeOne({
      formSubmissionId,
      eventId: event?.id,
    });

    onRefetchEvent?.();
  };

  useEffect(() => {
    onFetchAncillaryData();
  }, []);

  return (
    <DataTable
      headers={[
        {
          key: "form_schema.name",
          label: "Submission Name",
          renderComponent: ({ item }) => (
            <RoutingLink
              to={
                item?.status === "draft"
                  ? linkToUpdateFormSubmission(
                      item?.form_category?.id,
                      item?.form_schema?.id,
                      item?.id
                    )
                  : linkToFormSubmissionDetail(item?.id)
              }
            >{`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}</RoutingLink>
          ),
        },
        {
          key: "form_schema.name",
          label: "Template",
          renderComponent: ({ item }) => (
            <RoutingLink to={linkToFormSubmissionDetail()}>
              {item?.form_schema?.name}
            </RoutingLink>
          ),
        },
        {
          key: "form_category.name",
          label: "Category",
        },
        {
          key: "status",
          label: `Status`,
          renderComponent: ({ item }) => (
            <Pill variant={pillMapping[item.status]}>
              {toStartCaseString(item.status)}
            </Pill>
          ),
        },
        {
          key: "created_at",
          label: "Submission Date",
          renderComponent: ({ item }) => (
            <DataTable.DataRow.DateCell
              value={item.created_at}
              withTime
            />
          ),
        },
      ]}
      items={formattedFormSubmissions}
      isLoading={isLoading}
      getItemActions={({ item: { id } }) => [
        {
          label: "Remove",
          buttonProps: {
            onClick: () => onRemove({ formSubmissionId: id }),
            icon: "minus-circle",
          },
        },
      ]}
    />
  );
};
