import { useQuery } from "@tanstack/react-query";
import {
  DropdownInput,
  type ControlledDropdownInputProps,
} from "@validereinc/common-components";
import { UsersAdapter } from "@validereinc/domain";
import React from "react";

export const UsersDropdownInput = ({
  excludeUserIds,
  name,
  isMulti,
  value,
  ...restProps
}: ControlledDropdownInputProps & {
  value?: string | string[];
  excludeUserIds?: string[];
}) => {
  const singleQueryPayload: Parameters<typeof UsersAdapter.getList>[0] = {
    filters: {
      id: value ?? isMulti ? [] : "",
    },
  };

  const defaultValueQuery = useQuery({
    queryKey: ["users", singleQueryPayload],
    queryFn: async () => {
      const { data } = await UsersAdapter.getList(singleQueryPayload);

      return data?.[0];
    },
    enabled: Boolean(value),
  });

  const allUsersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return UsersAdapter.getList({});
    },
  });

  return (
    <DropdownInput
      name={name ?? "user"}
      placeholder="Select a user"
      isMulti={isMulti}
      {...restProps}
      options={allUsersQuery.data?.data ?? []}
      defaultValue={defaultValueQuery.data ?? ""}
      isLoading={allUsersQuery.isLoading}
      labelKey="name"
      valueKey="id"
      onFetchData={async (payload) => {
        let { data } = await UsersAdapter.getList({
          ...payload,
          filters: {
            name: payload.searchTerm,
          },
        });

        // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
        if (Array.isArray(payload.value)) {
          data = data.filter((d) => payload.value.includes(d.id));
        }

        if (excludeUserIds?.length) {
          data = data.filter((u) => !excludeUserIds.includes(u.id));
        }

        return data;
      }}
    />
  );
};
