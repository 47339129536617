import { ErrorOnLoginCallbackLayout } from "#src/batteries-included-components/Layouts/Authentication/ErrorOnLoginCallback";
import { LoadingOnLoginLayout } from "#src/batteries-included-components/Layouts/Authentication/LoadingOnLogin";
import { LoadingOnLoginRedirect } from "#src/batteries-included-components/Layouts/Authentication/LoadingOnLoginRedirect";
import { LoggingOutLayout } from "#src/batteries-included-components/Layouts/Authentication/LoggingOut";
import { SwitchingCompanyLayout } from "#src/batteries-included-components/Layouts/Authentication/SwitchingCompany";
import { VerifyingOnLoginLayout } from "#src/batteries-included-components/Layouts/Authentication/VerifyingOnLogin";

export const AuthenticationLayouts = {
  LoadingOnLoginLayout: LoadingOnLoginLayout,
  SwitchingCompanyLayout: SwitchingCompanyLayout,
  VerifyingOnLoginLayout: VerifyingOnLoginLayout,
  ErrorOnLoginCallbackLayout: ErrorOnLoginCallbackLayout,
  LoadingOnLoginRedirectLayout: LoadingOnLoginRedirect,
  LoggingOutLayout: LoggingOutLayout,
};
