import { z } from "zod";
import {
  AttributeValueSchema,
  LookupWithSingleAttributeRefSchema,
} from "./AttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { UnitConfigurationSchema } from "./MeasurementTypeSchemas";
import {
  TemplatedReportSchema,
  TemplatedReportWithCategorySchema,
} from "./TemplatedReportSchemas";

export const ReportGeneratedStatus = {
  EXPORTING: "exporting",
  SUCCESS: "success",
  FAILED: "failed",
} as const;
export type ReportGeneratedStatusType =
  (typeof ReportGeneratedStatus)[keyof typeof ReportGeneratedStatus];

export const ReportStatusV3 = {
  LOCKED: "locked",
  UNLOCKED: "unlocked",
  ARCHIVED: "archived",
} as const;
export type ReportStatusV3Type =
  (typeof ReportStatusV3)[keyof typeof ReportStatusV3];

export const ReportInputValue = z.union([
  AttributeValueSchema,
  LookupWithSingleAttributeRefSchema,
]);
export type ReportInputValueType = z.infer<typeof ReportInputValue>;

export const ReportV2Schema = z
  .object({
    name: z.string().min(3).max(64),
    company_id: z.string().uuid(),
    status: z.nativeEnum(ReportGeneratedStatus),
    templated_report_name: z.string().min(3).max(64).regex(/^\w+$/).nullable(),
    templated_report: TemplatedReportWithCategorySchema.nullable(),
    input: z.record(ReportInputValue),
    s3_object_id: z.string().nullable(),
    report_version: z.string().nullable(),
    s3_download_link: z.string().url().nullable(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);
export type ReportV2Type = z.infer<typeof ReportV2Schema>;

export const ReportWithDownloadV2Schema = ReportV2Schema.merge(
  z.object({
    s3_download_link: z.string(),
  })
);
export type ReportWithDownloadV2Type = z.infer<
  typeof ReportWithDownloadV2Schema
>;

export const ReportVersionV3Schema = z
  .object({
    report_id: z.string().uuid(),
    version: z.number().int().positive(),
    is_current: z.boolean(),
    s3_object_id: z.string().nullable(),
    definition_version: z.string(),
    status: z.nativeEnum(ReportGeneratedStatus),
  })
  .merge(TemplatedReportSchema.pick({ row_styling_headers: true }))
  .merge(DomainModelMetaSchema.pick({ created_at: true }))
  .merge(DomainModelMetaExtendedSchema.pick({ created_by: true }));
export type ReportVersionV3Type = z.infer<typeof ReportVersionV3Schema>;

export const ReportV3Schema = ReportV2Schema.pick({
  id: true,
  name: true,
  company_id: true,
  templated_report_name: true,
  templated_report: true,
  input: true,
  s3_download_link: true,
  created_at: true,
  updated_at: true,
  created_by: true,
  updated_by: true,
}).merge(
  z.object({
    status: z.nativeEnum(ReportStatusV3),
    latest_version: ReportVersionV3Schema.pick({
      version: true,
      status: true,
      row_styling_headers: true,
      created_at: true,
      created_by: true,
    }),
  })
);
export type ReportV3Type = z.infer<typeof ReportV3Schema>;

export const ReportWithVersionsV3Schema = ReportV3Schema.omit({
  latest_version: true,
  s3_download_link: true,
}).merge(
  z.object({
    versions: z.array(ReportVersionV3Schema),
  })
);
export type ReportWithVersionsV3Type = z.infer<
  typeof ReportWithVersionsV3Schema
>;

export const ReportWithVersionV3Schema = ReportV3Schema.omit({
  latest_version: true,
}).merge(
  z.object({
    version: ReportVersionV3Schema,
  })
);
export type ReportWithVersionV3Type = z.infer<typeof ReportWithVersionV3Schema>;

export const CreateReportV3Schema = ReportV3Schema.pick({
  name: true,
  templated_report_name: true,
}).merge(
  ReportV3Schema.pick({
    input: true,
  }).partial()
);
export type CreateReportV3Type = z.infer<typeof CreateReportV3Schema>;

export const ReportInputValuesV3 = z.record(z.array(ReportInputValue));
export type ReportInputValuesV3Type = z.infer<typeof ReportInputValuesV3>;

export const ReportEmbeddedStyleVariant = {
  success: "success",
  warning: "warning",
  error: "error",
  default: "default",
} as const;
export type ReportEmbeddedStyleVariantType =
  (typeof ReportEmbeddedStyleVariant)[keyof typeof ReportEmbeddedStyleVariant];

export const ReportEmbeddedCellSchema = z.object({
  value: AttributeValueSchema,
  style: z.nativeEnum(ReportEmbeddedStyleVariant),
});
export type ReportEmbeddedCellType = z.infer<typeof ReportEmbeddedCellSchema>;

export const ReportEmbeddedRowSchema = z.object({
  row_id: z.string().uuid(),
  cells: z.record(ReportEmbeddedCellSchema),
});
export type ReportEmbeddedRowType = z.infer<typeof ReportEmbeddedRowSchema>;

export const ReportEmbeddedRowsSchema = z.array(ReportEmbeddedRowSchema);
export type ReportEmbeddedRowsType = z.infer<typeof ReportEmbeddedRowsSchema>;

export const ReportEmbeddedSheetHeaderSchema = z
  .object({
    display_name: z.string(),
    position: z.number().int().positive(),
  })
  .merge(
    UnitConfigurationSchema.pick({
      measurement_type: true,
      measurement_unit: true,
    })
  );
export type ReportEmbeddedSheetHeaderType = z.infer<
  typeof ReportEmbeddedSheetHeaderSchema
>;

export const ReportEmbeddedSheetSchema = z.object({
  name: z.string(),
  display_name: z.string(),
  position: z.number().int().positive(),
  headers: z.record(ReportEmbeddedSheetHeaderSchema),
});
export type ReportEmbeddedSheetType = z.infer<typeof ReportEmbeddedSheetSchema>;

export const ReportEmbeddedSheetsSchema = z.array(ReportEmbeddedSheetSchema);
export type ReportEmbeddedSheetsType = z.infer<
  typeof ReportEmbeddedSheetsSchema
>;
