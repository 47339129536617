import {
  SystemActionType,
  WorkflowStatus,
  WorkflowTaskStatus,
  WorkflowTaskStatusType,
  WorkflowTaskType,
  WorkflowType,
} from "@validereinc/domain";

export const getSystemActionStatus = ({
  workflow,
  workflowSteps,
  workflowStep,
}: {
  workflowStep: WorkflowTaskType;
  workflowSteps: WorkflowTaskType[];
  workflow: WorkflowType;
}): WorkflowTaskStatusType => {
  const workflowStepsSorted = workflowSteps
    .slice()
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  const step = workflow?.config.steps?.[workflowStep.step_id];
  const myNextStepIds = [];

  let status: WorkflowTaskStatusType = WorkflowTaskStatus.OPEN;

  // Since system actions don't return a status from BE, we need to extrapolate it from the workflow.
  if (
    step &&
    step.task.type === SystemActionType.ASSESS_TEMPLATED_REPORT_FOR_COMMENTS &&
    "assessment_success_next" in step.task &&
    "assessment_failure_next" in step.task
  ) {
    myNextStepIds.push(
      ...[step.task.assessment_success_next, step.task.assessment_failure_next]
    );
  } else if (step.next) {
    myNextStepIds.push(step.next);
  }
  if (step.task.type === SystemActionType.FORM_CHOICE) {
    step.task.choices.forEach((choice) => {
      if (choice.next) {
        myNextStepIds.push(choice.next);
      }
    });
  }

  const isNextStepPresentInActions = myNextStepIds.some((nextStepId) =>
    workflowStepsSorted.find((action) => action.step_id === nextStepId)
  );

  const amILastStep = !!step.end;

  // We can fake them as complete if:
  // 1. If the workflow is complete, then the task has completed
  // 2. If the workflow is in error/dismissed, and it is the latest action, it is errored. Workflow steps in descending order
  // 3. Its next step is in the list of actions: meaning that current system action was successfully executed
  // 4. OR, current system action is the last step and the workflow is not in progress.
  if (workflow?.status === WorkflowStatus.COMPLETE) {
    status = WorkflowTaskStatus.COMPLETE;
  } else if (
    workflow?.status === WorkflowStatus.ERROR &&
    workflowStepsSorted[0]?.step_id === workflowStep.step_id
  ) {
    status = WorkflowTaskStatus.ERROR;
  } else if (
    workflow?.status === WorkflowStatus.DISMISSED &&
    workflowStepsSorted[0]?.step_id === workflowStep.step_id
  ) {
    status = WorkflowTaskStatus.COMPLETE;
  } else if (
    (workflow?.status !== WorkflowStatus.IN_PROGRESS && amILastStep) ||
    isNextStepPresentInActions
  ) {
    status = WorkflowTaskStatus.COMPLETE;
  }

  return status;
};
