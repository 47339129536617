import {
  DeviceConfigurationFilterType,
  RecordValueConfigurationContext,
} from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useParams } from "#routers/hooks";
import { getDefaultDateRange } from "#utils/recordUtils";
import { useQuery } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  useFilters,
} from "@validereinc/common-components";
import { Device } from "@validereinc/domain";
import React, { useContext, useEffect, useMemo } from "react";

export const DeviceConfigurationFilterPanel = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const { form, record, recordValue } =
    useContext(RecordValueConfigurationContext) || {};
  const [storedFilters, setStoredFilters] =
    useFilters<DeviceConfigurationFilterType>(storageKey);
  const params = useParams<{ measurementType: string }>();

  const deviceSearchParams = {
    filters: {
      measurement_types: { $exact: params.measurementType },
    },
  };

  const deviceQuery = useQuery({
    queryKey: ["devices", deviceSearchParams],
    queryFn: async () => Device.getList(deviceSearchParams),
  });

  const defaultDateRange = useMemo(
    () => getDefaultDateRange(record, params.measurementType),
    [record, params.measurementType]
  );

  const filterValue = {
    device: storedFilters.device,
    dateRange:
      storedFilters.from && storedFilters.to
        ? {
            from: new Date(storedFilters.from),
            to: new Date(storedFilters.to),
          }
        : defaultDateRange,
  };

  const onFiltersChange = ({
    dateRange,
    ...filters
  }: Record<string, string> & { dateRange: { from: Date; to: Date } }) => {
    form?.setValue("start", dateRange?.from?.toString());
    form?.setValue("end", dateRange?.to?.toString());
    setStoredFilters({
      ...storedFilters,
      ...filters,
      ...(dateRange && {
        from: dateRange.from,
        to: dateRange.to,
      }),
    });
  };

  useEffect(() => {
    setStoredFilters({
      ...storedFilters,
      ...filterValue.dateRange,
      device:
        storedFilters.device ||
        recordValue?.configuration?.sources?.[0]?.device_id,
    });
  }, [record, storedFilters.device]);

  return (
    <FilterPanel
      value={filterValue}
      onChange={onFiltersChange}
      defaultActiveKeys={["Asset"]}
      isVertical
      filters={[
        {
          component: (
            <DropdownInput
              style={{ width: "355px" }}
              key="device"
              name="device"
              placeholder="Select Device"
              labelKey="name"
              valueKey="id"
              options={deviceQuery.data?.data ?? []}
              isLoading={deviceQuery.isLoading}
              isSearchable
              isRequired
              label="Source Device"
              isClearable={false}
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              name="dateRange"
              variant={"time"}
              isRange
              isRequired
              label={"Submission Date"}
            />
          ),
        },
      ]}
    />
  );
};
