import { OrganizationRoute } from "#src/routes/organization";
import { RoutePath } from "#src/utils/route";

export const EquipmentListRoute = OrganizationRoute.concat(
  new RoutePath({
    path: "/equipment",
    title: "Equipment",
  })
);

// Declared here instead of ../create to prevent circular dependency
export const EquipmentCreateMode = {
  ASSET_BUILDER: "asset-builder",
  QUICK_CREATE: "quick-create",
} as const;
export type EquipmentCreateModeType =
  (typeof EquipmentCreateMode)[keyof typeof EquipmentCreateMode];
export const EquipmentCreateRoute = EquipmentListRoute.concat(
  new RoutePath<"mode">({
    path: "/create/:mode",
    title: "Create Equipment",
  })
);

/** @deprecated directly declaring and referencing this breadcrumb is unnecessary. Use new RoutePath based pattern and directly generate breadcrumbs using 'useBreadcrumbsFromRoute()' hook */
export const EQUIPMENT_LIST_BREADCRUMB = EquipmentListRoute.toBreadcrumb();

export * from "./EquipmentListPage";
