import type { ImportDataActionStatus } from "#src/batteries-included-components/Buttons/ImportDataAction/ImportDataActionContext/types";
import { useListDatasets } from "#src/components/hooks/adapters/useDatasets";
import FileService from "#src/components/Services/FileService";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useQueueUnique } from "#src/hooks/useQueue";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DropdownInput,
  FileInput,
  Form,
  useForm,
  useToast,
  type DialogProps,
} from "@validereinc/common-components";
import {
  Resources,
  TemplateAdapter,
  TransactionAdapter,
  type TransactionType,
} from "@validereinc/domain";
import { downloadBlob } from "@validereinc/utilities";
import snakeCase from "lodash/snakeCase";
import startCase from "lodash/startCase";
import mime from "mime";
import React, { useState } from "react";
import { z } from "zod";
import { getDataSetImportDataActionQueueKey } from "./DataSetImportDataDialog.helpers";

const queueKey = getDataSetImportDataActionQueueKey();
const acceptedMimeTypes = [
  mime.getType("csv"),
  mime.getType("xls"),
  mime.getType("xlsx"),
  mime.getType("json"),
].join(",");
const DataSetImportDialogForm = z.object({
  dataset: z.string().uuid(),
  file: z
    .array(z.instanceof(File))
    .refine((files) => files.every((f) => acceptedMimeTypes.includes(f.type)), {
      message: "Invalid file type",
    }),
});
type DataSetImportDialogFormType = z.infer<typeof DataSetImportDialogForm>;

export const DataSetImportDataDialog = ({
  isOpen,
  onClose,
}: Pick<DialogProps, "isOpen" | "onClose">) => {
  const form = useForm<DataSetImportDialogFormType>();
  const queryClient = useQueryClient();
  const selectedDataset = form.watch("dataset");

  const [queue, setQueue] = useQueueUnique<TransactionType>(
    [],
    queueKey,
    (tx) => tx.transaction_id
  );
  const [status, setStatus] = useState<ImportDataActionStatus>();
  const { toast } = useToast();
  const { claims } = useAuthenticatedContext();
  const { data: datasets, isLoading: areDatasetsLoading } = useListDatasets();
  const { data: templateBlob, isLoading: isTemplateBlobLoading } = useQuery(
    ["datasets", "template", selectedDataset],
    () =>
      TemplateAdapter.dataset.getOne({
        datasetId: selectedDataset,
        companyId: claims.company.id!,
      }),
    {
      enabled: Boolean(selectedDataset) && Boolean(claims?.company?.id),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDownloadTemplateClick = () => {
    if (!templateBlob) {
      toast.push({
        intent: "error",
        description: "Template not available!",
      });
      return;
    }

    const selectedDatasetName = datasets?.data.find(
      (d) => d.id === selectedDataset
    )?.name;
    const fileName =
      `${selectedDatasetName ? `${snakeCase(selectedDatasetName)}_` : ""}import_template`.toLowerCase();

    try {
      downloadBlob(fileName, templateBlob);
      toast.push({
        intent: "success",
        description: `Template ${fileName}.xlsx downloaded.`,
      });
    } catch (err) {
      toast.push({
        intent: "error",
        description: `Template failed to download.`,
      });
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to download import template for templated configuration",
      });
    }
  };

  const handleFileUpload = async (file: File) => {
    if (!selectedDataset || !claims) {
      return {
        file,
        uploadedFile: null,
        transactionId: null,
      };
    }

    // extract key file details
    const { name, type: contentType, lastModified } = file;

    // create a file in the file upload service
    setStatus({ percentComplete: 1, label: "Uploading..." });
    const {
      status: createFileStatus,
      data: {
        data: [uploadedFileData],
        meta: { transaction_id: transactionId },
      },
    } = await FileService.createFile({
      name,
      contentType,
      description:
        "A user-uploaded file through the import data action component",
      tags: {
        lastModified: new Date(lastModified).toISOString(),
      },
      datasetId: selectedDataset,
      companyId: claims.company?.id,
    })!;

    if (createFileStatus !== 200 && createFileStatus !== 201) {
      throw new Error("Could not create an entry in the file upload service");
    }

    // extract the ID and upload URL of the file entry in the file upload service
    const { file_id: fileId, uri: uploadUrl } = uploadedFileData;

    // upload the file to the pre-signed upload URL
    const { status: uploadFileStatus } = await FileService.uploadFile({
      url: uploadUrl,
      fileBlob: file,
    })!;

    if (uploadFileStatus !== 200 && uploadFileStatus !== 201) {
      throw new Error(
        "Could not upload file to the created entry in file upload service"
      );
    }

    // poll and check to see if the file scanning in the file upload service has completed
    setStatus({ percentComplete: 33, label: "Scanning..." });
    let scanStatus = "unknown";
    let pollLimit = 10;

    while (pollLimit > 0 && !["safe", "unsafe"].includes(scanStatus)) {
      const {
        data: {
          data: [{ file_scan_status }],
        },
      } = await FileService.getFileDownloadUrl(fileId)!;

      scanStatus = file_scan_status;

      // scan status re-check polling interval
      await new Promise((res) => setTimeout(res, 1000));
      pollLimit -= 1;
    }

    // verify based on the scan result
    setStatus({ percentComplete: 66, label: "Verifying..." });

    if (scanStatus !== "safe") {
      throw new Error(
        "The uploaded file has been deemed as unsafe or failed to complete verification"
      );
    }

    setStatus({ percentComplete: 100, label: "Uploading Complete" });
    return {
      file,
      uploadedFile: {
        ref: fileId,
        name,
      },
      transactionId,
    };
  };

  const formSubmissionMutation = useMutation({
    mutationFn: async (formValues: DataSetImportDialogFormType) => {
      await DataSetImportDialogForm.safeParseAsync(formValues);

      const { transactionId } = await handleFileUpload(formValues.file.pop()!);

      if (!transactionId) {
        throw new Error(
          "Transaction ID not returned for initiated data import"
        );
      }

      const transaction = await TransactionAdapter.getOne({
        id: transactionId,
        meta: { history: false },
      });

      if (!transaction?.data.length) {
        throw new Error("No transactions found for initiated transaction");
      }

      return transaction;
    },
    onSuccess: (transaction) => {
      setQueue(queue.insert(transaction.data[0]));
      onClose();
      toast.push("Successfully started data import");
      queryClient.invalidateQueries({
        queryKey: [Resources.TRANSACTION],
      });
      queryClient.invalidateQueries({ queryKey: [Resources.DATASET] });
    },
    onError: (err) => {
      console.error(err);
      toast.push({
        intent: "error",
        description: "Failed to start data import. Try again?",
      });
    },
    onSettled: () => {
      setStatus({ percentComplete: 0, label: "Ready" });
    },
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Import Data"
      actionRow={[
        <Button
          key="confirm"
          variant="primary"
          onClick={form.handleSubmit(formSubmissionMutation.mutate)}
          isLoading={formSubmissionMutation.isLoading}
        >
          Import
        </Button>,
      ]}
    >
      Choose the dataset you&apos;d like to upload to and select the import
      file.
      <Form {...form}>
        <DropdownInput
          name="dataset"
          label="Dataset"
          isRequired
          isLoading={areDatasetsLoading}
          isSortedAlphabetically
          placeholder="Select a dataset to import against"
          options={
            datasets?.data.map((set) => ({
              name: startCase(set.name),
              id: set.id,
            })) ?? []
          }
          labelKey="name"
          valueKey="id"
          isFluid
          style={{ marginTop: 16 }}
        />
        <Button
          icon="download-simple"
          variant="outline"
          onClick={handleDownloadTemplateClick}
          isLoading={Boolean(
            areDatasetsLoading || (selectedDataset && isTemplateBlobLoading)
          )}
          disabled={!selectedDataset}
        >
          Download Import Template
        </Button>
        <FileInput
          name="file"
          label="Select file to upload"
          placeholder="Upload a .csv, .xls, .xlsx, or .json file"
          isRequired={true}
          rules={{
            required: "A file with data to import is required",
          }}
          acceptedFileTypes={acceptedMimeTypes}
          showIcon={true}
          style={{ marginTop: 12 }}
          isDisabled={
            !selectedDataset ||
            form.formState.isSubmitting ||
            !!status?.percentComplete
          }
        />
      </Form>
    </Dialog>
  );
};
