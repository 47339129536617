import { AssetBuilderFilterArea } from "#src/batteries-included-components/FilterAreas/AssetBuilderFilterAreas/AssetBuilderFilterAreas";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  EmptyState,
  Icon,
  IconLoading,
  RadioInput,
  RadioSelectionCardOptionType,
  type StorageKeys,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./AssetTemplateRadioInput.module.scss";
const cx = classNames.bind(styles);

export const AssetTemplateRadioInput = ({
  filterConfigStorageKey,
  isTemplateQueryDisabled,
  availableTemplatesQueryIsLoading,
  availableTemplatesOptions,
  isOptionSelected,
  handleOnChange,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  isTemplateQueryDisabled: boolean;
  availableTemplatesQueryIsLoading: boolean;
  availableTemplatesOptions: RadioSelectionCardOptionType[];
  isOptionSelected: boolean;
  handleOnChange?: (templateName: string) => void;
}) => {
  const { localize } = useLocalization();

  const [canUserUseTemplatedConfigurations] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: {
      $and: [
        "templated_configuration:read",
        "templated_configuration_runs:write",
      ],
    },
  });

  if (!canUserUseTemplatedConfigurations) {
    return null;
  }

  return (
    <>
      <p className={cx("empty-state-label")}>Asset Template</p>
      <AssetBuilderFilterArea filterConfigStorageKey={filterConfigStorageKey} />
      <div className={cx("container")}>
        {isTemplateQueryDisabled ? (
          <EmptyState
            icon={<Icon variant="files" />}
            title="No asset templates to display yet"
            suggestion={`Select an ${localize("equipment")} type to view available asset templates.`}
          />
        ) : availableTemplatesQueryIsLoading ? (
          <div className={cx("loading-container")}>
            <IconLoading
              speed="slow"
              size={24}
            />
          </div>
        ) : availableTemplatesOptions.length === 0 ? (
          <EmptyState
            icon={<Icon variant="warning" />}
            title="No asset templates available"
            suggestion={`Asset templates have not been created for this type of ${localize("equipment")} yet.`}
          />
        ) : (
          <div className={cx("asset-template-radio-container")}>
            <RadioInput
              name="template_name"
              label="Asset Template"
              className={cx("asset-template-radio-input-wrapper")}
              componentClassName={cx("asset-template-radio-input", {
                "option-selected": isOptionSelected,
              })}
              optionClassName={cx("asset-template-radio-input-option")}
              options={availableTemplatesOptions}
              onChange={handleOnChange}
              shouldUseRadioSelectionCard
              isLabelShown={false}
              isRequired
            />
          </div>
        )}
      </div>
    </>
  );
};
