import {
  FilterArea,
  useFilterAreaContentContext,
} from "#src/components/FilterArea";
import {
  Button,
  Icon,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import { SortDirection, SortDirectionType } from "@validereinc/domain";
import React, { useState } from "react";
import { z } from "zod";

export const AssetBuilderFilterSchema = z
  .object({
    displayName: z.string(),
    sortDirection: z.enum([SortDirection.ASCENDING, SortDirection.DESCENDING]),
  })
  .partial();
export const AssetBuilderFilterSchemaKeys =
  AssetBuilderFilterSchema.keyof().Enum;
export type AssetBuilderFiltersType = z.infer<typeof AssetBuilderFilterSchema>;

export const AssetBuilderFilterAreaContent = () => {
  const { handleOnChange } = useFilterAreaContentContext();

  const [sortDirection, setSortDirection] = useState<SortDirectionType>(
    SortDirection.DESCENDING
  );
  return (
    <>
      <TextInput
        name={AssetBuilderFilterSchemaKeys.displayName}
        style={{ flexGrow: 1 }}
        placeholder="Search Asset Templates..."
        type="search"
        isInline
        onChange={(val) =>
          handleOnChange(val, AssetBuilderFilterSchemaKeys.displayName)
        }
      />
      <Button
        variant="outline"
        slotRight={<Icon variant="arrows-down-up" />}
        onClick={() => {
          if (sortDirection === SortDirection.DESCENDING) {
            setSortDirection(SortDirection.ASCENDING);
          } else {
            setSortDirection(SortDirection.DESCENDING);
          }
          handleOnChange(
            sortDirection,
            AssetBuilderFilterSchemaKeys.sortDirection
          );
        }}
      >
        {`Name ${sortDirection === SortDirection.ASCENDING ? "Z-A" : "A-Z"}`}
      </Button>
    </>
  );
};

export const AssetBuilderFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        displayName: "",
        sortDirection: SortDirection.ASCENDING,
      }}
      applyDefaultValues
    >
      <FilterArea.Container style={{ marginBottom: 8 }}>
        <FilterArea.Content>
          {() => (
            <div
              style={{
                display: "flex",
                gap: 8,
                flexGrow: 1,
              }}
            >
              <AssetBuilderFilterAreaContent />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
