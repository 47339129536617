import { linkToFormCategoryDetails } from "#routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { useNavigate } from "#src/Routers/hooks";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useDeleteOneFormSubmission } from "#src/components/hooks/adapters/useFormSubmissions";
import { linkToCreateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/create-form-submission";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import { WorkflowTaskAddFormSubmissionRoutePath } from "#src/routes/workflows/all/[workflowId]/detail/task/[taskId]/form-submission/add";
import { ExceptionUtils } from "#src/utils/exception";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  Button,
  DropdownMenu,
  EmptyState,
  Icon,
  KeyValueList,
  Pill,
  PillProps,
} from "@validereinc/common-components";
import {
  BaseError,
  FormSubmissionStatus,
  FormSubmissionTaskType,
  FormSubmissionType,
  UsersAdapter,
  WorkflowTaskStatus,
  WorkflowTaskType,
  WorkflowTaskWithFormSubmissionType,
  WorkflowType,
  isWorkflowTaskWithFormSubmission,
  type FormSchemaType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { Base64 } from "js-base64";
import React, { useMemo } from "react";
import styles from "./WorkflowFormSubmission.module.scss";

type WorkflowFormSubmissionProps = {
  workflowTask: WorkflowTaskType;
  formSchema?: FormSchemaType;
  workflow?: WorkflowType;
};

const cx = classNames.bind(styles);

const WorkflowTaskFormSubmissionEntry = ({
  submission,
  formSchema,
}: {
  submission: WorkflowTaskWithFormSubmissionType["form_submission"][0];
  formSchema: FormSchemaType;
}) => {
  const { data: createdByUser } = useQuery(
    ["users", submission.created_by],
    () => {
      if (!submission.created_by) return;
      return UsersAdapter.getOne({ id: submission.created_by });
    },
    {
      enabled: Boolean(submission.created_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const title = `${
    submission.form_schema?.name ? `${submission.form_schema.name} - ` : ""
  }${submission.id.split("-")[0]}`;

  const getStatusPillVariant = (
    status: FormSubmissionType["status"]
  ): PillProps["variant"] => {
    const map: Partial<
      Record<FormSubmissionType["status"], PillProps["variant"]>
    > = {
      pending: "warning",
      submitted: "info",
      validated: "success",
      invalidated: "error",
    };

    return map[status] ?? "default";
  };

  return (
    <KeyValueList
      variant="shaded"
      className={cx("keyValueListContainer")}
      data={[
        {
          title: "Form Submission",
          value: (
            <RoutingLink
              label={title}
              to={
                submission.status === FormSubmissionStatus.DRAFT
                  ? linkToUpdateFormSubmission(
                      formSchema.form_category.id,
                      formSchema.id,
                      submission.id
                    )
                  : linkToFormSubmissionDetail(submission.id)
              }
            >
              {title}
            </RoutingLink>
          ),
        },
        {
          title: "Status",
          value: (
            // REVIEW: something is up with the BE schemas
            <Pill variant={getStatusPillVariant(submission.status)}>
              {submission.status}
            </Pill>
          ),
        },
        {
          title: "Submitted By",
          value: createdByUser?.name ?? submission.created_at,
        },
        {
          title: "Date Submitted",
          value: datetimeFormatter(new Date(submission.created_at)),
        },
      ]}
    />
  );
};

export const WorkflowTaskFormSubmission = ({
  workflowTask,
  formSchema,
  workflow,
}: WorkflowFormSubmissionProps) => {
  const encodedAndSerializedDefaultValues = useMemo(() => {
    let sanitizedDefaultValuesObject = {} as Record<string, string>;
    const prepopulatedValuesForFormSubmission =
      (
        workflow?.config.steps?.[workflowTask.step_id]
          ?.task as FormSubmissionTaskType
      )?.input ?? {};

    sanitizedDefaultValuesObject = Object.fromEntries(
      Object.entries(prepopulatedValuesForFormSubmission)
        .map(([questionPath, defaultValue]) => {
          if (defaultValue.startsWith("$")) {
            return [
              questionPath,
              workflow?.variables?.[defaultValue] ?? "",
            ] as [string, string];
          }
          return [questionPath, defaultValue] as [string, string];
        })
        .filter(([_, value]) => value !== undefined && value !== null)
    );

    try {
      return Base64.encode(JSON.stringify(sanitizedDefaultValuesObject));
    } catch (e) {
      // Return a default value of an encoded empty object + error reporting:
      ExceptionUtils.reportException(
        new BaseError(
          `Could not encode form submission default values from workflow: ${JSON.stringify(sanitizedDefaultValuesObject)}`
        ),
        "error"
      );
      return Base64.encode(JSON.stringify({}));
    }
  }, [workflow, workflowTask?.step_id]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: removeFormSubmissionFromTask } = useDeleteOneFormSubmission({
    successMessage: "Successfully removed form submission association to task",
    errorMessage: "Unable to remove form submission association to task",
    customInvalidateFunction: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows"],
      });
    },
  });

  const hasSubmissions = isWorkflowTaskWithFormSubmission(workflowTask)
    ? workflowTask.form_submission.length > 0
    : false;

  // loading state
  if (!formSchema) {
    return <div className={cx("skeleton", "skeleton--fill")}></div>;
  }
  const addSubmissionButton = (
    <DropdownMenu
      options={[
        {
          label: "New Submission",
          onClick: () =>
            navigate({
              pathname: linkToCreateFormSubmission(
                formSchema?.form_category_id,
                formSchema.id
              ),
              query: {
                "task-id": workflowTask.id,
                "associated-asset-id": workflowTask.workflow?.facility?.id,
                "associated-asset-type": "facility",
                "default-values": encodedAndSerializedDefaultValues,
              },
            }),
        },
        {
          label: "Existing Submission",
          onClick: () =>
            navigate(
              WorkflowTaskAddFormSubmissionRoutePath.toLinkParts({
                pathParams: {
                  workflowId: workflowTask.workflow_id,
                  taskId: workflowTask.id,
                },
                queryParams: {
                  formSchemaId: formSchema.id,
                },
              })
            ),
        },
      ]}
    >
      <Button
        icon="caret-down"
        iconPosition="right"
        iconProps={{
          size: 16,
          weight: "fill",
        }}
        variant="primary"
        size="x-small"
        slotLeft={
          <Icon
            variant="plus-circle"
            size={24}
          />
        }
      >
        Add Form Submission
      </Button>
    </DropdownMenu>
  );
  return (
    <>
      <div>
        {!hasSubmissions ? (
          <div className={cx("emptyStateContainer")}>
            <p className={cx("title")}>Form Submission</p>
            <EmptyState
              title="No form submissions to display"
              suggestion="Add a new or existing form submission to the task to see it here"
              icon={
                <Icon
                  variant="files"
                  size={32}
                />
              }
              className={cx("emptyState")}
              action={
                <div className={cx("attachSubmissionTrigger")}>
                  {addSubmissionButton}
                </div>
              }
            />
          </div>
        ) : (
          <div className={cx("contentsContainer")}>
            <Accordion
              defaultActiveKeys={
                workflowTask.form_submission
                  ? [workflowTask.form_submission[0].id]
                  : []
              }
            >
              {(workflowTask.form_submission ?? []).map((submission, idx) => (
                <Accordion.AccordionPanel
                  key={submission.id}
                  title={`Form Submission ${idx === 0 ? "" : idx + 1}`}
                  dataKey={submission.id}
                  actionRow={
                    <div className={cx("actionRow")}>
                      <RoutingLink
                        to={
                          submission.status === FormSubmissionStatus.DRAFT
                            ? linkToUpdateFormSubmission(
                                formSchema.form_category.id,
                                formSchema.id,
                                submission.id
                              )
                            : linkToFormSubmissionDetail(submission.id)
                        }
                      >
                        <Button
                          iconPosition="left"
                          icon="share"
                        >
                          View
                        </Button>
                      </RoutingLink>
                      <Button
                        icon="minus-circle"
                        onClick={() => {
                          removeFormSubmissionFromTask({
                            id: submission.id,
                          });
                        }}
                        disabled={
                          workflowTask.status !== WorkflowTaskStatus.OPEN
                        }
                      />
                    </div>
                  }
                >
                  <WorkflowTaskFormSubmissionEntry
                    key={submission.id}
                    submission={submission}
                    formSchema={formSchema}
                  />
                </Accordion.AccordionPanel>
              ))}
            </Accordion>
            {workflowTask.status === WorkflowTaskStatus.OPEN &&
              addSubmissionButton}
          </div>
        )}
      </div>
      <Accordion defaultActiveKeys={["form-overview"]}>
        <Accordion.AccordionPanel
          title={"Form Overview"}
          dataKey={"form-overview"}
        >
          <KeyValueList
            data={[
              {
                title: "Category",
                value: (
                  <RoutingLink
                    to={linkToFormCategoryDetails(formSchema.form_category_id)}
                  >
                    {formSchema.form_category.name}
                  </RoutingLink>
                ),
              },
              {
                title: "Template",
                value: (
                  <RoutingLink
                    to={linkToFormTemplateDetail(
                      formSchema.form_category.id,
                      formSchema.id
                    )}
                  >
                    {formSchema.name}
                  </RoutingLink>
                ),
              },
            ]}
            color="neutral"
            variant="shaded"
            className={cx("keyValueListContainer")}
          />
        </Accordion.AccordionPanel>
      </Accordion>
    </>
  );
};
