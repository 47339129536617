import {
  CustomReportVariantType,
  CustomReportVariants,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { useCreateCalculatorResultSavedFilter } from "#hooks/adapters/useCalculatorResults";
import { useCreateRecordSavedFilter } from "#hooks/adapters/useRecords";
import { SavedFilterTag } from "#src/components/hooks/FilterPanel/useSavedFilters";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  Button,
  Dialog,
  DialogProps,
  Form,
  RadioInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import { FilterObjectType, SavedFilterStatus } from "@validereinc/domain";
import React from "react";

export const CreateCustomReportModal = ({
  isOpen,
  onClose,
  onSave,
  filter,
  groupBy,
  metadata,
  variant,
}: Pick<DialogProps, "isOpen" | "onClose"> & {
  onSave?: (id: string) => void;
  filter: FilterObjectType<unknown>;
  groupBy?: string[];
  metadata?: unknown[];
  variant: CustomReportVariantType;
}) => {
  const {
    v2: {
      userInfo: { checkHasPermissions },
    },
  } = useAuthenticatedContext();

  const canWriteCompanyReports = checkHasPermissions(
    "company_custom_reports:write"
  );

  const form = useForm({
    defaultValues: {
      status: SavedFilterStatus.DRAFT,
    },
  });

  const onSuccess = (data: unknown) => {
    onClose();
    onSave?.(data?.data?.id);
  };

  const createVolumetricCustomReport = useCreateRecordSavedFilter({
    onSuccess,
  });

  const createEmissionsCustomReport = useCreateCalculatorResultSavedFilter({
    onSuccess,
  });

  const createCustomReport =
    variant === CustomReportVariants.EMISSIONS
      ? createEmissionsCustomReport
      : createVolumetricCustomReport;

  const onSubmit = form.handleSubmit((values) => {
    const { name, status } = values;
    createCustomReport.mutate({
      name,
      status,
      filter,
      group_by: groupBy,
      metadata,
      tag: SavedFilterTag.CUSTOM_REPORT,
    });
  });

  return (
    <Dialog
      isOpen={isOpen}
      title="Create New Custom Report"
      actionRow={[
        <Button
          key="confirm-save-custom-report"
          variant="primary"
          onClick={onSubmit}
        >
          Save
        </Button>,
      ]}
      onClose={onClose}
    >
      <Form {...form}>
        <TextInput
          name="name"
          label="Name"
          placeholder="Unsaved Report"
          isRequired
        />
        <RadioInput
          name="status"
          label="Access"
          isRequired
          options={[
            {
              label: "Save to My Reports",
              value: SavedFilterStatus.DRAFT,
            },
            ...(canWriteCompanyReports
              ? [
                  {
                    label: "Save to Company Reports",
                    value: SavedFilterStatus.ACTIVE,
                  },
                ]
              : []),
          ]}
          valueKey="value"
          labelKey="label"
        />
      </Form>
    </Dialog>
  );
};
