import { useNavigate, useSearchParams } from "#src/Routers/hooks";
import { AddFormSubmission } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission";
import {
  AddFormSubmissionContextProvider,
  useAddFormSubmissionContext,
} from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useGetOneWorkflowTask } from "#src/components/hooks/adapters/useWorkflowTasks";
import { useGetOneWorkflow } from "#src/components/hooks/adapters/useWorkflows";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { WorkflowTaskAddFormSubmissionRoutePath } from "#src/routes/workflows/all/[workflowId]/detail/task/[taskId]/form-submission/add";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Banner, Button, Page, useToast } from "@validereinc/common-components";
import {
  FormSubmissionType,
  Resources,
  WorkflowTaskAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import styles from "./WorkflowTaskAddFormSubmissionPage.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskAddFormSubmissionPageContent = () => {
  const queryClient = useQueryClient();
  const { selectedFormSubmissions } = useAddFormSubmissionContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { workflowId, taskId } = useParams<{
    workflowId: string;
    taskId: string;
  }>();
  const { data: workflowDetails } = useGetOneWorkflow(
    { id: workflowId },
    {
      enabled: Boolean(workflowId),
    }
  );

  const { data: workflowTaskDetails } = useGetOneWorkflowTask(
    { id: taskId },
    { enabled: Boolean(taskId) }
  );

  const { mutate: associateFormSubmissionsToWorkflowTask } = useMutation({
    mutationFn: async (formSubmissions: FormSubmissionType[]) => {
      setIsSubmitting(true);

      try {
        await Promise.all(
          formSubmissions.map(({ id }) =>
            WorkflowTaskAdapter.formSubmission.updateOne({
              id,
              data: undefined,
              meta: {
                taskId,
              },
            })
          )
        );

        queryClient.invalidateQueries({ queryKey: ["workflows"] });
        queryClient.invalidateQueries({ queryKey: [Resources.WORKFLOW] });
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: ["workflows"],
          });
          queryClient.refetchQueries({
            queryKey: [Resources.WORKFLOW],
          });
        }, 4000);

        toast.push({
          intent: "success",
          description: `Successfully added ${selectedFormSubmissions.length} form submission(s) to workflow task`,
        });

        navigate(
          WorkflowDetailsRoutePath.toLinkParts({
            pathParams: {
              workflowId,
            },
          })
        );
      } catch (err) {
        toast.push({
          intent: "error",
          description:
            "Couldn't add selected form submissions to the workflow task",
        });
        setIsSubmitting(false);
      }
    },
  });

  const [breadcrumbs] = useBreadcrumbsFromRoute(
    WorkflowTaskAddFormSubmissionRoutePath,
    {
      "/detail": {
        title: workflowDetails?.data.name,
      },
      ":taskId": {
        title: workflowTaskDetails?.data.name,
      },
    }
  );

  const handleSubmit = async () => {
    associateFormSubmissionsToWorkflowTask(selectedFormSubmissions);
  };

  return (
    <Page
      title={WorkflowTaskAddFormSubmissionRoutePath.title}
      category={WorkflowTaskAddFormSubmissionRoutePath.previous?.title}
      breadcrumbs={breadcrumbs}
      footer={
        <>
          <Button
            onClick={() => {
              history.goBack();
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !selectedFormSubmissions.length}
            >
              Add to Workflow Task
            </Button>
          </div>
        </>
      }
    >
      {workflowDetails?.data && workflowTaskDetails?.data ? (
        <Banner
          className={cx("banner")}
          titleText={`The selected submissions will be associated to a workflow task "${workflowTaskDetails?.data.name}" in the Workflow "${workflowDetails.data?.name}"`}
          actionContent={
            <RoutingLink
              to={WorkflowDetailsRoutePath.toLink({
                pathParams: { workflowId: workflowDetails.data?.id },
              })}
            >
              View Workflow
            </RoutingLink>
          }
          isDismissable
        />
      ) : null}
      <AddFormSubmission
        selectedFormSubmissionsPanelProps={{
          emptyStateProps: {
            suggestion: "Select form submissions to add to workflow task.",
          },
        }}
      />
    </Page>
  );
};

export const WorkflowTaskAddFormSubmissionPage = () => {
  const [{ formSchemaId }] = useSearchParams<{ formSchemaId: string }>();
  const storageKeys = useStorageKey(
    `workflows-form-submissions-add-${formSchemaId}`
  );

  return (
    <AddFormSubmissionContextProvider
      {...storageKeys}
      filtersDefaultValues={{
        form_schema_id: formSchemaId,
      }}
    >
      <WorkflowTaskAddFormSubmissionPageContent />
    </AddFormSubmissionContextProvider>
  );
};
