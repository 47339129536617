import { AuthenticationLayouts } from "#src/batteries-included-components/Layouts/Authentication";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { ExceptionUtils } from "#src/utils/exception";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import styles from "./Layout.module.css";
import LayoutContent from "./LayoutContent";

const cx = classNames.bind(styles);

export const Layout = (props: RouteComponentProps) => {
  const { isLoading, isSwitching } = useAuthenticatedContext();

  useEffect(() => {
    if (isLoading) return;

    ExceptionUtils.registerLifecycleEvent({
      category: "tenant",
      type: "info",
      message: "Loaded session & tenant details. App is ready.",
    });
  }, [isLoading]);

  if (isSwitching) {
    return <AuthenticationLayouts.SwitchingCompanyLayout />;
  }

  if (isLoading) {
    return <AuthenticationLayouts.LoadingOnLoginLayout />;
  }

  return <LayoutContent {...props} />;
};

export default Layout;
