import { useFile } from "#src/hooks/useFile";
import { FileUploadInput } from "@validereinc/common-components";
import React from "react";

export const FileField = (props) => {
  const { handleFileDownload, handleFileUpload } = useFile();

  return (
    <FileUploadInput
      {...props}
      showIcon={true}
      uploadFile={handleFileUpload}
      downloadFile={handleFileDownload}
    />
  );
};
