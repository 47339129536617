import React, { useCallback } from "react";
import {
  BaseError,
  ReportV3Adapter,
  Resources,
  WorkflowUserTaskAttachment,
  WorkflowUserTaskAttachmentReport,
  WorkflowUserTaskAttachmentType,
} from "@validereinc/domain";
import styles from "./WorkflowTaskAttachment.module.scss";
import classNames from "classnames/bind";
import {
  Button,
  KeyValueList,
  Skeleton,
  Tag,
  toast,
} from "@validereinc/common-components";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import startCase from "lodash/startCase";
import { TemplatedReportsGeneratedReportDetailsRoutePath } from "#routes/reports/templated-reports/template/[templateName]/generated/[reportId]";
import { TemplatedReportsTemplateDetailRoutePath } from "#routes/reports/templated-reports/template/[templateName]";
import { TemplatedReportsCategoriesDetailRoutePath } from "#routes/reports/templated-reports/categories/[categoryId]";
import { useQuery } from "@tanstack/react-query";
import { downloadLink } from "@validereinc/utilities";
import { ExceptionUtils } from "#utils/exception";

const cx = classNames.bind(styles);

export const getAttachmentTitle = (attachment: WorkflowUserTaskAttachment) => {
  if (attachment.type === WorkflowUserTaskAttachmentType.FORM_SUBMISSION) {
    return "Form Submission";
  }
  if (attachment.type === WorkflowUserTaskAttachmentType.RECORD) {
    return "Records";
  }
  if (attachment.type === WorkflowUserTaskAttachmentType.REPORT) {
    return "Report Details";
  }
  return "Details";
};

export const ReportAttachmentHeader = ({
  attachment,
  taskId,
}: {
  attachment: WorkflowUserTaskAttachmentReport;
  taskId: string;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [Resources.REPORT, attachment.report_id],
    queryFn: () => {
      if (!attachment.report_id) return;
      return ReportV3Adapter.getOne({
        id: attachment.report_id,
      });
    },
    enabled: !!attachment.report_id,
    staleTime: 3 * 60 * 1000,
  });
  const report = data?.data;
  const downloadReport = useCallback(() => {
    try {
      if (!report?.s3_download_link) {
        throw new BaseError("Report download link does not exist.", {
          getExplanation: () =>
            "Failed to download report: download link does not exist.",
        });
      }

      toast.push({
        intent: "success",
        description: "Successfully exported report.",
      });
      downloadLink(report.s3_download_link, report.name);
    } catch (error) {
      toast.push({
        intent: "error",
        description: "Unable to export report.",
      });
      ExceptionUtils.reportException(error, "error", {
        sourceComponent: "ReportAttachmentHeader",
      });
    }
  }, [report]);

  return (
    <div className={cx("accordion-actions")}>
      <Button
        icon="arrow-square-out"
        disabled={!report?.s3_download_link}
        isLoading={isLoading}
        onClick={downloadReport}
      />
      <RoutingLink
        to={
          report?.templated_report_name && report?.latest_version.version
            ? TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts({
                pathParams: {
                  reportId: report.id,
                  templateName: report.templated_report_name,
                },
                queryParams: {
                  version: report.latest_version.version.toString(),
                  taskId: taskId,
                },
              })
            : ""
        }
      >
        <Button
          iconPosition="left"
          icon="share"
          disabled={!report}
          isLoading={isLoading}
        >
          View
        </Button>
      </RoutingLink>
    </div>
  );
};

const ReportAttachment = ({
  taskId,
  attachment,
}: {
  taskId: string;
  attachment: WorkflowUserTaskAttachmentReport;
}) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [Resources.REPORT, attachment.report_id],
    queryFn: () => {
      if (!attachment.report_id) return;
      return ReportV3Adapter.getOne({
        id: attachment.report_id,
      });
    },
    enabled: !!attachment.report_id,
    staleTime: 3 * 60 * 1000,
  });
  const report = data?.data;

  return (
    <Skeleton
      isLoading={isLoading}
      isBusy={isFetching}
    >
      <KeyValueList
        variant="shaded"
        color="neutral"
        data={[
          {
            title: "Report",
            value: (
              <RoutingLink
                to={
                  report?.templated_report_name &&
                  report?.latest_version?.version
                    ? TemplatedReportsGeneratedReportDetailsRoutePath.toLinkParts(
                        {
                          pathParams: {
                            reportId: report.id,
                            templateName: report.templated_report_name,
                          },
                          queryParams: {
                            version: report?.latest_version?.version.toString(),
                            taskId: taskId,
                          },
                        }
                      )
                    : ""
                }
              >
                {report?.name}
              </RoutingLink>
            ),
          },
          {
            title: "Template",
            value: (
              <RoutingLink
                to={
                  report?.templated_report_name
                    ? TemplatedReportsTemplateDetailRoutePath.toLinkParts({
                        pathParams: {
                          templateName: report.templated_report_name,
                        },
                      })
                    : ""
                }
                disabled={!report?.templated_report_name}
              >
                {report?.templated_report?.display_name}
              </RoutingLink>
            ),
          },
          ...(report?.templated_report?.category
            ? [
                {
                  title: "Category",
                  value: (
                    <RoutingLink
                      to={TemplatedReportsCategoriesDetailRoutePath.toLinkParts(
                        {
                          pathParams: {
                            categoryId: report?.templated_report?.category.id,
                          },
                        }
                      )}
                      disabled={!report?.templated_report?.category?.id}
                    >
                      {report?.templated_report?.category?.name ?? "-"}
                    </RoutingLink>
                  ),
                },
              ]
            : []),
          {
            title: "Inputs",
            value: (
              <div className={cx("tagList")}>
                {report?.input
                  ? Object.keys(report?.input).map((key) => (
                      <Tag
                        key={key}
                        label={startCase(key)}
                        value={report.input[key]}
                      />
                    ))
                  : "-"}
              </div>
            ),
          },
        ]}
      />
    </Skeleton>
  );
};

export const WorkflowTaskAttachment = ({
  taskId,
  attachment,
}: {
  taskId: string;
  attachment: WorkflowUserTaskAttachment;
}) => {
  if (attachment.type === WorkflowUserTaskAttachmentType.FORM_SUBMISSION) {
    // TODO implement
    return null;
  }
  if (attachment.type === WorkflowUserTaskAttachmentType.RECORD) {
    // TODO implement
    return null;
  }
  if (attachment.type === WorkflowUserTaskAttachmentType.REPORT) {
    return (
      <ReportAttachment
        taskId={taskId}
        attachment={attachment}
      />
    );
  }

  return null;
};
