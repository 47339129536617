import { dateFormatter } from "@validereinc/utilities";
import { useGetOneUser } from "./adapters/useUsers";

type ActionType =
  | "create"
  | "edit"
  | "update"
  | "run"
  | "submit"
  | "due"
  | "complete";

type Props = {
  type: ActionType;
  date: string | null | undefined;
  byUserId: string | null | undefined;
};

const ACTION_LABELS: Record<ActionType, string> = {
  create: "Created",
  edit: "Last edited",
  update: "Last updated",
  run: "Last ran",
  submit: "Submitted",
  due: "Due",
  complete: "Completed",
};

export function useActionDetails({ type, date, byUserId }: Props) {
  const { data: user, isLoading } = useGetOneUser({ id: byUserId });

  const userName = user?.name;
  const actionLabel = ACTION_LABELS[type];

  const formattedDate = !date
    ? undefined
    : dateFormatter(new Date(date), false);
  const description = !date
    ? undefined
    : userName
      ? `${actionLabel} ${formattedDate} by ${userName}`
      : `${actionLabel} ${formattedDate}`;
  return {
    isLoading,
    formattedDate,
    userName,
    description,
  };
}
