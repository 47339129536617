import FileService from "#src/components/Services/FileService";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  colours,
  DataDisplayWithAdornment,
  FilePreviewPopover,
  Icon,
  useToast,
  type DataDisplayProps,
} from "@validereinc/common-components";
import {
  downloadFile,
  getBrowserRenderableFileType,
  getFileExtensionFromFilename,
  getMimeTypeFromExtension,
  getMimeTypeFromFileName,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { validate } from "uuid";
import styles from "./FileDataDisplay.module.css";

const cx = classNames.bind(styles);
const defaultCacheTimeSeconds = 10 * 60;

export type FileDataDisplayProps = Omit<DataDisplayProps, "value"> & {
  fileName?: string;
  fileId?: string;
  prefetch?: boolean;
  /** hide the icon prefixed in the download link ?*/
  hideIcon?: boolean;
};

/**
 * Used to display file data.
 *
 * Pre-fetches the file metadata and the file contents when it renders and
 * caches the file metadata for the duration specified by the pre-signed S3 URL or
 * a default of 10 minutes. The file contents however for the specific pre-signed URL are cached
 * forever until the component is unmounted, at which point the file will be
 * garbarge collected within 5 minutes if it isn't rendered again.
 */
export const FileDataDisplay = ({
  fileName,
  fileId,
  prefetch = false,
  hideIcon = false,
  alignment,
  isInline,
  isBusy,
  isLoading,
  emptyText = "-",
  showInvalidIndicator = true,
  invalidText = "Unsupported data type",
  className,
  style,
}: FileDataDisplayProps) => {
  const [shouldFetchDetails, setShouldFetchDetails] = useState(prefetch);
  const [filePreviewUrl, setFilePreviewUrl] = useState<string>("");
  const { toast } = useToast();
  const {
    data: fileMeta,
    error: fileMetaError,
    isInitialLoading: isFileMetaInitialLoading,
  } = useQuery({
    queryKey: ["files", "meta", fileId],
    queryFn: async () => {
      if (!fileId) return;

      // IMPROVE: move the FileService to the domain package and define new types there
      const data = await FileService.getFileDownloadUrl(fileId);

      if (!data) {
        return;
      }

      const {
        data: {
          data: [fileData],
        },
      } = data;

      if (!fileData) {
        throw new Error("Could not extract file data");
      }

      if (fileData.file_scan_status !== "safe") {
        throw new Error("The file download link is not available.");
      }

      // IMPROVE: assertion is a stop-gap until we get time to move the FileService to the domain package and define types there
      const { uri: downloadUrl } = fileData as {
        file_id: string;
        file_scan_status: "safe" | "unsafe";
        uri: string;
      };

      if (!downloadUrl) {
        throw new Error("The file download URL does not exist.");
      }

      // IMPROVE: assertion is a stop-gap until we get time to move the FileService to the domain package and define types there
      return fileData as {
        file_id: string;
        file_scan_status: "safe" | "unsafe";
        uri: string;
      };
    },
    refetchInterval: (data) => {
      if (!data) {
        return false;
      }

      const params = new URLSearchParams(new URL(data.uri).search);

      /** 
        NOTE: the download URL expires and must be refreshed based on the
        window provided in AWS recognized query param in the pre-signed URL
        @see
        {@link https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html}
        the default if this header is not provided is 10mins. (600 seconds)
        */
      return params.has("X-Amz-Expires")
        ? (Number(params.get("X-Amz-Expires")) || defaultCacheTimeSeconds) *
            1000
        : defaultCacheTimeSeconds * 1000;
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!fileId,
  });
  const {
    data: fileBlob,
    error: fileBlobError,
    isInitialLoading: isDataInitialLoading,
  } = useQuery({
    queryKey: ["files", fileMeta?.file_id, fileMeta?.uri],
    queryFn: () => {
      if (!fileMeta?.uri) {
        return;
      }

      return fetch(fileMeta.uri).then((r) => r.blob());
    },
    enabled: Boolean(fileMeta?.uri && shouldFetchDetails),
    staleTime: Infinity,
  });
  const downloadFileMutation = useMutation({
    mutationFn: ({
      fileName,
      fileBlob,
    }: {
      fileName: string;
      fileBlob?: Blob;
    }) => {
      downloadFile(fileName, fileBlob);
    },
    onError: (err) => {
      toast.push({
        intent: "error",
        description: `${fileName} failed to download.`,
      });
      console.error(err);
    },
  });

  useEffect(() => {
    if (!fileBlob) {
      return;
    }

    const uri = URL.createObjectURL(fileBlob);

    setFilePreviewUrl(uri);

    return () => URL.revokeObjectURL(uri);
  }, [fileBlob]);

  const handleDownload = () => {
    if (!fileName || !fileId) return;

    if (fileMetaError || !fileMeta?.uri) {
      toast.push({
        intent: "error",
        description: `Can't download ${fileName}. Unable to fetch the file download link.`,
      });
      ExceptionUtils.reportException(fileMetaError, "warning", {
        hint: `File could not be downloaded from a file download link. (name: ${fileName}, ref: ${fileId})`,
      });
      return;
    }

    if (shouldFetchDetails && error) {
      toast.push({
        intent: "error",
        description: `Can't download ${fileName}. Unable to fetch the file.`,
      });
      ExceptionUtils.reportException(fileBlobError, "warning", {
        hint: `File could not be downloaded from a file download link. (name: ${fileName}, ref: ${fileId})`,
      });
      return;
    }

    if (shouldFetchDetails) {
      downloadFileMutation.mutate({ fileName, fileBlob });
    } else {
      fetch(fileMeta.uri)
        .then((r) => r.blob())
        .then((blob) =>
          downloadFileMutation.mutate({
            fileName,
            fileBlob: blob,
          })
        )
        .catch((err) => {
          toast.push({
            intent: "error",
            description: `Can't download ${fileName}. Unable to fetch the file.`,
          });
          ExceptionUtils.reportException(err, "warning", {
            hint: `File could not be downloaded from a file download link. (name: ${fileName}, ref: ${fileId})`,
          });
        });
    }
  };

  const error = fileMetaError || fileBlobError;
  const isInvalid = typeof fileName !== "string" && validate(fileId);
  const isEmpty = !fileName || !fileId;
  const isAnyLoading =
    isLoading || isFileMetaInitialLoading || isDataInitialLoading;
  const fileExtension = fileName
    ? getFileExtensionFromFilename(fileName)
    : null;
  const fileTypeRenderable = getBrowserRenderableFileType(fileExtension);
  const mimeType =
    getMimeTypeFromFileName(fileName ?? "") ??
    getMimeTypeFromExtension(fileExtension);

  return (
    <DataDisplayWithAdornment
      showAdornment={showInvalidIndicator && !isEmpty && isInvalid}
      alignment={alignment}
      isInline={isInline}
      isBusy={isBusy}
      isLoading={isLoading}
      adornmentSlot={() =>
        !isEmpty && isInvalid ? (
          <Icon
            variant="warning"
            colour={colours.colours.status.warning}
            size={12}
            alt={invalidText}
            aria-roledescription="Warning Icon"
          />
        ) : null
      }
      className={className}
      style={style}
    >
      {fileName && fileId ? (
        <FilePreviewPopover
          fileName={fileName}
          fileType={fileTypeRenderable}
          fileMimeType={mimeType}
          fileExtension={fileExtension}
          fileObjectUrl={filePreviewUrl}
          onDownload={handleDownload}
          isLoading={isAnyLoading}
          rootProps={{
            onOpenChange: (newState) => {
              if (newState) setShouldFetchDetails(true);
            },
          }}
        >
          <Button
            icon={hideIcon ? "" : "paperclip"}
            variant={error || isInvalid ? "text-error" : "text"}
            size="xx-small"
            style={{ marginLeft: 4 }}
            className={cx("preview-trigger")}
          >
            {fileName ?? emptyText}
          </Button>
        </FilePreviewPopover>
      ) : (
        emptyText
      )}
    </DataDisplayWithAdornment>
  );
};
