import {
  StatusVariants,
  SummaryInformation,
  SummaryInformationContainer,
} from "#common/SummaryInformation";
import { getStatusType } from "#common/Table/rendererHelper";
import {
  getBreadcrumbsObject,
  LegacyBreadcrumbType,
} from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToFormSubmissionList } from "#routers/links";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { FileDataDisplay } from "#src/components/DataDisplay/FileDataDisplay";
import { GeocoordinateDataDisplay } from "#src/components/DataDisplay/GeocoordinateDataDisplay";
import FormService from "#src/components/Services/FormService";
import { useGetOneUser } from "#src/components/hooks/adapters/useUsers";
import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import { linkToAssetDetailPage } from "#utils/links";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ArrayDataDisplay,
  BooleanDataDisplay,
  Button,
  CalculatedFieldDataDisplay,
  Column,
  DataTable,
  DateDataDisplay,
  DateRangeDataDisplay,
  Dialog,
  DropdownMenu,
  KeyValuePanel,
  Link,
  MathDataDisplay,
  NumberDataDisplay,
  Page,
  Panel,
  Pill,
  Row,
  TextDataDisplay,
  useToast,
} from "@validereinc/common-components";
import {
  BaseError,
  DateTimeRangeSchema,
  FileUploadedSchema,
  FormCategoryAdapter,
  FormSubmissionMeasurementQuestionAnswerSchema,
  FormSubmissionStatus,
  FormSubmissionType,
  LookupWithDetailsSchema,
  Resources,
  TemplatedConfigurationRunAdapter,
  type FormSchemaQuestionType,
  type FormSchemaType,
  type FormSubmissionAnswersBySectionMapType,
  type FormSubmissionAnswerType,
  type MeasurementUnitType,
} from "@validereinc/domain";
import { Forms } from "@validereinc/domain-controllers";
import { DateFormats, downloadXLSX } from "@validereinc/utilities";
import classNames from "classnames/bind";
import parseISO from "date-fns/parseISO";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteOneFormSubmission,
  useGetOneFormSubmission,
  useUpdateOneFormSubmission,
} from "../hooks/adapters/useFormSubmissions";
import styles from "./FormSubmissionDetail.module.css";
import UpdateStatusModal from "./UpdateStatusModal";
import { useExportFormSubmissionAsPDF } from "./exportFormSubmission";

const { FormCalculatedFieldService, getQuestionAnswerPairsBySection } =
  Forms.logic;
const { CalculatedFieldEquationController } = Forms.view;
const cx = classNames.bind(styles);

// IMPROVE: this component needs a big refactor/cleanup
const FormSubmissionDetail = ({
  breadcrumbs,
}: {
  breadcrumbs: LegacyBreadcrumbType[];
}) => {
  const params = useParams<{ formSubmissionId: string }>();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const [canEditFormSubmissions] = useHasPermission("form_submissions:edit");
  const navigate = useNavigate();
  const { toast } = useToast();
  const { measurementUnits, getPrecisionByType } = useMeasurementTypes();
  const formSubmissionQuery = useGetOneFormSubmission({
    id: params.formSubmissionId,
  });

  const detailsData = formSubmissionQuery.data?.data;

  const submittedByUserQuery = useGetOneUser({
    id: detailsData?.created_by,
  });

  const exportXLSXMutation = useMutation({
    mutationFn: async (formSubmissionId: string) => {
      const respData =
        await FormService.getFormSubmissionXLSXExport(formSubmissionId);

      if (!respData) {
        throw new BaseError("Failed to fetch XLSX export of form submission");
      }

      const fileBlob = respData.data;

      downloadXLSX(
        `${detailsData?.form_schema?.name} - ${detailsData?.id?.slice(0, 7)}`,
        fileBlob
      );
    },
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: `Successfully exported form submission`,
      });
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to export form submission.",
      });
    },
  });
  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: false,
    metaUserDataMap: {
      [detailsData?.created_by ?? ""]: submittedByUserQuery.data,
    },
  });
  const formCategoryQuery = useQuery({
    queryKey: [
      "forms",
      "categories",
      detailsData?.form_schema?.form_category_id,
    ],
    queryFn: () =>
      FormCategoryAdapter.getOne({
        id: detailsData?.form_schema?.form_category_id ?? "",
      }),
    enabled: !!detailsData?.form_schema?.form_category_id,
  });
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const isLoading =
    formSubmissionQuery.isLoading || submittedByUserQuery.isLoading;

  const title = isLoading
    ? "Form Submission Detail"
    : `${detailsData?.form_schema?.name} - ${detailsData?.id?.slice(0, 7)}`;

  const updateMutation = useUpdateOneFormSubmission({
    successMessage: (data: { data: FormSubmissionType }) =>
      `${title} status changed to ${startCase(data.data.status.toLowerCase())}`,
    errorMessage: `Failed to update status of ${title}.`,
  });

  const breadcrumbsWithName = useMemo(() => {
    const breadcrumbsObject = getBreadcrumbsObject(breadcrumbs, params);
    breadcrumbsObject[breadcrumbsObject.length - 1].title = title;
    return breadcrumbsObject;
  }, [breadcrumbs, detailsData]);

  const deleteMutation = useDeleteOneFormSubmission({
    successMessage: `${title} successfully deleted.`,
    errorMessage: `Failed to delete ${title}.`,
    onSuccess: () => {
      navigate({ pathname: linkToFormSubmissionList() });
    },
  });

  const formatQuestionAnswer = (
    question: FormSchemaQuestionType,
    sectionId: string,
    sectionIdx: number,
    answer: FormSubmissionAnswerType,
    {
      allQuestions,
      allAnswers,
      measurementUnits,
    }: {
      allQuestions: FormSchemaType["config"]["questions"];
      allAnswers: FormSubmissionAnswersBySectionMapType;
      measurementUnits: MeasurementUnitType[];
    }
  ) => {
    if (answer?.value === undefined || answer?.value === null) return null;

    switch (question.type) {
      case "measurement": {
        const parsedAnswer =
          FormSubmissionMeasurementQuestionAnswerSchema.parse(answer);

        const displayMeasurementUnitId =
          FormCalculatedFieldService.getMeasurementUnitFromQuestion(
            question,
            false
          );
        const displayMeasurementUnit = measurementUnits.find(
          ({ id }) => displayMeasurementUnitId === id
        );
        const displayUnit =
          displayMeasurementUnit?.name?.symbol ?? displayMeasurementUnitId;

        if (question?.equation) {
          const equationVariablesAndValues =
            FormCalculatedFieldService.getEquationVariablesAndValuesFromSubmission(
              question.equation,
              sectionId,
              sectionIdx,
              allAnswers,
              allQuestions
            );

          // If the answer is derived from another equation with a time unit, the BE calculates and stores it in minutes.
          // Therefore, we must convert the unit.
          const convertedAnswer =
            FormCalculatedFieldService.convertAnswerIfNecessary(
              equationVariablesAndValues,
              answer.value as number,
              question
            );

          return (
            <CalculatedFieldDataDisplay
              value={convertedAnswer ?? answer.value}
              displaySlot={(props) => (
                <NumberDataDisplay
                  {...props}
                  unit={displayUnit}
                />
              )}
              alignment="right"
              showHint
              isValid
              hintSlot={
                <>
                  This was calculated using the equation:{" "}
                  <CalculatedFieldEquationController
                    equation={question.equation}
                    questionsMap={allQuestions}
                    sourceFieldValuesMap={equationVariablesAndValues}
                    getTokens
                  >
                    {({ tokens }) => (
                      <MathDataDisplay.Root>
                        <MathDataDisplay
                          colorScheme="light"
                          style={{ whiteSpace: "nowrap", fontSize: 14 }}
                        >
                          {tokens.map((t, idx) => {
                            if (t.startGroup || t.endGroup) {
                              return <span key={idx}>{t.displayValue}</span>;
                            } else if (t.isVariable) {
                              const displayMeasurementUnit =
                                measurementUnits.find(
                                  ({ id }) => t.unit === id
                                );
                              const unit =
                                displayMeasurementUnit?.name?.symbol ?? t.unit;

                              return (
                                <MathDataDisplay.InteractiveGroup
                                  key={idx}
                                  value={t.value}
                                  displayValue={
                                    t.value !== undefined &&
                                    t.value !== null &&
                                    String(t.value) !== ""
                                      ? t.value.toString()
                                      : null
                                  }
                                  description={`This is the answer from the question of the same name.${unit ? ` In units: "${unit}"` : ""}`}
                                >
                                  &quot;{t.displayValue}&quot;
                                </MathDataDisplay.InteractiveGroup>
                              );
                            } else {
                              return (
                                <span
                                  key={idx}
                                  dangerouslySetInnerHTML={{
                                    __html: t.displayValue,
                                  }}
                                ></span>
                              );
                            }
                          })}
                        </MathDataDisplay>
                      </MathDataDisplay.Root>
                    )}
                  </CalculatedFieldEquationController>
                </>
              }
            />
          );
        }

        const precision = getPrecisionByType(question.measurement_type);
        const displayedNumber = (
          <NumberDataDisplay
            value={parsedAnswer.value}
            unit={displayUnit}
            formattingOpts={{
              ...(precision !== null
                ? {
                    fractionDigits: precision,
                  }
                : {}),
            }}
            alignment="right"
          />
        );

        if (parsedAnswer.subject_type && parsedAnswer.subject_id) {
          return (
            <RoutingLink
              style={{ display: "block", textAlign: "right" }}
              to={{
                pathname: linkToAssetDetailPage(
                  parsedAnswer.subject_type,
                  parsedAnswer.subject_id
                ),
                query: {
                  tab: "measurements",
                },
              }}
            >
              {displayedNumber}
            </RoutingLink>
          );
        }

        return displayedNumber;
      }
      case "question": {
        switch (question.data_type) {
          case "geo_point":
            return (
              <GeocoordinateDataDisplay
                coordinates={answer.value}
                alignment="right"
              />
            );
          case "file": {
            const verifiedAnswer = FileUploadedSchema.safeParse(answer.value);

            if (verifiedAnswer.error) {
              return "-";
            }

            return (
              <FileDataDisplay
                fileName={verifiedAnswer.data.name}
                fileId={verifiedAnswer.data.ref}
                prefetch={false}
                alignment="right"
              />
            );
          }
          case "lookup": {
            const verifiedAnswer = LookupWithDetailsSchema.safeParse(answer);

            if (verifiedAnswer.error) {
              return answer?.name ?? "-";
            }

            if (verifiedAnswer.data.entity_type === "form_submission") {
              return verifiedAnswer.data.name;
            }

            return (
              <RoutingLink
                to={linkToAssetDetailPage(
                  verifiedAnswer.data.entity_type,
                  verifiedAnswer.data.value
                )}
                style={{
                  textAlign: "right",
                }}
              >
                {verifiedAnswer.data.name}
              </RoutingLink>
            );
          }
          case "multi-pick-list": {
            return (
              <ArrayDataDisplay
                value={answer.value}
                alignment="right"
              />
            );
          }
          case "pick-list": {
            return (
              <TextDataDisplay
                value={answer.value}
                alignment="right"
              />
            );
          }
          case "date": {
            return (
              <DataTable.DataRow.DateCell
                value={answer.value}
                withTime={false}
                alignment="right"
              />
            );
          }
          case "date-time": {
            return (
              <DataTable.DataRow.DateCell
                value={answer.value}
                withTime
                alignment="right"
              />
            );
          }
          case "date-time-range": {
            const verifiedAnswer = DateTimeRangeSchema.safeParse(answer.value);

            if (verifiedAnswer.error) {
              return "-";
            }

            return (
              <DateRangeDataDisplay
                displayFormat={DateFormats.DATE_TIME}
                value={{
                  from: parseISO(verifiedAnswer.data[0]),
                  to: parseISO(verifiedAnswer.data[1]),
                }}
                alignment="right"
              />
            );
          }
          case "boolean": {
            return (
              <BooleanDataDisplay
                value={answer.value}
                alignment="right"
              />
            );
          }
          case "number": {
            return (
              <DataTable.DataRow.NumberCell
                value={answer.value}
                formattingOpts={{
                  ...(Number(answer.value) > 10 ** 6
                    ? { fractionDigits: 6 }
                    : { overrideOpts: { maximumFractionDigits: 3 } }),
                }}
                alignment="right"
              />
            );
          }
          case "integer": {
            return (
              <DataTable.DataRow.NumberCell
                value={answer.value}
                formattingOpts={{
                  ...(Number(answer.value) > 10 ** 6
                    ? { fractionDigits: 6 }
                    : { overrideOpts: { maximumFractionDigits: 3 } }),
                }}
                alignment="right"
              />
            );
          }
          case "string": {
            if (typeof answer.value !== "string") return "-";

            return (
              <DataTable.DataRow.TextCell
                value={answer.value}
                alignment="right"
              />
            );
          }
          default:
            return (
              <DataTable.DataRow.TextCell
                value="n/a"
                alignment="right"
              />
            );
        }
      }
    }
  };

  const sharedProps = {
    type: "vertical",
    isLoading,
    allowWrap: true,
  };
  const statusVariant = useMemo(() => {
    const { type } = getStatusType(detailsData?.status ?? "", "form");

    // map a status type to the appr. summary information container prop
    return (
      {
        success: StatusVariants.ACTIVE,
        info: StatusVariants.INFO,
        warning: StatusVariants.WARNING,
        error: StatusVariants.INACTIVE,
        default: StatusVariants.DEFAULT,
      }[type] ?? StatusVariants.DEFAULT
    );
  }, [detailsData?.status]);

  const data = useMemo(
    () =>
      getQuestionAnswerPairsBySection(detailsData, detailsData?.form_schema, {
        formatQuestionAnswer,
        measurementUnits,
      }),
    [detailsData]
  );

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      params.formSubmissionId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: params.formSubmissionId,
      }),
    enabled: isTemplatedConfigsAvailable && !!params.formSubmissionId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const actionRow = [
    <Button
      key="delete-form"
      variant="error-outline"
      onClick={() => setIsDeleteModalOpen(true)}
      disabled={isLoading}
    >
      Delete
    </Button>,
    ...(detailsData?.status !== FormSubmissionStatus.DRAFT &&
    detailsData?.form_schema?.form_submission_editable &&
    canEditFormSubmissions
      ? [
          <Button
            key="edit-form"
            icon="pencil-simple"
            onClick={() =>
              navigate({
                pathname: linkToUpdateFormSubmission(
                  detailsData?.form_schema?.form_category_id,
                  detailsData?.form_schema?.id,
                  detailsData?.id
                ),
              })
            }
            disabled={isLoading}
          >
            Edit
          </Button>,
        ]
      : []),
    <DropdownMenu
      key="export-action"
      options={[
        {
          label: "Export as XLSX",
          isDisabled: exportXLSXMutation.isLoading,
          onClick: () => {
            exportXLSXMutation.mutate(params?.formSubmissionId);
          },
        },
        {
          label: "Export as PDF",
          isDisabled: !detailsData || exportPDFMutation.isLoading,
          onClick: () => {
            if (!detailsData) return;

            exportPDFMutation.mutate(detailsData);
          },
        },
      ]}
    >
      <Button
        icon="caret-down"
        iconPosition="right"
        isLoading={exportPDFMutation.isLoading || exportXLSXMutation.isLoading}
      >
        Export
      </Button>
    </DropdownMenu>,
    <Button
      key="update-status"
      variant="primary"
      onClick={() => setIsStatusModalOpen(true)}
    >
      Update Status
    </Button>,
  ];

  return (
    <>
      <Page
        title={
          <div className={cx("page-title")}>
            {title}
            {detailsData?.version ? (
              <Pill
                variant="default"
                isCapitalized={false}
              >{`v${detailsData.version}`}</Pill>
            ) : null}
          </div>
        }
        category="Form Submission"
        breadcrumbs={breadcrumbsWithName}
        actionRow={actionRow}
      >
        <Row>
          <Column variant={6}>
            <Panel
              isFluidY={false}
              loaded={!isLoading}
            >
              <SummaryInformationContainer
                variant="vertical"
                status={detailsData?.status}
                statusVariant={statusVariant}
              >
                <SummaryInformation
                  {...sharedProps}
                  title="Form Template"
                  value={
                    detailsData?.form_schema ? (
                      <Link
                        onClick={() => {
                          navigate({
                            pathname: linkToFormTemplateDetail(
                              detailsData?.form_schema?.form_category_id,
                              detailsData?.form_schema?.id
                            ),
                          });
                        }}
                        label={detailsData?.form_schema?.name}
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                <SummaryInformation
                  {...sharedProps}
                  isLoading={formCategoryQuery.isLoading}
                  title="Form Category"
                  value={
                    formCategoryQuery.data ? (
                      <Link
                        onClick={() => {
                          navigate({
                            pathname: linkToFormCategoryDetails(
                              formCategoryQuery.data.id
                            ),
                          });
                        }}
                        label={formCategoryQuery.data.name}
                      />
                    ) : (
                      "-"
                    )
                  }
                />
                {templatedConfigurationRunDependencyGraph ? (
                  <SummaryInformation
                    {...sharedProps}
                    title="Configuration Template"
                    value={
                      <Link
                        underline="none"
                        onClick={() =>
                          setIsTemplatedConfigurationRunDrawerOpen(true)
                        }
                        label={templatedConfigurationRunDependencyGraph.name}
                      />
                    }
                  />
                ) : null}
                <SummaryInformation
                  {...sharedProps}
                  title="Submitted At"
                  value={
                    <DateDataDisplay
                      value={detailsData?.created_at}
                      displayFormat={DateFormats.DATE_TIME}
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    />
                  }
                />
                <SummaryInformation
                  {...sharedProps}
                  title="Submitted By"
                  value={submittedByUserQuery.data?.name ?? "-"}
                />
              </SummaryInformationContainer>
            </Panel>
          </Column>
          <Column variant={18}>
            {data?.map((panel, index) => (
              <KeyValuePanel
                panelMaxColumnCount={2}
                key={`${panel?.name}-${index}`}
                data={panel.data}
                panelProps={{ title: panel.section?.name, isFluidY: false }}
              />
            )) ?? null}
          </Column>
        </Row>
      </Page>
      <UpdateStatusModal
        open={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        onUpdate={() => {}}
        doUpdate={async (updateData) => {
          // update the form submission with the updated data provided by the modal
          await updateMutation.mutateAsync({
            id: params.formSubmissionId,
            data: updateData,
          });
        }}
        entityTitle={title}
        currentStatus={detailsData?.status}
      />
      <Dialog
        title="Delete Form?"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actionRow={[
          <Button
            key="delete-warning"
            variant="error"
            onClick={() => {
              deleteMutation.mutate({
                id: params?.formSubmissionId,
              });
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this form submission? This action
          cannot be undone.
        </div>
      </Dialog>
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={params.formSubmissionId}
        />
      </IsFeatureAvailable>
    </>
  );
};

FormSubmissionDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default FormSubmissionDetail;
