import { EntityDropdownInput } from "#src/batteries-included-components/Dropdowns/EntityDropdownInput";
import { USER_GROUP_QUERY_KEY } from "#src/components/hooks/adapters/useUserGroups";
import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useQuery } from "@tanstack/react-query";
import {
  Form,
  Panel,
  TextAreaInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import {
  CreateUserGroupSchema,
  SortDirection,
  UserGroupCategoriesAdapter,
  UserGroupCategoryType,
  UserGroupsAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { DEFAULT_QUERY_OPTIONS } from "../../../../components/hooks/adapters/adapterUtils";
import styles from "./UserGroupFormPanel.module.scss";

const cx = classNames.bind(styles);

export const BasicInfoFormStep = () => {
  const STEP_NUMBER = 1;
  const { currentStep, updateStep } = useMultiStepFormContext();

  const { groupId } = useParams<{ groupId: string }>();

  // If in edit mode, fetch user group:
  const { data: userGroup } = useQuery({
    queryKey: [...USER_GROUP_QUERY_KEY, groupId],
    queryFn: () => UserGroupsAdapter.getOne({ id: groupId }),
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!groupId,
  });

  const form = useForm({ defaultValues: userGroup?.data ?? {} });

  const getFormState = useMemoizedFormState(form);

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues() as Record<string, string>,
      getFormState,
    });
  }, [userGroup, getFormState]);

  const urlParams = new URLSearchParams(window.location.search);

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Panel>
          <Form {...form}>
            <h4 className="sectionTitle">Details</h4>

            <div className={cx("basicInfoContainer")}>
              <EntityDropdownInput<UserGroupCategoryType>
                isMulti={false}
                isRequired
                fetchEntityList={UserGroupCategoriesAdapter.getList}
                label={"Group Category"}
                name={CreateUserGroupSchema.keyof().Enum.group_category_id}
                sort={{
                  sortBy: CreateUserGroupSchema.keyof().Enum.name,
                  sortDirection: SortDirection.ASCENDING,
                }}
                defaultValue={urlParams.get("defaultCategoryId")}
                isDisabled={!!groupId}
              />

              <TextInput
                name={CreateUserGroupSchema.keyof().Enum.name}
                label="Name"
                isRequired
                placeholder="Enter name..."
              />

              <TextAreaInput
                name={CreateUserGroupSchema.keyof().Enum.description}
                label="Description"
                placeholder="Enter a description..."
                isRequired
              />
            </div>
          </Form>
        </Panel>
      ) : null}
    </>
  );
};
