import { z } from "zod";

export const MeasurementTypes = {
  aboveground_biomass_production: "aboveground_biomass_production",
  acetaldehyde_emissions_intensity: "acetaldehyde_emissions_intensity",
  acrolein_emissions_intensity: "acrolein_emissions_intensity",
  actinomycetes_count: "actinomycetes_count",
  adjusted_interim_credit_rate: "adjusted_interim_credit_rate",
  adjustment_factor: "adjustment_factor",
  adjustment_factor_clay_silt_content: "adjustment_factor_clay_silt_content",
  adjustment_factor_mean_annual_precipitation:
    "adjustment_factor_mean_annual_precipitation",
  adjustment_factor_mean_annual_temperature:
    "adjustment_factor_mean_annual_temperature",
  adjustment_factor_soil_depth: "adjustment_factor_soil_depth",
  adjustment_factor_topographic_slope: "adjustment_factor_topographic_slope",
  air_actuated_devices_count: "air_actuated_devices_count",
  air_temperature: "air_temperature",
  alfalfa_actual_coverage: "alfalfa_actual_coverage",
  aluminum_concentration: "aluminum_concentration",
  ambient_pressure: "ambient_pressure",
  ambient_temperature: "ambient_temperature",
  amine_acid_gas_flow: "amine_acid_gas_flow",
  amine_acid_gas_inlet_pressure: "amine_acid_gas_inlet_pressure",
  amine_acid_gas_inlet_termperature: "amine_acid_gas_inlet_termperature",
  amine_gas_bypass_flow: "amine_gas_bypass_flow",
  ammonia_emissions_intensity: "ammonia_emissions_intensity",
  ammonia_emissions_intensity_mfre: "ammonia_emissions_intensity_mfre",
  amonia_concentration: "amonia_concentration",
  anaerobes_count: "anaerobes_count",
  api_gravity: "api_gravity",
  application_rate: "application_rate",
  application_rate_mass: "application_rate_mass",
  approximated_photosynthesis_rate: "approximated_photosynthesis_rate",
  area: "area",
  associated_gas_electricity_generated: "associated_gas_electricity_generated",
  atm_devices_count: "atm_devices_count",
  bare_ground_actual_coverage: "bare_ground_actual_coverage",
  bare_soil: "bare_soil",
  benzene_emissions_intensity: "benzene_emissions_intensity",
  benzene_emissions_rate: "benzene_emissions_rate",
  biological_quality_rating: "biological_quality_rating",
  biomass: "biomass",
  biomass_to_carbon_conversion_factor: "biomass_to_carbon_conversion_factor",
  blend_fraction: "blend_fraction",
  blowdown_diameter: "blowdown_diameter",
  blowdown_pressure: "blowdown_pressure",
  blowdown_temperature: "blowdown_temperature",
  blowdown_time: "blowdown_time",
  blowdown_volume: "blowdown_volume",
  boron_concentration: "boron_concentration",
  bottom_temperature: "bottom_temperature",
  bray_p1_concentration: "bray_p1_concentration",
  breathing_loss: "breathing_loss",
  breathing_loss_cond_ch4: "breathing_loss_cond_ch4",
  breathing_loss_cond_voc: "breathing_loss_cond_voc",
  bulk_ngl_received_volume: "bulk_ngl_received_volume",
  bulk_ngl_supplied_volume: "bulk_ngl_supplied_volume",
  business_travel_air_distance: "business_travel_air_distance",
  business_travel_car_distance: "business_travel_car_distance",
  butanes_emissions_rate: "butanes_emissions_rate",
  c_8_emissions_rate: "c_8_emissions_rate",
  c1_emission_factor: "c1_emission_factor",
  calcium_carbonate_percent: "calcium_carbonate_percent",
  calcium_concentration: "calcium_concentration",
  calcium_percent: "calcium_percent",
  canada_goldenrod_actual_coverage: "canada_goldenrod_actual_coverage",
  carbon_atoms_butane: "carbon_atoms_butane",
  carbon_atoms_ethane: "carbon_atoms_ethane",
  carbon_atoms_heptane: "carbon_atoms_heptane",
  carbon_atoms_hexane: "carbon_atoms_hexane",
  carbon_atoms_methane: "carbon_atoms_methane",
  carbon_atoms_pentane: "carbon_atoms_pentane",
  carbon_atoms_propane: "carbon_atoms_propane",
  carbon_content: "carbon_content",
  carbon_content_fraction: "carbon_content_fraction",
  carbon_content_less_co2: "carbon_content_less_co2",
  carbon_credits: "carbon_credits",
  carbon_energy_intensity: "carbon_energy_intensity",
  carbon_intensity_vol: "carbon_intensity_vol",
  carbon_per_sq_m_plot: "carbon_per_sq_m_plot",
  carbon_stock_co2e: "carbon_stock_co2e",
  carbon_surface_density: "carbon_surface_density",
  carbon_surface_density_30_100: "carbon_surface_density_30_100",
  cargo_transported_volume: "cargo_transported_volume",
  casing_internal_diamter: "casing_internal_diamter",
  casing_pressure: "casing_pressure",
  cast_iron_length: "cast_iron_length",
  cec_concentration: "cec_concentration",
  cems_operating_time: "cems_operating_time",
  centrifugal_compressor_emissions_present:
    "centrifugal_compressor_emissions_present",
  ch4_emissions_intensity: "ch4_emissions_intensity",
  ch4_emissions_intensity_mfre: "ch4_emissions_intensity_mfre",
  ch4_global_warming_potential: "ch4_global_warming_potential",
  ch4_mass_fraction: "ch4_mass_fraction",
  ch4_to_co2eq_conversion: "ch4_to_co2eq_conversion",
  chloride_concentration: "chloride_concentration",
  circulation_rate: "circulation_rate",
  clay_percent: "clay_percent",
  clay_silt_content: "clay_silt_content",
  clingage_factor: "clingage_factor",
  closed_loop_devices_count: "closed_loop_devices_count",
  closest_surface_development: "closest_surface_development",
  co_emission_factor_input_based: "co_emission_factor_input_based",
  co_emission_factor_output_based: "co_emission_factor_output_based",
  co_emissions_intensity: "co_emissions_intensity",
  co_emissions_intensity_mfre: "co_emissions_intensity_mfre",
  co_emissions_intensity_mfre_gas: "co_emissions_intensity_mfre_gas",
  co_emissions_rate: "co_emissions_rate",
  co_energy_intensity: "co_energy_intensity",
  co2_emission_factor: "co2_emission_factor",
  co2_emissions_intensity: "co2_emissions_intensity",
  co2_emissions_intensity_mfre: "co2_emissions_intensity_mfre",
  co2_emissions_rate: "co2_emissions_rate",
  co2_global_warming_potential: "co2_global_warming_potential",
  co2_mass_fraction: "co2_mass_fraction",
  co2_removal: "co2_removal",
  co2_respiration: "co2_respiration",
  co2_volume_fraction: "co2_volume_fraction",
  co2e_emission_factor: "co2e_emission_factor",
  combustion_air_pressure: "combustion_air_pressure",
  combustion_efficiency: "combustion_efficiency",
  combustion_time: "combustion_time",
  common_yarrow_actual_coverage: "common_yarrow_actual_coverage",
  comp_connector_count: "comp_connector_count",
  comp_connector_leak_time: "comp_connector_leak_time",
  comp_meter_count: "comp_meter_count",
  comp_meter_leak_time: "comp_meter_leak_time",
  comp_open_ended_line_count: "comp_open_ended_line_count",
  comp_open_ended_line_leak_time: "comp_open_ended_line_leak_time",
  comp_pressure_relief_valve_count: "comp_pressure_relief_valve_count",
  comp_pressure_relief_valve_leak_time: "comp_pressure_relief_valve_leak_time",
  comp_valve_count: "comp_valve_count",
  comp_valve_leak_time: "comp_valve_leak_time",
  completions_fuel_volume: "completions_fuel_volume",
  component_count: "component_count",
  confidence_interval: "confidence_interval",
  connector_component_count: "connector_component_count",
  connector_count: "connector_count",
  connector_leak_time: "connector_leak_time",
  control_factor: "control_factor",
  control_mode: "control_mode",
  controlled_benzene_emissions: "controlled_benzene_emissions",
  controlled_thc_emissions_rate: "controlled_thc_emissions_rate",
  copper_concentration: "copper_concentration",
  count: "count",
  count_rate: "count_rate",
  creditable_area: "creditable_area",
  crested_wheatgrass_actual_coverage: "crested_wheatgrass_actual_coverage",
  curled_dock_actual_coverage: "curled_dock_actual_coverage",
  curlycup_gumweed_actual_coverage: "curlycup_gumweed_actual_coverage",
  currency_cad: "currency_cad",
  currency_usd: "currency_usd",
  custom_ia_ef: "custom_ia_ef",
  custom_il_ef: "custom_il_ef",
  custom_in_ef: "custom_in_ef",
  custom_mi_ef: "custom_mi_ef",
  custom_mn_ef: "custom_mn_ef",
  custom_mrow_ef: "custom_mrow_ef",
  custom_nd_ef: "custom_nd_ef",
  custom_oh_ef: "custom_oh_ef",
  custom_or_ef: "custom_or_ef",
  custom_us_ef: "custom_us_ef",
  custom_va_ef: "custom_va_ef",
  cyclohexane_emissions_rate: "cyclohexane_emissions_rate",
  cyclopentane_emissions_rate: "cyclopentane_emissions_rate",
  cylindrical_section_length: "cylindrical_section_length",
  decanes_emissions_rate: "decanes_emissions_rate",
  deck_seam_length_factor: "deck_seam_length_factor",
  deck_seam_loss_factor: "deck_seam_loss_factor",
  dense_spikemoss_actual_coverage: "dense_spikemoss_actual_coverage",
  density: "density",
  depth: "depth",
  depth_from_packer: "depth_from_packer",
  depth_to_bumper: "depth_to_bumper",
  deviation_duration: "deviation_duration",
  diameter: "diameter",
  diesel_fuel_volume: "diesel_fuel_volume",
  dimensionful_coefficient: "dimensionful_coefficient",
  dimensionless_coefficient: "dimensionless_coefficient",
  discharge_pressure: "discharge_pressure",
  disp_acgas_volume: "disp_acgas_volume",
  disp_c_1_mx_volume: "disp_c_1_mx_volume",
  disp_c_2_mx_volume: "disp_c_2_mx_volume",
  disp_c_2_sp_volume: "disp_c_2_sp_volume",
  disp_c_3_mx_volume: "disp_c_3_mx_volume",
  disp_c_3_sp_volume: "disp_c_3_sp_volume",
  disp_c_4_mx_volume: "disp_c_4_mx_volume",
  disp_c_4_sp_volume: "disp_c_4_sp_volume",
  disp_c_5_mx_volume: "disp_c_5_mx_volume",
  disp_c_5_sp_volume: "disp_c_5_sp_volume",
  disp_c_6_mx_volume: "disp_c_6_mx_volume",
  disp_c_6_sp_volume: "disp_c_6_sp_volume",
  disp_cond_volume: "disp_cond_volume",
  disp_gas_volume: "disp_gas_volume",
  disp_ic_4_mx_volume: "disp_ic_4_mx_volume",
  disp_ic_4_sp_volume: "disp_ic_4_sp_volume",
  disp_ic_5_mx_volume: "disp_ic_5_mx_volume",
  disp_ic_5_sp_volume: "disp_ic_5_sp_volume",
  disp_litemx_volume: "disp_litemx_volume",
  disp_nc_4_mx_volume: "disp_nc_4_mx_volume",
  disp_nc_4_sp_volume: "disp_nc_4_sp_volume",
  disp_nc_5_mx_volume: "disp_nc_5_mx_volume",
  disp_nc_5_sp_volume: "disp_nc_5_sp_volume",
  disp_oil_volume: "disp_oil_volume",
  disposition_volume: "disposition_volume",
  downstream_volume_adjustable: "downstream_volume_adjustable",
  downstream_volume_adjusted: "downstream_volume_adjusted",
  downstream_volume_non_adjustable: "downstream_volume_non_adjustable",
  downtime_hours: "downtime_hours",
  drilled_depth: "drilled_depth",
  drilling_fuel_volume: "drilling_fuel_volume",
  dry_bulk_density: "dry_bulk_density",
  dry_bulk_density_30_100: "dry_bulk_density_30_100",
  dry_gas_production_volume: "dry_gas_production_volume",
  dynamic_viscosity: "dynamic_viscosity",
  efficiency: "efficiency",
  electric_driven_devices_count: "electric_driven_devices_count",
  electricity_consumption: "electricity_consumption",
  electricity_generated: "electricity_generated",
  electricity_output_ratio: "electricity_output_ratio",
  emission_height: "emission_height",
  emission_rate: "emission_rate",
  employee_commute_distance: "employee_commute_distance",
  enclosed_combuster_devices_count: "enclosed_combuster_devices_count",
  energy: "energy",
  energy_co2: "energy_co2",
  energy_content: "energy_content",
  energy_conversion: "energy_conversion",
  energy_density: "energy_density",
  energy_emission_factor: "energy_emission_factor",
  energy_output_biomass: "energy_output_biomass",
  energy_output_fossil_fuel: "energy_output_fossil_fuel",
  energy_per_area: "energy_per_area",
  energy_sox: "energy_sox",
  engine_operating_speed: "engine_operating_speed",
  engine_output_rate: "engine_output_rate",
  engine_rated_speed: "engine_rated_speed",
  enthalpy: "enthalpy",
  equity_share: "equity_share",
  esd_devices_count: "esd_devices_count",
  ethane_emissions_rate: "ethane_emissions_rate",
  ethane_volume: "ethane_volume",
  ethylbenzene_emissions_rate: "ethylbenzene_emissions_rate",
  event_duration: "event_duration",
  excluded_area: "excluded_area",
  expected_change_precipitation: "expected_change_precipitation",
  expected_change_temperature: "expected_change_temperature",
  false_chamomile_actual_coverage: "false_chamomile_actual_coverage",
  final_pressure: "final_pressure",
  final_strata_count: "final_strata_count",
  final_temperature: "final_temperature",
  flange_component_count: "flange_component_count",
  flange_count: "flange_count",
  flange_leak_time: "flange_leak_time",
  flare_time: "flare_time",
  flaring_efficiency: "flaring_efficiency",
  flash_loss_cond_ch4: "flash_loss_cond_ch4",
  flash_loss_cond_voc: "flash_loss_cond_voc",
  flashing_loss: "flashing_loss",
  flow_rate: "flow_rate",
  flowback_time: "flowback_time",
  formaldehyde_emissions_intensity: "formaldehyde_emissions_intensity",
  formaldehyde_energy_intensity: "formaldehyde_energy_intensity",
  foxtail_barley_actual_coverage: "foxtail_barley_actual_coverage",
  frac_c_2_sp_volume: "frac_c_2_sp_volume",
  frac_c_3_sp_volume: "frac_c_3_sp_volume",
  frac_c_4_sp_volume: "frac_c_4_sp_volume",
  frac_c_5_sp_volume: "frac_c_5_sp_volume",
  frac_c_6_sp_volume: "frac_c_6_sp_volume",
  fraction: "fraction",
  fraction_gas_in_vessel: "fraction_gas_in_vessel",
  fraction_petroleum: "fraction_petroleum",
  fringed_sage_actual_coverage: "fringed_sage_actual_coverage",
  fuel_consumption_efficiency: "fuel_consumption_efficiency",
  fuel_consumption_mass: "fuel_consumption_mass",
  fuel_consumption_rate_vol: "fuel_consumption_rate_vol",
  fuel_consumption_volume: "fuel_consumption_volume",
  fuel_energy_density: "fuel_energy_density",
  fuel_gas_flow: "fuel_gas_flow",
  fuel_mass: "fuel_mass",
  fugitive_volume: "fugitive_volume",
  gas_explosive_limit: "gas_explosive_limit",
  gas_fraction: "gas_fraction",
  gas_fuel_volume: "gas_fuel_volume",
  gas_mass_concentration: "gas_mass_concentration",
  gas_molar_concentration: "gas_molar_concentration",
  gas_oil_ratio: "gas_oil_ratio",
  gas_received_volume: "gas_received_volume",
  gas_to_liquid_ratio: "gas_to_liquid_ratio",
  gas_vented_flared_volume: "gas_vented_flared_volume",
  gauge_pressure: "gauge_pressure",
  general_bacteria_count: "general_bacteria_count",
  general_fungi_count: "general_fungi_count",
  ghg_mass_fraction: "ghg_mass_fraction",
  ghg_molar_concentration: "ghg_molar_concentration",
  ghg_mole_fraction: "ghg_mole_fraction",
  gis_factor: "gis_factor",
  global_warming_potential: "global_warming_potential",
  gram_positives_count: "gram_positives_count",
  green_needle_grass_actual_coverage: "green_needle_grass_actual_coverage",
  gross_electricity_generated: "gross_electricity_generated",
  gross_primary_productivity: "gross_primary_productivity",
  gwp_ghg_species: "gwp_ghg_species",
  h2co_emission_intensity_mfre_gas: "h2co_emission_intensity_mfre_gas",
  heating_value: "heating_value",
  height: "height",
  heptanes_emissions_rate: "heptanes_emissions_rate",
  herd_size: "herd_size",
  hexane_emissions_intensity: "hexane_emissions_intensity",
  hhv_biomass: "hhv_biomass",
  hhv_fossil_fuel: "hhv_fossil_fuel",
  high_bleed_rate: "high_bleed_rate",
  higher_heating_value: "higher_heating_value",
  hole_size_area: "hole_size_area",
  hydrocarbon_concentration: "hydrocarbon_concentration",
  hydrogen_benchmark: "hydrogen_benchmark",
  hydrogen_emissions_rate: "hydrogen_emissions_rate",
  hydrogen_percent: "hydrogen_percent",
  i_butane_volume: "i_butane_volume",
  ideal_gas_constant: "ideal_gas_constant",
  ignition_angle: "ignition_angle",
  industrial_heat_benchmark: "industrial_heat_benchmark",
  initial_pressure: "initial_pressure",
  initial_strata_count: "initial_strata_count",
  initial_temperature: "initial_temperature",
  inlet_gas_water_content: "inlet_gas_water_content",
  inlet_temperature: "inlet_temperature",
  intake_manifold_air_pressure: "intake_manifold_air_pressure",
  intercept: "intercept",
  intermittent_bleed_rate: "intermittent_bleed_rate",
  inverse_molar_volume: "inverse_molar_volume",
  inverse_pressure_length: "inverse_pressure_length",
  inverse_temperature: "inverse_temperature",
  iron_concentration: "iron_concentration",
  isobutane_emissions_rate: "isobutane_emissions_rate",
  isopentane_emissions_rate: "isopentane_emissions_rate",
  june_grass_actual_coverage: "june_grass_actual_coverage",
  kentucky_bluegrass_actual_coverage: "kentucky_bluegrass_actual_coverage",
  kinematic_viscosity: "kinematic_viscosity",
  kochia_actual_coverage: "kochia_actual_coverage",
  leak_rate: "leak_rate",
  leak_volume: "leak_volume",
  leakage_rate: "leakage_rate",
  leaking_time: "leaking_time",
  length: "length",
  liquid_fuel_volume: "liquid_fuel_volume",
  liquid_height: "liquid_height",
  liquid_level_rate_of_change: "liquid_level_rate_of_change",
  liquid_pumped: "liquid_pumped",
  lng_production_volume: "lng_production_volume",
  load_factor: "load_factor",
  low_bleed_rate: "low_bleed_rate",
  lower_heating_value: "lower_heating_value",
  mach_number: "mach_number",
  magnesium_concentration: "magnesium_concentration",
  magnesium_percent: "magnesium_percent",
  main_air_flow: "main_air_flow",
  manganese_concentration: "manganese_concentration",
  mass: "mass",
  mass_1_1_2_2_tetrachloroethane: "mass_1_1_2_2_tetrachloroethane",
  mass_1_1_2_trichloroethane: "mass_1_1_2_trichloroethane",
  mass_1_1_dichloroethane: "mass_1_1_dichloroethane",
  mass_1_2_3_trimethylbenzene: "mass_1_2_3_trimethylbenzene",
  mass_1_2_4_trimethylbenzene: "mass_1_2_4_trimethylbenzene",
  mass_1_2_dichloroethane: "mass_1_2_dichloroethane",
  mass_1_2_dichloropropane: "mass_1_2_dichloropropane",
  mass_1_3_5_trimethylbenzene: "mass_1_3_5_trimethylbenzene",
  mass_1_3_butadiene: "mass_1_3_butadiene",
  mass_1_3_dichloropropene: "mass_1_3_dichloropropene",
  mass_13butadiene: "mass_13butadiene",
  mass_14dichlorobenzene: "mass_14dichlorobenzene",
  mass_2_2_4_trimethylpentane: "mass_2_2_4_trimethylpentane",
  mass_2_methylnaphthalene: "mass_2_methylnaphthalene",
  mass_3_methylcholanthreneb: "mass_3_methylcholanthreneb",
  mass_7_12_dimethylbenzanthracene: "mass_7_12_dimethylbenzanthracene",
  mass_acenaphthene: "mass_acenaphthene",
  mass_acenaphthylene: "mass_acenaphthylene",
  mass_acetaldehyde: "mass_acetaldehyde",
  mass_acetonitrile: "mass_acetonitrile",
  mass_acetylene: "mass_acetylene",
  mass_acrolein: "mass_acrolein",
  mass_aldehydes: "mass_aldehydes",
  mass_anthracene: "mass_anthracene",
  mass_arsenic: "mass_arsenic",
  mass_barium: "mass_barium",
  mass_benz_a_anthracene: "mass_benz_a_anthracene",
  mass_benzanthracene: "mass_benzanthracene",
  mass_benzene: "mass_benzene",
  mass_benzo_a_anthracene: "mass_benzo_a_anthracene",
  mass_benzo_a_pyrene: "mass_benzo_a_pyrene",
  mass_benzo_b_fluoranthene: "mass_benzo_b_fluoranthene",
  mass_benzo_e_pyrene: "mass_benzo_e_pyrene",
  mass_benzo_g_h_i_perylene: "mass_benzo_g_h_i_perylene",
  mass_benzo_g_h_l_perylene: "mass_benzo_g_h_l_perylene",
  mass_benzo_k_fluoranthene: "mass_benzo_k_fluoranthene",
  mass_benzofluoranthene: "mass_benzofluoranthene",
  mass_benzopyrene: "mass_benzopyrene",
  mass_benzyl_chloride: "mass_benzyl_chloride",
  mass_beryllium: "mass_beryllium",
  mass_biphenyl: "mass_biphenyl",
  mass_butane: "mass_butane",
  mass_butene: "mass_butene",
  mass_butyr_isobutyraldehyde: "mass_butyr_isobutyraldehyde",
  mass_c1_combusted: "mass_c1_combusted",
  mass_c1_noncombusted: "mass_c1_noncombusted",
  mass_cadmium: "mass_cadmium",
  mass_carbon: "mass_carbon",
  mass_carbon_dioxide_emissions: "mass_carbon_dioxide_emissions",
  mass_carbon_dioxide_equivalent_emissions:
    "mass_carbon_dioxide_equivalent_emissions",
  mass_carbon_tetrachloride: "mass_carbon_tetrachloride",
  mass_ch4: "mass_ch4",
  mass_chlorobenzene: "mass_chlorobenzene",
  mass_chlorobenzenek: "mass_chlorobenzenek",
  mass_chloroethane: "mass_chloroethane",
  mass_chloroform: "mass_chloroform",
  mass_chromium: "mass_chromium",
  mass_chrysene: "mass_chrysene",
  mass_co: "mass_co",
  mass_co_90_105_load: "mass_co_90_105_load",
  mass_co_controlled: "mass_co_controlled",
  mass_co_less_than_90_load: "mass_co_less_than_90_load",
  mass_co2: "mass_co2",
  mass_co2_combusted: "mass_co2_combusted",
  mass_co2_content: "mass_co2_content",
  mass_co2_noncombusted: "mass_co2_noncombusted",
  mass_co2eq: "mass_co2eq",
  mass_cobalt: "mass_cobalt",
  mass_condensable_particulate: "mass_condensable_particulate",
  mass_conversion: "mass_conversion",
  mass_copper: "mass_copper",
  mass_crude_oil_rvp_5: "mass_crude_oil_rvp_5",
  mass_cycloheptane: "mass_cycloheptane",
  mass_cyclohexane: "mass_cyclohexane",
  mass_cyclopentane: "mass_cyclopentane",
  mass_decane: "mass_decane",
  mass_dibenz_a_h_anthracene: "mass_dibenz_a_h_anthracene",
  mass_dibenzo_a_h_anthracene: "mass_dibenzo_a_h_anthracene",
  mass_dichlorobenzene: "mass_dichlorobenzene",
  mass_emission_factor: "mass_emission_factor",
  mass_energy_density: "mass_energy_density",
  mass_energy_intensity: "mass_energy_intensity",
  mass_ethane: "mass_ethane",
  mass_ethane_recovery_hap_emissions: "mass_ethane_recovery_hap_emissions",
  mass_ethylbenzene: "mass_ethylbenzene",
  mass_ethylene: "mass_ethylene",
  mass_ethylene_dibromide: "mass_ethylene_dibromide",
  mass_ethylene_dichloride: "mass_ethylene_dichloride",
  mass_extraction_hap_emissions: "mass_extraction_hap_emissions",
  mass_filterable_particulate_less_than_1_micrometer:
    "mass_filterable_particulate_less_than_1_micrometer",
  mass_filterable_particulate_less_than_10_micrometer:
    "mass_filterable_particulate_less_than_10_micrometer",
  mass_filterable_particulate_less_than_3_micrometer:
    "mass_filterable_particulate_less_than_3_micrometer",
  mass_flow_rate: "mass_flow_rate",
  mass_flow_rate_efficiency: "mass_flow_rate_efficiency",
  mass_flow_rate_emissions_factor: "mass_flow_rate_emissions_factor",
  mass_flow_rate_per_length: "mass_flow_rate_per_length",
  mass_fluoranthene: "mass_fluoranthene",
  mass_fluorene: "mass_fluorene",
  mass_formaldehyde: "mass_formaldehyde",
  mass_fractionation_hap_emissions: "mass_fractionation_hap_emissions",
  mass_fugitive_hap_emissions: "mass_fugitive_hap_emissions",
  mass_fugitive_vom_emissions: "mass_fugitive_vom_emissions",
  mass_fugitive_vom_emissions_ozone: "mass_fugitive_vom_emissions_ozone",
  mass_gasoline_rvp_7: "mass_gasoline_rvp_7",
  mass_heptane: "mass_heptane",
  mass_hexane: "mass_hexane",
  mass_higher_heating_value: "mass_higher_heating_value",
  mass_indeno_1_2_3_c_d_pyrene: "mass_indeno_1_2_3_c_d_pyrene",
  mass_isobutane: "mass_isobutane",
  mass_isooctane_2_2_4_trimethylpentane:
    "mass_isooctane_2_2_4_trimethylpentane",
  mass_isopentane: "mass_isopentane",
  mass_isopropyl_benzene: "mass_isopropyl_benzene",
  mass_lead: "mass_lead",
  mass_length: "mass_length",
  mass_manganese: "mass_manganese",
  mass_mercury: "mass_mercury",
  mass_metallic_hap: "mass_metallic_hap",
  mass_methane_emissions: "mass_methane_emissions",
  mass_methanol: "mass_methanol",
  mass_methylcyclohexane: "mass_methylcyclohexane",
  mass_methylcyclopentane: "mass_methylcyclopentane",
  mass_methylene_chloride: "mass_methylene_chloride",
  mass_molybdenum: "mass_molybdenum",
  mass_n_hexane: "mass_n_hexane",
  mass_n_nonane: "mass_n_nonane",
  mass_n_octane: "mass_n_octane",
  mass_n_pentane: "mass_n_pentane",
  mass_n2o: "mass_n2o",
  mass_n2o_controlled_low_nox_burner: "mass_n2o_controlled_low_nox_burner",
  mass_n2o_uncontrolled: "mass_n2o_uncontrolled",
  mass_naphthalene: "mass_naphthalene",
  mass_nh3: "mass_nh3",
  mass_nickel: "mass_nickel",
  mass_nitrous_oxide_emissions: "mass_nitrous_oxide_emissions",
  mass_nmhc: "mass_nmhc",
  mass_nonane: "mass_nonane",
  mass_nox: "mass_nox",
  mass_nox_90_105_load: "mass_nox_90_105_load",
  mass_nox_controlled: "mass_nox_controlled",
  mass_nox_less_than_90_load: "mass_nox_less_than_90_load",
  mass_nox_uncontrolled: "mass_nox_uncontrolled",
  mass_octane: "mass_octane",
  mass_organic_hap: "mass_organic_hap",
  mass_pah: "mass_pah",
  mass_pentane: "mass_pentane",
  mass_pentene: "mass_pentene",
  mass_per_length: "mass_per_length",
  mass_per_mass_length: "mass_per_mass_length",
  mass_percentage_organic_soil_carbon: "mass_percentage_organic_soil_carbon",
  mass_percentage_organic_soil_carbon_30_100:
    "mass_percentage_organic_soil_carbon_30_100",
  mass_perylene: "mass_perylene",
  mass_phenanathrene: "mass_phenanathrene",
  mass_phenanthrene: "mass_phenanthrene",
  mass_phenol: "mass_phenol",
  mass_pm: "mass_pm",
  mass_pm_10: "mass_pm_10",
  mass_pm_condensable: "mass_pm_condensable",
  mass_pm_filterable: "mass_pm_filterable",
  mass_pm_total: "mass_pm_total",
  mass_pm10: "mass_pm10",
  mass_pm10_filterable: "mass_pm10_filterable",
  mass_pm2_5: "mass_pm2_5",
  mass_pm2_5_filterable: "mass_pm2_5_filterable",
  mass_pm2_point_5_filterable: "mass_pm2_point_5_filterable",
  mass_product: "mass_product",
  mass_propane: "mass_propane",
  mass_propylene: "mass_propylene",
  mass_propylene_oxide: "mass_propylene_oxide",
  mass_pyrene: "mass_pyrene",
  mass_r123: "mass_r123",
  mass_r134a: "mass_r134a",
  mass_r22: "mass_r22",
  mass_r401a: "mass_r401a",
  mass_r401b: "mass_r401b",
  mass_r401c: "mass_r401c",
  mass_r402a: "mass_r402a",
  mass_r402b: "mass_r402b",
  mass_r403b: "mass_r403b",
  mass_r404a: "mass_r404a",
  mass_r406a: "mass_r406a",
  mass_r407a: "mass_r407a",
  mass_r407b: "mass_r407b",
  mass_r407c: "mass_r407c",
  mass_r407d: "mass_r407d",
  mass_r407e: "mass_r407e",
  mass_r408a: "mass_r408a",
  mass_r409a: "mass_r409a",
  mass_r410a: "mass_r410a",
  mass_r410b: "mass_r410b",
  mass_r411a: "mass_r411a",
  mass_r411b: "mass_r411b",
  mass_r413a: "mass_r413a",
  mass_r414a: "mass_r414a",
  mass_r414b: "mass_r414b",
  mass_r417a: "mass_r417a",
  mass_r422a: "mass_r422a",
  mass_r422d: "mass_r422d",
  mass_r423a: "mass_r423a",
  mass_r424a: "mass_r424a",
  mass_r426a: "mass_r426a",
  mass_r428a: "mass_r428a",
  mass_r434a: "mass_r434a",
  mass_r500: "mass_r500",
  mass_r502: "mass_r502",
  mass_r504: "mass_r504",
  mass_r507: "mass_r507",
  mass_r508a: "mass_r508a",
  mass_r508b: "mass_r508b",
  mass_refrigerant: "mass_refrigerant",
  mass_selenium: "mass_selenium",
  mass_so: "mass_so",
  mass_so2: "mass_so2",
  mass_sox: "mass_sox",
  mass_styrene: "mass_styrene",
  mass_sulphur: "mass_sulphur",
  mass_tetrachloroethane: "mass_tetrachloroethane",
  mass_tetrachloroethylene: "mass_tetrachloroethylene",
  mass_toc: "mass_toc",
  mass_toluene: "mass_toluene",
  mass_total_filterable_particulate: "mass_total_filterable_particulate",
  mass_total_hap: "mass_total_hap",
  mass_total_pah: "mass_total_pah",
  mass_total_particulate: "mass_total_particulate",
  mass_total_pm_10: "mass_total_pm_10",
  mass_tpm: "mass_tpm",
  mass_trichloroethylene: "mass_trichloroethylene",
  mass_trimethylbenzene: "mass_trimethylbenzene",
  mass_vanadium: "mass_vanadium",
  mass_vinyl_chloride: "mass_vinyl_chloride",
  mass_vinylidene_chloride: "mass_vinylidene_chloride",
  mass_voc: "mass_voc",
  mass_voc_controlled: "mass_voc_controlled",
  mass_vocs: "mass_vocs",
  mass_water: "mass_water",
  mass_xylene: "mass_xylene",
  mass_xylenes: "mass_xylenes",
  mass_zinc: "mass_zinc",
  materiality: "materiality",
  max_adjustment_factor: "max_adjustment_factor",
  mean_annual_precipitation: "mean_annual_precipitation",
  mean_annual_temperature: "mean_annual_temperature",
  mercury_emissions_intensity: "mercury_emissions_intensity",
  mercury_emissions_intensity_mfre: "mercury_emissions_intensity_mfre",
  metallic_hap_emissions_intensity: "metallic_hap_emissions_intensity",
  meter_count: "meter_count",
  meter_leak_time: "meter_leak_time",
  methane_concentration: "methane_concentration",
  methane_emissions_rate: "methane_emissions_rate",
  methane_energy_intensity: "methane_energy_intensity",
  methane_intensity: "methane_intensity",
  methylcyclohexane_emissions_rate: "methylcyclohexane_emissions_rate",
  methylcyclopentane_emissions_rate: "methylcyclopentane_emissions_rate",
  middle_temperature: "middle_temperature",
  min_adjustment_factor: "min_adjustment_factor",
  mineralizable_nitrogen_concentration: "mineralizable_nitrogen_concentration",
  mol_1_2_4_trimethylbenzene_content: "mol_1_2_4_trimethylbenzene_content",
  mol_2_2_4_trimethylpentane_content: "mol_2_2_4_trimethylpentane_content",
  mol_benzene_content: "mol_benzene_content",
  mol_c1_content: "mol_c1_content",
  mol_c10_content: "mol_c10_content",
  mol_c10_plus_content: "mol_c10_plus_content",
  mol_c11_content: "mol_c11_content",
  mol_c12_content: "mol_c12_content",
  mol_c12_plus_content: "mol_c12_plus_content",
  mol_c13_content: "mol_c13_content",
  mol_c14_content: "mol_c14_content",
  mol_c15_content: "mol_c15_content",
  mol_c15_plus_content: "mol_c15_plus_content",
  mol_c2_content: "mol_c2_content",
  mol_c3_content: "mol_c3_content",
  mol_c4_content: "mol_c4_content",
  mol_c5_content: "mol_c5_content",
  mol_c5_plus_content: "mol_c5_plus_content",
  mol_c6_content: "mol_c6_content",
  mol_c6_plus_content: "mol_c6_plus_content",
  mol_c7_content: "mol_c7_content",
  mol_c7_plus_composition: "mol_c7_plus_composition",
  mol_c7_plus_content: "mol_c7_plus_content",
  mol_c8_content: "mol_c8_content",
  mol_c9_content: "mol_c9_content",
  mol_carbon_content: "mol_carbon_content",
  mol_cc5_content: "mol_cc5_content",
  mol_co_content: "mol_co_content",
  mol_co2_content: "mol_co2_content",
  mol_cyclohexane_content: "mol_cyclohexane_content",
  mol_cyclopentane_content: "mol_cyclopentane_content",
  mol_ethylbenzene_content: "mol_ethylbenzene_content",
  mol_h2_content: "mol_h2_content",
  mol_h2o_content: "mol_h2o_content",
  mol_h2s_content: "mol_h2s_content",
  mol_he_content: "mol_he_content",
  mol_ic4_content: "mol_ic4_content",
  mol_ic5_content: "mol_ic5_content",
  mol_m_p_xylene_content: "mol_m_p_xylene_content",
  mol_methylcyclohexane_content: "mol_methylcyclohexane_content",
  mol_methylcyclopentane_content: "mol_methylcyclopentane_content",
  mol_n2_content: "mol_n2_content",
  mol_nc10_content: "mol_nc10_content",
  mol_nc4_content: "mol_nc4_content",
  mol_nc5_content: "mol_nc5_content",
  mol_nc6_content: "mol_nc6_content",
  mol_nc7_content: "mol_nc7_content",
  mol_nc8_content: "mol_nc8_content",
  mol_nc9_content: "mol_nc9_content",
  mol_nec5_content: "mol_nec5_content",
  mol_neo_hexane_content: "mol_neo_hexane_content",
  mol_no_content: "mol_no_content",
  mol_no2_content: "mol_no2_content",
  mol_nox_content: "mol_nox_content",
  mol_o_xylene_content: "mol_o_xylene_content",
  mol_o2_content: "mol_o2_content",
  mol_thc_content: "mol_thc_content",
  mol_toluene_content: "mol_toluene_content",
  mol_vocs_content: "mol_vocs_content",
  molar_density: "molar_density",
  molar_volume_conversion_factor: "molar_volume_conversion_factor",
  molecular_weight: "molecular_weight",
  molecular_weight_released_gas: "molecular_weight_released_gas",
  molecular_weights_fraction: "molecular_weights_fraction",
  motor_gas_fuel_volume: "motor_gas_fuel_volume",
  mwmix: "mwmix",
  n_butane_emissions_rate: "n_butane_emissions_rate",
  n_butane_volume: "n_butane_volume",
  n_hexane_emissions_rate: "n_hexane_emissions_rate",
  n_pentane_emissions_rate: "n_pentane_emissions_rate",
  n2o_emission_factor: "n2o_emission_factor",
  n2o_emissions_intensity: "n2o_emissions_intensity",
  n2o_emissions_intensity_mfre: "n2o_emissions_intensity_mfre",
  n2o_energy_intensity: "n2o_energy_intensity",
  n2o_global_warming_potential: "n2o_global_warming_potential",
  naphthalene_emissions_intensity: "naphthalene_emissions_intensity",
  natural_gas_feed_rate: "natural_gas_feed_rate",
  nee_gpp: "nee_gpp",
  net_primary_productivity_co2e: "net_primary_productivity_co2e",
  ngl_production_volume: "ngl_production_volume",
  nitrate_nitrogen_concentration: "nitrate_nitrogen_concentration",
  nitrogen_actuated_devices_count: "nitrogen_actuated_devices_count",
  nitrogen_energy_intensity: "nitrogen_energy_intensity",
  nitrogen_fixers_count: "nitrogen_fixers_count",
  non_routine_planned_flared_fuel_volume:
    "non_routine_planned_flared_fuel_volume",
  non_routine_planned_flared_production_volume:
    "non_routine_planned_flared_production_volume",
  non_routine_planned_vented_fuel_volume:
    "non_routine_planned_vented_fuel_volume",
  non_routine_planned_vented_production_volume:
    "non_routine_planned_vented_production_volume",
  non_routine_release_duration: "non_routine_release_duration",
  non_routine_release_pressure: "non_routine_release_pressure",
  non_routine_release_rate: "non_routine_release_rate",
  non_routine_release_temperature: "non_routine_release_temperature",
  non_routine_release_volume: "non_routine_release_volume",
  non_routine_unplanned_flared_fuel_volume:
    "non_routine_unplanned_flared_fuel_volume",
  non_routine_unplanned_flared_production_volume:
    "non_routine_unplanned_flared_production_volume",
  non_routine_unplanned_vented_fuel_volume:
    "non_routine_unplanned_vented_fuel_volume",
  non_routine_unplanned_vented_production_volume:
    "non_routine_unplanned_vented_production_volume",
  nonanes_emissions_rate: "nonanes_emissions_rate",
  nox_emission_factor_input_based: "nox_emission_factor_input_based",
  nox_emission_factor_output_based: "nox_emission_factor_output_based",
  nox_emissions_intensity: "nox_emissions_intensity",
  nox_emissions_intensity_mfre: "nox_emissions_intensity_mfre",
  nox_emissions_intensity_mfre_gas: "nox_emissions_intensity_mfre_gas",
  nox_emissions_rate: "nox_emissions_rate",
  nps_size: "nps_size",
  number_blowdowns: "number_blowdowns",
  number_components: "number_components",
  number_compressors: "number_compressors",
  number_depressures: "number_depressures",
  number_engine_starts: "number_engine_starts",
  number_sources: "number_sources",
  number_vents: "number_vents",
  octanes_emissions_rate: "octanes_emissions_rate",
  oil_processed_volume: "oil_processed_volume",
  oil_tank_liquid_throughput: "oil_tank_liquid_throughput",
  oil_tank_upstream_vessel_pressure: "oil_tank_upstream_vessel_pressure",
  one_two_four_trimethylbenzene_emissions_rate:
    "one_two_four_trimethylbenzene_emissions_rate",
  open_ended_line_component_count: "open_ended_line_component_count",
  open_ended_line_count: "open_ended_line_count",
  open_ended_line_leak_time: "open_ended_line_leak_time",
  operating_power_rating: "operating_power_rating",
  operating_time: "operating_time",
  organic_hap_emissions_intensity: "organic_hap_emissions_intensity",
  other_components_count: "other_components_count",
  other_components_leak_time: "other_components_leak_time",
  other_emissions_rate: "other_emissions_rate",
  outlet_gas_water_content: "outlet_gas_water_content",
  outlet_temperature: "outlet_temperature",
  oxidation_air: "oxidation_air",
  paddock_count: "paddock_count",
  path_integrated_concentration: "path_integrated_concentration",
  pentanes_emissions_rate: "pentanes_emissions_rate",
  pentanes_plus_volume: "pentanes_plus_volume",
  percent_excluded_area: "percent_excluded_area",
  percentage_large_coarse_fragments: "percentage_large_coarse_fragments",
  percentage_large_coarse_fragments_30_100:
    "percentage_large_coarse_fragments_30_100",
  perennial_grasses_forb_cover: "perennial_grasses_forb_cover",
  performance_standard: "performance_standard",
  performance_standard_allocation: "performance_standard_allocation",
  petrinex_acid_gas: "petrinex_acid_gas",
  petrinex_disp: "petrinex_disp",
  petrinex_flare: "petrinex_flare",
  petrinex_fuel: "petrinex_fuel",
  petrinex_prod: "petrinex_prod",
  petrinex_rec: "petrinex_rec",
  petrinex_vent: "petrinex_vent",
  ph_buffer: "ph_buffer",
  ph_scale: "ph_scale",
  phosphorus_bicarb_concentration: "phosphorus_bicarb_concentration",
  phosphorus_percent: "phosphorus_percent",
  pigging_pipeline_pressure: "pigging_pipeline_pressure",
  pilot_uptime: "pilot_uptime",
  pipe_inside_diameter: "pipe_inside_diameter",
  pipe_length: "pipe_length",
  pipe_outside_diameter: "pipe_outside_diameter",
  pipe_wall_thickness: "pipe_wall_thickness",
  pipeline_length: "pipeline_length",
  plastic_composite_length: "plastic_composite_length",
  plume_height: "plume_height",
  pm_2_5_emission_factor_input_based: "pm_2_5_emission_factor_input_based",
  pm_2_5_emission_factor_output_based: "pm_2_5_emission_factor_output_based",
  pm_condensable_pm_emissions_intensity:
    "pm_condensable_pm_emissions_intensity",
  pm_condensable_pm_emissions_intensity_mfre:
    "pm_condensable_pm_emissions_intensity_mfre",
  pm_emissions_intensity: "pm_emissions_intensity",
  pm_emissions_intensity_mfre: "pm_emissions_intensity_mfre",
  pm_filterable_pm_emissions_intensity: "pm_filterable_pm_emissions_intensity",
  pm_filterable_pm_emissions_intensity_mfre:
    "pm_filterable_pm_emissions_intensity_mfre",
  pm_total_emission_factor_input_based: "pm_total_emission_factor_input_based",
  pm_total_emission_factor_output_based:
    "pm_total_emission_factor_output_based",
  pm_total_emissions_rate: "pm_total_emissions_rate",
  pm10_emission_factor_input_based: "pm10_emission_factor_input_based",
  pm10_emission_factor_output_based: "pm10_emission_factor_output_based",
  pm10_emissions_intensity: "pm10_emissions_intensity",
  pm10_emissions_intensity_mfre: "pm10_emissions_intensity_mfre",
  pm10_emissions_rate: "pm10_emissions_rate",
  pm10_energy_intensity: "pm10_energy_intensity",
  pm10_filterable_pm_emissions_intensity:
    "pm10_filterable_pm_emissions_intensity",
  pm10_filterable_pm_emissions_intensity_mfre:
    "pm10_filterable_pm_emissions_intensity_mfre",
  pm2_5_emissions_intensity: "pm2_5_emissions_intensity",
  pm2_5_emissions_rate: "pm2_5_emissions_rate",
  pm2_5_filterable_pm_emissions_intensity:
    "pm2_5_filterable_pm_emissions_intensity",
  pm2_5_filterable_pm_emissions_intensity_mfre:
    "pm2_5_filterable_pm_emissions_intensity_mfre",
  pm2_5_pm_emissions_intensity: "pm2_5_pm_emissions_intensity",
  pm2_5_pm_emissions_intensity_mfre: "pm2_5_pm_emissions_intensity_mfre",
  pollutants_visible_time: "pollutants_visible_time",
  population_emissions_factor: "population_emissions_factor",
  position: "position",
  potassium_concentration: "potassium_concentration",
  potassium_magnesium_ratio: "potassium_magnesium_ratio",
  potassium_percent: "potassium_percent",
  potential_npp: "potential_npp",
  potential_soc_accrual_rate: "potential_soc_accrual_rate",
  power: "power",
  power_rating: "power_rating",
  prairie_rose_actual_coverage: "prairie_rose_actual_coverage",
  pressure: "pressure",
  pressure_drop: "pressure_drop",
  pressure_relief_valve_component_count:
    "pressure_relief_valve_component_count",
  pressure_relief_valve_count: "pressure_relief_valve_count",
  pressure_relief_valve_leak_time: "pressure_relief_valve_leak_time",
  proc_c_1_mx_volume: "proc_c_1_mx_volume",
  proc_c_2_mx_volume: "proc_c_2_mx_volume",
  proc_c_2_sp_volume: "proc_c_2_sp_volume",
  proc_c_3_mx_volume: "proc_c_3_mx_volume",
  proc_c_3_sp_volume: "proc_c_3_sp_volume",
  proc_c_4_mx_volume: "proc_c_4_mx_volume",
  proc_c_4_sp_volume: "proc_c_4_sp_volume",
  proc_c_5_mx_volume: "proc_c_5_mx_volume",
  proc_c_5_sp_volume: "proc_c_5_sp_volume",
  proc_c_6_mx_volume: "proc_c_6_mx_volume",
  proc_c_6_sp_volume: "proc_c_6_sp_volume",
  proc_cond_volume: "proc_cond_volume",
  proc_ethane_mx_volume: "proc_ethane_mx_volume",
  proc_gas_volume: "proc_gas_volume",
  proc_ic_4_mx_volume: "proc_ic_4_mx_volume",
  proc_ic_4_sp_volume: "proc_ic_4_sp_volume",
  proc_ic_5_mx_volume: "proc_ic_5_mx_volume",
  proc_ic_5_sp_volume: "proc_ic_5_sp_volume",
  proc_lite_mx_volume: "proc_lite_mx_volume",
  proc_litemx_volume: "proc_litemx_volume",
  proc_nc_4_mx_volume: "proc_nc_4_mx_volume",
  proc_nc_4_sp_volume: "proc_nc_4_sp_volume",
  proc_nc_5_mx_volume: "proc_nc_5_mx_volume",
  proc_nc_5_sp_volume: "proc_nc_5_sp_volume",
  proc_oil_volume: "proc_oil_volume",
  proc_sulphur_mass: "proc_sulphur_mass",
  process_burner_devices_count: "process_burner_devices_count",
  prod_c_1_mx_volume: "prod_c_1_mx_volume",
  prod_c_2_mx_volume: "prod_c_2_mx_volume",
  prod_c_2_sp_volume: "prod_c_2_sp_volume",
  prod_c_3_mx_volume: "prod_c_3_mx_volume",
  prod_c_3_sp_volume: "prod_c_3_sp_volume",
  prod_c_4_mx_volume: "prod_c_4_mx_volume",
  prod_c_4_sp_volume: "prod_c_4_sp_volume",
  prod_c_5_mx_volume: "prod_c_5_mx_volume",
  prod_c_5_sp_volume: "prod_c_5_sp_volume",
  prod_c_6_mx_volume: "prod_c_6_mx_volume",
  prod_c_6_sp_volume: "prod_c_6_sp_volume",
  prod_cond_volume: "prod_cond_volume",
  prod_gas_volume: "prod_gas_volume",
  prod_ic_4_mx_volume: "prod_ic_4_mx_volume",
  prod_ic_4_sp_volume: "prod_ic_4_sp_volume",
  prod_ic_5_mx_volume: "prod_ic_5_mx_volume",
  prod_ic_5_sp_volume: "prod_ic_5_sp_volume",
  prod_litemx_volume: "prod_litemx_volume",
  prod_nc_4_mx_volume: "prod_nc_4_mx_volume",
  prod_nc_4_sp_volume: "prod_nc_4_sp_volume",
  prod_nc_5_mx_volume: "prod_nc_5_mx_volume",
  prod_nc_5_sp_volume: "prod_nc_5_sp_volume",
  prod_oil_volume: "prod_oil_volume",
  produced_flow_rate: "produced_flow_rate",
  produced_water_pond_throughput: "produced_water_pond_throughput",
  produced_water_tank_liquid_throughput:
    "produced_water_tank_liquid_throughput",
  produced_water_tank_upstream_vessel_pressure:
    "produced_water_tank_upstream_vessel_pressure",
  production_level: "production_level",
  production_volume: "production_volume",
  projected_soc_accrual_rate: "projected_soc_accrual_rate",
  propane_emissions_rate: "propane_emissions_rate",
  propane_odorized_volume: "propane_odorized_volume",
  propane_volume: "propane_volume",
  protected_steel_length: "protected_steel_length",
  pseudomonas_count: "pseudomonas_count",
  pump_count: "pump_count",
  pump_seal_leak_time: "pump_seal_leak_time",
  ratio_co2_to_carbon: "ratio_co2_to_carbon",
  rec_c_1_mx_volume: "rec_c_1_mx_volume",
  rec_c_2_mx_volume: "rec_c_2_mx_volume",
  rec_c_2_sp_volume: "rec_c_2_sp_volume",
  rec_c_3_mx_volume: "rec_c_3_mx_volume",
  rec_c_3_sp_volume: "rec_c_3_sp_volume",
  rec_c_4_mx_volume: "rec_c_4_mx_volume",
  rec_c_4_sp_volume: "rec_c_4_sp_volume",
  rec_c_5_mx_volume: "rec_c_5_mx_volume",
  rec_c_5_sp_volume: "rec_c_5_sp_volume",
  rec_c_6_mx_volume: "rec_c_6_mx_volume",
  rec_c_6_sp_volume: "rec_c_6_sp_volume",
  rec_cond_volume: "rec_cond_volume",
  rec_gas_volume: "rec_gas_volume",
  rec_ic_4_mx_volume: "rec_ic_4_mx_volume",
  rec_ic_4_sp_volume: "rec_ic_4_sp_volume",
  rec_ic_5_mx_volume: "rec_ic_5_mx_volume",
  rec_ic_5_sp_volume: "rec_ic_5_sp_volume",
  rec_litemx_volume: "rec_litemx_volume",
  rec_nc_4_mx_volume: "rec_nc_4_mx_volume",
  rec_nc_4_sp_volume: "rec_nc_4_sp_volume",
  rec_nc_5_mx_volume: "rec_nc_5_mx_volume",
  rec_nc_5_sp_volume: "rec_nc_5_sp_volume",
  rec_oil_volume: "rec_oil_volume",
  receipt_volume: "receipt_volume",
  recovered_gas_fraction: "recovered_gas_fraction",
  recovery_time: "recovery_time",
  refilling_time: "refilling_time",
  relative_humidity: "relative_humidity",
  relative_pressure: "relative_pressure",
  relative_tank_level: "relative_tank_level",
  release_duration: "release_duration",
  release_pressure: "release_pressure",
  release_rate: "release_rate",
  release_temperature: "release_temperature",
  release_volume: "release_volume",
  rest_period: "rest_period",
  rhizobium_count: "rhizobium_count",
  rim_seal_loss_factor: "rim_seal_loss_factor",
  rolling_avg_mass_fugitive_hap_emissions:
    "rolling_avg_mass_fugitive_hap_emissions",
  rolling_avg_mass_fugitive_vom_emissions:
    "rolling_avg_mass_fugitive_vom_emissions",
  rotation_frequency: "rotation_frequency",
  rye_grass_actual_coverage: "rye_grass_actual_coverage",
  s_and_w: "s_and_w",
  salt_concentration: "salt_concentration",
  sand_percent: "sand_percent",
  sand_reed_grass_actual_coverage: "sand_reed_grass_actual_coverage",
  saturation_factor: "saturation_factor",
  scrubber_length: "scrubber_length",
  scrubber_outside_diameter: "scrubber_outside_diameter",
  scrubber_wall_thickness: "scrubber_wall_thickness",
  silver_wormwood_actual_coverage: "silver_wormwood_actual_coverage",
  slender_wheatgrass_actual_coverage: "slender_wheatgrass_actual_coverage",
  slit_percent: "slit_percent",
  slope: "slope",
  slope_roof: "slope_roof",
  smartweed_actual_coverage: "smartweed_actual_coverage",
  smooth_brome_actual_coverage: "smooth_brome_actual_coverage",
  smooth_horsetail_actual_coverage: "smooth_horsetail_actual_coverage",
  so2_concetration: "so2_concetration",
  so2_content: "so2_content",
  so2_emissions_intensity: "so2_emissions_intensity",
  so2_emissions_rate: "so2_emissions_rate",
  so2_total_emissions: "so2_total_emissions",
  soc_content: "soc_content",
  soc_discount_value: "soc_discount_value",
  soc_percentage: "soc_percentage",
  sodium_concentration: "sodium_concentration",
  sodium_percent: "sodium_percent",
  soil_active_carbon: "soil_active_carbon",
  soil_depth_to_restrictive_layer: "soil_depth_to_restrictive_layer",
  soil_moisture_percent: "soil_moisture_percent",
  soil_total_carbon_percent: "soil_total_carbon_percent",
  sour_water_acid_gas_flow: "sour_water_acid_gas_flow",
  sour_water_acid_gas_inlet_pressure: "sour_water_acid_gas_inlet_pressure",
  sour_water_acid_gas_inlet_temperature:
    "sour_water_acid_gas_inlet_temperature",
  sox_emissions_intensity: "sox_emissions_intensity",
  specific_gravity: "specific_gravity",
  specific_heat: "specific_heat",
  specific_volume: "specific_volume",
  start_duration: "start_duration",
  start_max_fuel_consumption_rate: "start_max_fuel_consumption_rate",
  static_pressure: "static_pressure",
  steam_mass: "steam_mass",
  stoichiometric_ratio: "stoichiometric_ratio",
  stroke_length: "stroke_length",
  strokes_per_minute: "strokes_per_minute",
  substance_amount: "substance_amount",
  suction_pressure: "suction_pressure",
  sulfur_content: "sulfur_content",
  sulfur_volume_fraction: "sulfur_volume_fraction",
  sulphur: "sulphur",
  sulphur_concentration: "sulphur_concentration",
  surface_energy: "surface_energy",
  surface_mass: "surface_mass",
  surface_mass_k2o: "surface_mass_k2o",
  surface_mass_n: "surface_mass_n",
  surface_mass_p2o5: "surface_mass_p2o5",
  surface_volume: "surface_volume",
  tank_level: "tank_level",
  temperature: "temperature",
  temperature_area_per_energy: "temperature_area_per_energy",
  temperature_per_volume_fraction: "temperature_per_volume_fraction",
  temperature_reading_front_zone: "temperature_reading_front_zone",
  temperature_reading_rear_zone: "temperature_reading_rear_zone",
  terminal_throughput_volume: "terminal_throughput_volume",
  testing_time: "testing_time",
  thc_emissions_rate: "thc_emissions_rate",
  theoretical_volume: "theoretical_volume",
  thermal_efficiency: "thermal_efficiency",
  throughput: "throughput",
  throughput_boe: "throughput_boe",
  time: "time",
  time_conversion: "time_conversion",
  time_interval: "time_interval",
  toluene_emissions_intensity: "toluene_emissions_intensity",
  toluene_emissions_rate: "toluene_emissions_rate",
  top_temperature: "top_temperature",
  topographic_slope: "topographic_slope",
  total_bacteria_count: "total_bacteria_count",
  total_crude_oil_cond_production: "total_crude_oil_cond_production",
  total_dry_gas_volume: "total_dry_gas_volume",
  total_electricity_generated: "total_electricity_generated",
  total_gram_negatives_count: "total_gram_negatives_count",
  total_hap_emissions_intensity: "total_hap_emissions_intensity",
  total_land_area: "total_land_area",
  total_microbial_activity_count: "total_microbial_activity_count",
  total_wet_gas_volume: "total_wet_gas_volume",
  tpm_emission_factor_input_based: "tpm_emission_factor_input_based",
  tpm_emission_factor_output_based: "tpm_emission_factor_output_based",
  tpm_emissions_intensity: "tpm_emissions_intensity",
  tree_shrub_cover: "tree_shrub_cover",
  trichoderma_count: "trichoderma_count",
  tubing_internal_diameter: "tubing_internal_diameter",
  tubing_pressure: "tubing_pressure",
  two_two_four_trimethylpentane_emissions_rate:
    "two_two_four_trimethylpentane_emissions_rate",
  typical_dry_gas_flow_rate: "typical_dry_gas_flow_rate",
  uncertainty: "uncertainty",
  uncontrolled_benzene_emissions: "uncontrolled_benzene_emissions",
  uncontrolled_thc_emissions_rate: "uncontrolled_thc_emissions_rate",
  universal_gas_constant: "universal_gas_constant",
  unknown_mushroom_actual_coverage: "unknown_mushroom_actual_coverage",
  unlit_feed_gas: "unlit_feed_gas",
  unprotected_steel_length: "unprotected_steel_length",
  upright_prairie_coneflower_actual_coverage:
    "upright_prairie_coneflower_actual_coverage",
  upstream_volume: "upstream_volume",
  valve_component_count: "valve_component_count",
  valve_count: "valve_count",
  valve_leak_time: "valve_leak_time",
  vapor_concentration: "vapor_concentration",
  vapor_space_expansion_factor: "vapor_space_expansion_factor",
  vapour_pressure: "vapour_pressure",
  variation_in_soc_accrual_rate: "variation_in_soc_accrual_rate",
  velocity_conversion_constant: "velocity_conversion_constant",
  vent_rate: "vent_rate",
  vent_time: "vent_time",
  vented_vapor_saturation_factor: "vented_vapor_saturation_factor",
  vessel_outside_diameter: "vessel_outside_diameter",
  vessel_wall_thickness: "vessel_wall_thickness",
  voc_emission_factor_input_based: "voc_emission_factor_input_based",
  voc_emission_factor_output_based: "voc_emission_factor_output_based",
  voc_emissions_intensity: "voc_emissions_intensity",
  voc_emissions_intensity_mfre: "voc_emissions_intensity_mfre",
  voc_emissions_intensity_mfre_gas: "voc_emissions_intensity_mfre_gas",
  voc_emissions_rate: "voc_emissions_rate",
  voc_energy_intensity: "voc_energy_intensity",
  vol_2_2_4_trimethylpentane_emissions_intensity:
    "vol_2_2_4_trimethylpentane_emissions_intensity",
  vol_acetaldehyde_emissions_intensity: "vol_acetaldehyde_emissions_intensity",
  vol_acrolein_emissions_intensity: "vol_acrolein_emissions_intensity",
  vol_ammonia_emissions_intensity: "vol_ammonia_emissions_intensity",
  vol_benzene_content: "vol_benzene_content",
  vol_benzene_emissions_intensity: "vol_benzene_emissions_intensity",
  vol_biomass: "vol_biomass",
  vol_c1_content: "vol_c1_content",
  vol_c10_plus_content: "vol_c10_plus_content",
  vol_c2_content: "vol_c2_content",
  vol_c3_content: "vol_c3_content",
  vol_c4_content: "vol_c4_content",
  vol_c5_content: "vol_c5_content",
  vol_c5_plus_content: "vol_c5_plus_content",
  vol_c6_content: "vol_c6_content",
  vol_c6_plus_content: "vol_c6_plus_content",
  vol_c7_content: "vol_c7_content",
  vol_c7_plus_composition: "vol_c7_plus_composition",
  vol_c8_content: "vol_c8_content",
  vol_c9_content: "vol_c9_content",
  vol_carbon_dioxide_emissions: "vol_carbon_dioxide_emissions",
  vol_carbon_dioxide_equivalent_emissions:
    "vol_carbon_dioxide_equivalent_emissions",
  vol_cc5_content: "vol_cc5_content",
  vol_ch4_emissions_factor: "vol_ch4_emissions_factor",
  vol_ch4_emissions_factor_gas: "vol_ch4_emissions_factor_gas",
  vol_ch4_emissions_factor_liquid: "vol_ch4_emissions_factor_liquid",
  vol_ch4_in_natural_gas: "vol_ch4_in_natural_gas",
  vol_ch4_recovered: "vol_ch4_recovered",
  vol_ch4_unrecovered: "vol_ch4_unrecovered",
  vol_co_content: "vol_co_content",
  vol_co_emissions_intensity: "vol_co_emissions_intensity",
  vol_co2_content: "vol_co2_content",
  vol_co2_emissions_factor: "vol_co2_emissions_factor",
  vol_co2_emissions_factor_gas: "vol_co2_emissions_factor_gas",
  vol_co2_emissions_factor_liquid: "vol_co2_emissions_factor_liquid",
  vol_co2_recovered: "vol_co2_recovered",
  vol_co2_unrecovered: "vol_co2_unrecovered",
  vol_co2e_emissions_factor: "vol_co2e_emissions_factor",
  vol_concentration_emissions_factor: "vol_concentration_emissions_factor",
  vol_ethylbenzene_emissions_intensity: "vol_ethylbenzene_emissions_intensity",
  vol_flow_rate_emissions_factor: "vol_flow_rate_emissions_factor",
  vol_formaldehyde_emissions_intensity: "vol_formaldehyde_emissions_intensity",
  vol_fossil_fuel: "vol_fossil_fuel",
  vol_gas_recovered: "vol_gas_recovered",
  vol_gas_unrecovered: "vol_gas_unrecovered",
  vol_h2_content: "vol_h2_content",
  vol_h2s_content: "vol_h2s_content",
  vol_he_content: "vol_he_content",
  vol_hexane_emissions_intensity: "vol_hexane_emissions_intensity",
  vol_higher_heating_value: "vol_higher_heating_value",
  vol_ic4_content: "vol_ic4_content",
  vol_ic5_content: "vol_ic5_content",
  vol_mercury_emissions_intensity: "vol_mercury_emissions_intensity",
  vol_metallic_hap_emissions_intensity: "vol_metallic_hap_emissions_intensity",
  vol_methane_emissions: "vol_methane_emissions",
  vol_n_hexane_emissions_intensity: "vol_n_hexane_emissions_intensity",
  vol_n2_content: "vol_n2_content",
  vol_n2o_emissions_factor: "vol_n2o_emissions_factor",
  vol_n2o_emissions_factor_gas: "vol_n2o_emissions_factor_gas",
  vol_n2o_emissions_factor_liquid: "vol_n2o_emissions_factor_liquid",
  vol_naphthalene_emissions_intensity: "vol_naphthalene_emissions_intensity",
  vol_nc4_content: "vol_nc4_content",
  vol_nc5_content: "vol_nc5_content",
  vol_nec5_content: "vol_nec5_content",
  vol_nitrous_oxide_emissions: "vol_nitrous_oxide_emissions",
  vol_no_content: "vol_no_content",
  vol_no2_content: "vol_no2_content",
  vol_nox_content: "vol_nox_content",
  vol_nox_emissions_intensity: "vol_nox_emissions_intensity",
  vol_o2_content: "vol_o2_content",
  vol_organic_hap_emissions_intensity: "vol_organic_hap_emissions_intensity",
  vol_pm_condensable_pm_emissions_intensity:
    "vol_pm_condensable_pm_emissions_intensity",
  vol_pm_emissions_intensity: "vol_pm_emissions_intensity",
  vol_pm_filterable_pm_emissions_intensity:
    "vol_pm_filterable_pm_emissions_intensity",
  vol_pm10_emissions_intensity: "vol_pm10_emissions_intensity",
  vol_pm10_filterable_pm_emissions_intensity:
    "vol_pm10_filterable_pm_emissions_intensity",
  vol_pm2_5_filterable_pm_emissions_intensity:
    "vol_pm2_5_filterable_pm_emissions_intensity",
  vol_pm2_5_pm_emissions_intensity: "vol_pm2_5_pm_emissions_intensity",
  vol_so2_emissions_intensity: "vol_so2_emissions_intensity",
  vol_sox_emissions_intensity: "vol_sox_emissions_intensity",
  vol_toluene_emissions_intensity: "vol_toluene_emissions_intensity",
  vol_total_hap_emissions_intensity: "vol_total_hap_emissions_intensity",
  vol_voc_emissions_intensity: "vol_voc_emissions_intensity",
  vol_xylene_emissions_intensity: "vol_xylene_emissions_intensity",
  volume: "volume",
  volume_ch4: "volume_ch4",
  volume_co2: "volume_co2",
  volume_concentration: "volume_concentration",
  volume_concentration_per_pressure: "volume_concentration_per_pressure",
  volume_conversion: "volume_conversion",
  volume_conversion_emission_factor: "volume_conversion_emission_factor",
  volume_emission_factor: "volume_emission_factor",
  volume_flare: "volume_flare",
  volume_flow_rate_per_pressure: "volume_flow_rate_per_pressure",
  volume_fraction: "volume_fraction",
  volume_gas: "volume_gas",
  volume_gas_production: "volume_gas_production",
  volume_gas_sales: "volume_gas_sales",
  volume_oil: "volume_oil",
  volume_oil_production: "volume_oil_production",
  volume_oil_sales: "volume_oil_sales",
  volume_vent: "volume_vent",
  volume_water: "volume_water",
  volume_y_grade_sales: "volume_y_grade_sales",
  water_ex_organic_carbon_concentration:
    "water_ex_organic_carbon_concentration",
  well_depth: "well_depth",
  well_open_time: "well_open_time",
  western_snowberry_actual_coverage: "western_snowberry_actual_coverage",
  western_wheatgrass_actual_coverage: "western_wheatgrass_actual_coverage",
  wet_gas_pressure: "wet_gas_pressure",
  wet_gas_production_volume: "wet_gas_production_volume",
  wet_gas_temperature: "wet_gas_temperature",
  white_panicle_aster_actual_coverage: "white_panicle_aster_actual_coverage",
  white_prairie_aster_actual_coverage: "white_prairie_aster_actual_coverage",
  white_sweet_clover_actual_coverage: "white_sweet_clover_actual_coverage",
  wind_direction: "wind_direction",
  wind_speed: "wind_speed",
  wind_variation: "wind_variation",
  work_duration: "work_duration",
  working_loss: "working_loss",
  working_loss_cond_ch4: "working_loss_cond_ch4",
  working_loss_cond_voc: "working_loss_cond_voc",
  workover_fuel_volume: "workover_fuel_volume",
  workover_with_fracturing_count: "workover_with_fracturing_count",
  workover_without_fracturing_count: "workover_without_fracturing_count",
  wt_1_2_4_trimethylbenzene_content: "wt_1_2_4_trimethylbenzene_content",
  wt_2_2_4_trimethylpentane_content: "wt_2_2_4_trimethylpentane_content",
  wt_benzene_content: "wt_benzene_content",
  wt_c1_content: "wt_c1_content",
  wt_c10_content: "wt_c10_content",
  wt_c10_plus_content: "wt_c10_plus_content",
  wt_c11_content: "wt_c11_content",
  wt_c12_content: "wt_c12_content",
  wt_c13_content: "wt_c13_content",
  wt_c14_content: "wt_c14_content",
  wt_c15_content: "wt_c15_content",
  wt_c2_content: "wt_c2_content",
  wt_c3_content: "wt_c3_content",
  wt_c4_content: "wt_c4_content",
  wt_c5_content: "wt_c5_content",
  wt_c5_plus_content: "wt_c5_plus_content",
  wt_c6_content: "wt_c6_content",
  wt_c6_plus_content: "wt_c6_plus_content",
  wt_c7_content: "wt_c7_content",
  wt_c7_plus_composition: "wt_c7_plus_composition",
  wt_c8_content: "wt_c8_content",
  wt_c9_content: "wt_c9_content",
  wt_cc5_content: "wt_cc5_content",
  wt_co_content: "wt_co_content",
  wt_co2_content: "wt_co2_content",
  wt_cyclohexane_content: "wt_cyclohexane_content",
  wt_ethylbenzene_content: "wt_ethylbenzene_content",
  wt_formaldehyde_content: "wt_formaldehyde_content",
  wt_h2_content: "wt_h2_content",
  wt_h2s_content: "wt_h2s_content",
  wt_he_content: "wt_he_content",
  wt_ic4_content: "wt_ic4_content",
  wt_ic5_content: "wt_ic5_content",
  wt_methylcyclopentane_content: "wt_methylcyclopentane_content",
  wt_n_hexane_content: "wt_n_hexane_content",
  wt_n2_content: "wt_n2_content",
  wt_n2o_content: "wt_n2o_content",
  wt_nc4_content: "wt_nc4_content",
  wt_nc5_content: "wt_nc5_content",
  wt_nec5_content: "wt_nec5_content",
  wt_no_content: "wt_no_content",
  wt_no2_content: "wt_no2_content",
  wt_nox_content: "wt_nox_content",
  wt_o2_ar_content: "wt_o2_ar_content",
  wt_o2_content: "wt_o2_content",
  wt_pm10_content: "wt_pm10_content",
  wt_pm2_5_content: "wt_pm2_5_content",
  wt_salt_content: "wt_salt_content",
  wt_so2_content: "wt_so2_content",
  wt_sox_content: "wt_sox_content",
  wt_toluene_content: "wt_toluene_content",
  wt_voc_content: "wt_voc_content",
  wt_vocs_content: "wt_vocs_content",
  wt_vom_content: "wt_vom_content",
  wt_xylene_content: "wt_xylene_content",
  xylene_emissions_intensity: "xylene_emissions_intensity",
  xylenes_emissions_rate: "xylenes_emissions_rate",
  ytd_mass_fugitive_hap_emissions: "ytd_mass_fugitive_hap_emissions",
  ytd_mass_fugitive_vom_emissions: "ytd_mass_fugitive_vom_emissions",
  zinc_concentration: "zinc_concentration",
} as const;

export const MeasurementQuantitySchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .describe("The domain model schema for a Carbon Hub Measurement Quantity");

export const MeasurementUnitSchema = z
  .object({
    id: z.string(),
    quantity: z.string(),
    name: z.object({
      singular: z.string(),
      plural: z.string(),
      symbol: z.string(),
    }),
    to_anchor: z.number(),
    anchor_shift: z.number(),
  })
  .describe("The domain model for a Carbon Hub Measurement Unit");

export const MeasurementTypeSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    quantity: z.string(),
    default_unit: z.string(),
    default_unit_info: MeasurementUnitSchema.omit({ id: true }),
  })
  .describe("The domain model for a Carbon Hub Measurement Type (V2)");

export const UnitConfigurationSchema = z.object({
  // IMPROVE: types are generic for now. better to fold in directly from Node
  // API when their API SDK is ready
  measurement_type: z.string(),
  // IMPROVE: units are generic for now. better to fold in directly from Node
  // API when their API SDK is ready
  measurement_unit: z.string(),
  precision: z.number(),
  company_id: z.string(),
});

export type MeasurementQuantityType = z.infer<typeof MeasurementQuantitySchema>;
export type MeasurementTypeType = z.infer<typeof MeasurementTypeSchema>;
export type MeasurementUnitType = z.infer<typeof MeasurementUnitSchema>;
export type UnitConfigurationType = z.infer<typeof UnitConfigurationSchema>;

export const TimeMetricUnits = {
  d: "d",
  h: "h",
  min: "min",
  month: "month",
  ms: "ms",
  s: "s",
  week: "week",
  year: "year",
} as const;
export type TimeMetricUnitsType =
  (typeof TimeMetricUnits)[keyof typeof TimeMetricUnits];

export type TimeUnitsType = TimeMetricUnitsType;
