import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useQuery } from "@tanstack/react-query";
import {
  MeasurementTypeAdapter,
  type MeasurementQuantityType,
  type MeasurementTypeType,
  type MeasurementUnitType,
} from "@validereinc/domain";
import React, { createContext, ReactNode, useContext } from "react";

const DEFAULT_PRECISION = null;

export const MeasurementTypeContext = createContext<{
  /** all measurement quantities across all companies */
  measurementQuantities: MeasurementQuantityType[];
  /** all measurement types available across all companies */
  measurementTypes: MeasurementTypeType[];
  /** all measurement units available across all companies */
  measurementUnits: MeasurementUnitType[];
  /**
   * get the displayed name of a measurement type
   * @param type measurement type string literal
   */
  getTypeName: (type: string) => string;
  /**
   * get the full unit name of a unit
   * @param unit unit string literal
   */
  getUnitName: (unit: string, value?: number) => string;
  /**
   * get the units applicable for a given quantity
   * @param quantity quantity string literal
   */
  getUnitsByQuantity: (quantity?: string) => MeasurementUnitType[];
  /**
   * get the precision to apply for numbers for a given measurement type
   * @param type measurement type string literal
   */
  getPrecisionByType: (type: string) => number | null;
  /**
   * get the units for a given measurement type
   * @param type measurement type string literal
   */
  getUnitByType: (type: string) => string;
  isLoading: boolean;
} | null>(null);

export const MeasurementTypeProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { isAuthenticated } = useAuthenticatedContext();

  const measurementQuantitiesQuery = useQuery({
    queryKey: ["measurementQuantities"],
    queryFn: () => MeasurementTypeAdapter.listMeasurementQuantities(),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const measurementQuantities = measurementQuantitiesQuery.data ?? [];

  const measurementTypesQuery = useQuery({
    queryKey: ["measurementTypesV2"],
    queryFn: () => MeasurementTypeAdapter.listMeasurementTypes(),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const measurementTypes = measurementTypesQuery.data ?? [];

  const measurementUnitsQuery = useQuery({
    queryKey: ["measurementUnits"],
    queryFn: () => MeasurementTypeAdapter.listMeasurementUnits(),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const measurementUnits = measurementUnitsQuery.data ?? [];

  const userUnitsQuery = useQuery({
    queryKey: ["userUnitConfig"],
    queryFn: () => MeasurementTypeAdapter.listUserUnitConfigurations(),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const userUnits = userUnitsQuery.data?.data ?? [];

  const companyUnitsQuery = useQuery({
    queryKey: ["companyUnitConfig"],
    queryFn: () => MeasurementTypeAdapter.listCompanyUnitConfigurations(),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const companyUnits = companyUnitsQuery.data?.data ?? [];

  const getUnitByType = (type: string) => {
    const measurementType = measurementTypes.find(({ id }) => id === type);
    if (!measurementType) {
      return "";
    }

    return (
      userUnits.find(({ measurement_type }) => measurement_type === type)
        ?.measurement_unit ??
      companyUnits.find(({ measurement_type }) => measurement_type === type)
        ?.measurement_unit ??
      measurementType.default_unit
    );
  };

  const getPrecisionByType = (type: string) => {
    return (
      userUnits.find(({ measurement_type }) => measurement_type === type)
        ?.precision ??
      companyUnits.find(({ measurement_type }) => measurement_type === type)
        ?.precision ??
      DEFAULT_PRECISION
    );
  };

  const getTypeName = (type: string) =>
    measurementTypes.find(({ id }: MeasurementTypeType) => id === type)?.name ??
    type;

  const getUnitName = (unit: string) =>
    measurementUnits.find(({ id }: MeasurementUnitType) => id === unit)?.name
      ?.symbol ?? unit;

  const getUnitsByQuantity = (quantity?: string) =>
    quantity
      ? measurementUnits.filter(
          (unit: MeasurementUnitType) => unit.quantity === quantity
        )
      : measurementUnits;

  return (
    <MeasurementTypeContext.Provider
      value={{
        measurementQuantities,
        measurementTypes,
        measurementUnits,
        getTypeName,
        getUnitName,
        getUnitsByQuantity,
        getPrecisionByType,
        getUnitByType,
        isLoading:
          measurementQuantitiesQuery.isFetching ||
          measurementTypesQuery.isFetching ||
          measurementUnitsQuery.isFetching ||
          companyUnitsQuery.isFetching ||
          userUnitsQuery.isFetching,
      }}
    >
      {children}
    </MeasurementTypeContext.Provider>
  );
};

export const useMeasurementTypes = () => {
  const measurementMetadata = useContext(MeasurementTypeContext);

  if (!measurementMetadata) {
    throw new Error("No MeasurementTypeProvider found");
  }

  return measurementMetadata;
};
