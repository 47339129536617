import { z } from "zod";
import type { AssetTypeType } from "./AssetSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import {
  SystemActionType,
  UserTaskType,
  WorkflowStepType,
} from "./WorkflowConfigSchemas";
import { WorkflowSchema, type WorkflowStatusType } from "./WorkflowSchemas";
import type { WorkflowTemplateStatusType } from "./WorkflowTemplateSchemas";
import { TypeOfObjectValues } from "../util";

export const WorkflowTaskNotificationEventType = {
  CREATED: "created",
  STATUS_CHANGED: "status_changed",
  ASSIGNEE_CHANGED: "assignee_changed",
} as const;

export const WorkflowTaskNotificationRecipientType = {
  WORKFLOW_OWNER: "workflow_owner",
  ASSIGNEE: "assignee",
  ASSIGNEE_USER: "assignee_user",
  ASSIGNEE_GROUP: "assignee_group",
  USERS: "users",
} as const;

export const WorkflowTaskTypes = {
  ...SystemActionType,
  ...UserTaskType,
} as const;

export type WorkflowTaskTypesType = TypeOfObjectValues<
  typeof WorkflowTaskTypes
>;

export const WorkflowTaskStatus = {
  OPEN: "open",
  COMPLETE: "complete",
  DISMISSED: "dismissed",
  OVERDUE: "overdue",
  ERROR: "error", //TODO remove later: frontend only currently, until backend adds statuses for system actions
} as const;

export type WorkflowTaskStatusType = TypeOfObjectValues<
  typeof WorkflowTaskStatus
>;

export const WorkflowTaskBaseSchema = z
  .object({
    name: z.string(),
    type: z.nativeEnum(WorkflowTaskTypes),
    step_id: z.string(),
    step_type: z
      .enum([WorkflowStepType.SYSTEM_ACTION, WorkflowStepType.USER_TASK])
      .optional(),
    status: z.enum([
      WorkflowTaskStatus.OPEN,
      WorkflowTaskStatus.COMPLETE,
      WorkflowTaskStatus.DISMISSED,
      WorkflowTaskStatus.OVERDUE,
      WorkflowTaskStatus.ERROR, //TODO remove later: frontend only currently, until backend adds statuses for system actions
    ]),
    description: z.string(),
    is_dismissible: z.boolean(),
    assignee_user: z.string().uuid().nullable(),
    assignee_group: z.string().uuid().nullable(),
    restrict_assignee_completion: z.boolean().nullable(),
    workflow_id: z.string(),
    workflow: WorkflowSchema.omit({
      workflow_template_id: true,
      facility_id: true,
    }),
    company_id: z.string(),
    form_submission: z.undefined(),
    emission_event: z
      .array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
        })
      )
      .optional(),
    entity_id: z.string().uuid().optional(),
    entity_name: z.string().optional(),
    due_date: z.string().optional(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema.pick({ updated_by: true }))
  .describe("The domain model schema for a Workflow Task");

export const WorkflowTaskWithFormSubmissionSchema =
  WorkflowTaskBaseSchema.merge(
    z.object({
      config_form_schema: z
        .object({
          id: z.string(),
          name: z.string(),
        })
        .optional(),
      form_submission: z.array(
        z
          .object({
            status: z.enum([
              "validated",
              "invalidated",
              "pending",
              "submitted",
              "draft",
            ]),
            form_schema: z
              .object({
                name: z.string(),
              })
              .merge(DomainModelSchema),
          })
          .merge(DomainModelSchema)
          .merge(DomainModelMetaSchema)
          .merge(DomainModelMetaExtendedSchema)
      ),
    })
  );

export const WorkflowTaskSchema = z.union([
  WorkflowTaskBaseSchema,
  WorkflowTaskWithFormSubmissionSchema,
]);

export type WorkflowTaskType = z.infer<typeof WorkflowTaskSchema>;
export type WorkflowTaskWithFormSubmissionType = z.infer<
  typeof WorkflowTaskWithFormSubmissionSchema
>;

export type WorkflowTaskGetListFilterType = Partial<
  Pick<
    WorkflowTaskType,
    | "id"
    | "name"
    | "status"
    | "workflow_id"
    | "created_at"
    | "updated_at"
    | "description"
    | "type"
    | "assignee_user"
    | "assignee_group"
  > & {
    "assignee_group.user_id": string;
    "facility.id": string;
    "facility.name": string;
    "facility.status": string;
    "workflow_template.id": string;
    "workflow_template.category_id": string;
    "workflow_template.name": string;
    "workflow_template.description": string;
    "workflow_template.asset_type": AssetTypeType;
    "workflow_template.status": WorkflowTemplateStatusType;
    "workflow_category.id": string;
    "workflow_category.name": string;
    "workflow_category.description": string;
    "workflow.facility_id": string;
    "workflow.due_date": string;
    "workflow.status": WorkflowStatusType;
  }
>;

/**
 * Type predicate to determine if a workflow task is a base workflow task or one
 * with form submissions attached
 * @param task a workflow task
 * @returns true with a type assertion if the workflow task is one with form submissions
 */
export const isWorkflowTaskWithFormSubmission = (
  task: WorkflowTaskType
): task is WorkflowTaskWithFormSubmissionType =>
  task.form_submission !== undefined && Array.isArray(task.form_submission);

export const isUserTask = (type?: WorkflowTaskTypesType) => {
  if (!type) return false;
  return (
    [
      WorkflowTaskTypes.CHOICE,
      WorkflowTaskTypes.FORM_CHOICE,
      WorkflowTaskTypes.SUBMIT_FORM,
      WorkflowTaskTypes.COMPLETE_EVENT,
      WorkflowTaskTypes.MANUAL_TASK,
    ] as WorkflowTaskTypesType[]
  ).includes(type);
};
