import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { StorageKeys, StorageType } from "@validereinc/common-components";
import kebabCase from "lodash/kebabCase";

export const getStorageKey = (
  key: string,
  type: string,
  userId?: string,
  companyId?: string
) => [type, userId, companyId, kebabCase(key)].filter((c) => !!c).join("-");

export const useStorageKey = (key: string) => {
  const {
    v2: {
      userInfo: { user },
      companyInfo: { company },
    },
  } = useAuthenticatedContext();

  return Object.fromEntries(
    Object.entries(StorageType).map(([typeKey, typeValue]) => [
      `${typeKey as keyof typeof StorageType}StorageKey`,
      getStorageKey(key, typeValue, user?.id, company?.id),
    ])
  ) as StorageKeys;
};

export const CustomStorageKeyTypes = {
  CONFIG: "config",
  REPORT: "report",
  JOB: "job",
};

export type CustomStorageKeyType =
  (typeof CustomStorageKeyTypes)[keyof typeof CustomStorageKeyTypes];

export const useCustomStorageKey = (
  key: string,
  type: CustomStorageKeyType
) => {
  const {
    v2: {
      companyInfo: { company },
    },
  } = useAuthenticatedContext();

  return getStorageKey(key, type, company?.id);
};
