import PermissionAwareExportButton from "#src/batteries-included-components/Buttons/PermissionAwareExportButton/PermissionAwareExportButton";
import { type UserActivityLogFiltersType } from "#src/batteries-included-components/FilterAreas/UsersFilterAreas/UsersFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useExportActivityLogs,
  useListActivityLogs,
} from "#src/components/hooks/adapters/useActivityLogs";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useNavigate } from "#src/Routers/hooks";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { getPropertyAsMap } from "#src/utils/objectFormatter";
import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  type HeaderType,
  Pill,
  StorageKeys,
} from "@validereinc/common-components";
import {
  ActivitiesDomain,
  ActivityAction,
  ActivityExportResource,
  ActivityResource,
  ActivitySchema,
  type ActivityType,
  SortDirection,
  UserGroupsAdapter,
  type UserGroupType,
  type UserType,
} from "@validereinc/domain";
import isEmpty from "lodash/isEmpty";
import React, { useMemo, useState } from "react";

const activityModelKeys = ActivitySchema.keyof().Enum;

type UserGroupActivityLogBeforeAfter = { user_id: string };

export const UserGroupsActivityLogTablePanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const [canWriteReports] = useHasPermission("reports:write");
  const navigate = useNavigate();
  const [viewFilters] = useSessionStickyState<UserActivityLogFiltersType>(
    {} as UserActivityLogFiltersType,
    viewConfigStorageKey
  );

  const { resource_id, logged_by, logged_at } = viewFilters;

  const [tableState, updateTableState] = useTableSortingAndPagination({
    sortBy: "timestamp",
    sortDirection: SortDirection.DESCENDING,
  });
  const [selectedActivities, setSelectedActivities] = useState<
    Record<string, ActivityType>
  >({});

  const viewFiltersToApply = {
    ...(resource_id && { resource_id }),
    ...(logged_by && { author_id: logged_by }),
    ...(logged_at && { timestamp: logged_at }),
  };

  const activitiesQueryPayload = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...viewFiltersToApply,
      action: [ActivityAction.USER_ADD, ActivityAction.USER_REMOVE],
    },
    meta: { resourceType: ActivityResource.USER_GROUP },
  } satisfies Parameters<typeof ActivitiesDomain.getList>[0];

  const { data: activitiesData, isLoading: areActivityLogsLoading } =
    useListActivityLogs(activitiesQueryPayload);

  const activities = activitiesData?.data;

  // We cannot use the BE to filter on user IDs nested in before/after
  const filteredActivities = useMemo(() => {
    if (!viewFilters.user_id || !activities) {
      return activities ?? [];
    }

    return activities.filter((activity) => {
      const beforeUserId = (activity.before as UserGroupActivityLogBeforeAfter)
        ?.user_id;
      const afterUserId = (activity.after as UserGroupActivityLogBeforeAfter)
        ?.user_id;
      return (
        viewFilters.user_id?.includes(beforeUserId) ||
        viewFilters.user_id?.includes(afterUserId)
      );
    });
  }, [activities, viewFilters.user_id]);

  const processActivityUserGroups = useMemo(() => {
    const uniqueUserIds = new Set<string>();
    const uniqueUserGroupIds = new Set<string>();

    activities?.forEach((activity: ActivityType) => {
      uniqueUserIds.add(activity.author_id);
      const beforeUserId = (activity.before as UserGroupActivityLogBeforeAfter)
        ?.user_id;
      const afterUserId = (activity.after as UserGroupActivityLogBeforeAfter)
        ?.user_id;

      if (beforeUserId) uniqueUserIds.add(beforeUserId);
      if (afterUserId) uniqueUserIds.add(afterUserId);

      uniqueUserGroupIds.add(activity.resource_id);
    });

    return {
      uniqueUserIds,
      uniqueUserGroupIds,
    };
  }, [activities]);

  const { uniqueUserIds, uniqueUserGroupIds } = processActivityUserGroups;

  const associatedUsersQueries = useGetManyUsers(Array.from(uniqueUserIds));

  const userIdsToUserDetailsMap = useMemo(() => {
    return getPropertyAsMap(
      associatedUsersQueries,
      "data",
      "data.id"
    ) as Record<string, UserType>;
  }, [associatedUsersQueries]);

  const associatedUserGroupQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UserGroupsAdapter.getOne>> | undefined,
        unknown,
        UserGroupType | undefined
      >
    >
  >({
    queries:
      Array.from(uniqueUserGroupIds).map((userGroupId) => ({
        queryKey: ["users", "groups", userGroupId],
        queryFn: () => {
          if (!userGroupId) {
            return;
          }

          return UserGroupsAdapter.getOne({ id: userGroupId });
        },
        enabled: Boolean(userGroupId),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const userGroupIdsToUserGroupDetailsMap = useMemo(() => {
    return getPropertyAsMap(
      associatedUserGroupQueries,
      "data",
      "data.id"
    ) as Record<string, UserType>;
  }, [associatedUserGroupQueries]);

  const exportActivityLogPayload = {
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...(!isEmpty(selectedActivities)
        ? { id: Object.keys(selectedActivities) }
        : viewFilters.user_id
          ? {
              id: filteredActivities.map(
                (filteredActvity) => filteredActvity.id
              ),
            }
          : {}),
      ...viewFiltersToApply,
    },
    meta: { resourceType: ActivityExportResource.USER_GROUP },
  } satisfies Parameters<typeof ActivitiesDomain.exportList>[0];

  const { mutate: handleExport, isLoading: isExporting } =
    useExportActivityLogs(exportActivityLogPayload);

  const isLoading =
    areActivityLogsLoading ||
    associatedUsersQueries.some((query) => query.isLoading) ||
    associatedUserGroupQueries.some((query) => query.isLoading);

  const headers = useMemo<Array<HeaderType<ActivityType>>>(
    () => [
      {
        key: "user",
        label: "User",
        renderComponent: ({ item }) => {
          const userId =
            (item.before as UserGroupActivityLogBeforeAfter)?.user_id ??
            (item.after as UserGroupActivityLogBeforeAfter)?.user_id ??
            "";
          const userName = userIdsToUserDetailsMap[userId]?.name;
          return userId && userName ? (
            <RoutingLink to={linkToUserDetailPage(userId)}>
              {userName}
            </RoutingLink>
          ) : (
            "-"
          );
        },
      },
      {
        key: activityModelKeys.action,
        label: "Type",
        renderComponent: ({ item }) => {
          return item.action === ActivityAction.USER_ADD ? (
            <Pill
              variant="success"
              isCapitalized={false}
              isLoading={isLoading}
            >
              Added to Group
            </Pill>
          ) : item.action === ActivityAction.USER_REMOVE ? (
            <Pill
              variant="error"
              isCapitalized={false}
              isLoading={isLoading}
            >
              Removed from Group
            </Pill>
          ) : null;
        },
      },
      {
        key: activityModelKeys.resource_id,
        label: "User Group",
        renderComponent: ({ item }) => {
          const userGroupId = item.resource_id;
          const userGroupName =
            userGroupIdsToUserGroupDetailsMap[userGroupId]?.name;
          return userGroupId && userGroupName ? (
            <Pill
              variant="primary"
              isCapitalized={false}
              isBordered
              isLoading={isLoading}
            >
              <div
                onClick={() => {
                  navigate({
                    pathname: UserGroupDetailsRoutePath.toLink({
                      pathParams: { groupId: userGroupId },
                    }),
                  });
                }}
              >
                {userGroupName}
              </div>
            </Pill>
          ) : (
            "-"
          );
        },
      },
      {
        key: activityModelKeys.author_id,
        label: "Logged By",
        renderComponent: ({ item }) => {
          const authorId = item.author_id;
          const authorName = userIdsToUserDetailsMap[authorId]?.name ?? "-";
          return authorId ? (
            <RoutingLink to={linkToUserDetailPage(authorId)}>
              {authorName}
            </RoutingLink>
          ) : (
            "-"
          );
        },
      },
      {
        key: activityModelKeys.timestamp,
        label: "Logged At",
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item[activityModelKeys.timestamp]}
            withTime
            isLoading={isLoading}
          />
        ),
      },
    ],
    [userIdsToUserDetailsMap, userGroupIdsToUserGroupDetailsMap]
  );

  const actionRow = [
    ...(canWriteReports
      ? [
          <PermissionAwareExportButton
            key="export-roles-permissions-activity-log"
            onClick={handleExport}
            isExporting={isExporting}
          />,
        ]
      : []),
  ];

  return (
    <DataTablePanel
      actionRowWhenNoRowsSelected={actionRow}
      actionRowWhenRowsSelected={actionRow}
      dataTableProps={{
        headers,
        items: filteredActivities ?? [],
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: activitiesData?.total_entries,
          pageSizeText: "logs per page",
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        selected: selectedActivities,
        onSelectionChange: setSelectedActivities,
        getItemId: (item) => item.id,
      }}
      panelProps={{
        title: "User Group Change Log",
      }}
    />
  );
};
