import { UserActivityLogFilterArea } from "#src/batteries-included-components/FilterAreas/UsersFilterAreas/UsersFilterAreas";
import { UserGroupsActivityLogTablePanel } from "#src/batteries-included-components/Panels/TablePanels/UserGroupsActviityLogTablePanel";
import { UserRolesActivityLogTablePanel } from "#src/batteries-included-components/Panels/TablePanels/UserRolesActivityLogTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { ButtonToggleGroup } from "@validereinc/common-components";
import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./UserActivityLogTab.module.scss";

const cx = classNames.bind(styles);

export const UserActivityTabOption = {
  ROLES: "roles",
  USER_GROUPS: "user_groups",
} as const;
export type UserActivityTabOptionType =
  (typeof UserActivityTabOption)[keyof typeof UserActivityTabOption];

export const UserActivityLogTab = ({
  isUserGroupsAvailable,
}: {
  isUserGroupsAvailable: boolean;
}) => {
  const { viewConfigStorageKey } = useStorageKey("user-activity-log");

  const [mode, setMode] = useState<UserActivityTabOptionType>(
    UserActivityTabOption.ROLES
  );

  const shouldDisplayUserRolesActivityLog =
    mode === UserActivityTabOption.ROLES;
  const shouldDisplayUserGroupsActivityLog =
    mode === UserActivityTabOption.USER_GROUPS;

  return (
    <>
      <div className={cx("container")}>
        <ButtonToggleGroup
          className={cx("button-toggle-group")}
          activeKey={mode}
          items={[
            { label: "User Roles", dataKey: UserActivityTabOption.ROLES },
            {
              label: "User Groups",
              dataKey: UserActivityTabOption.USER_GROUPS,
            },
          ]}
          onChange={(val) => setMode(val as UserActivityTabOptionType)}
        />

        <UserActivityLogFilterArea
          viewConfigStorageKey={`${viewConfigStorageKey}-${shouldDisplayUserRolesActivityLog ? UserActivityTabOption.ROLES : UserActivityTabOption.USER_GROUPS}`}
          shouldShowRolesDropdown={shouldDisplayUserRolesActivityLog}
          shouldShowUserGroupsDropdown={shouldDisplayUserGroupsActivityLog}
        />
      </div>
      {shouldDisplayUserRolesActivityLog ? (
        <UserRolesActivityLogTablePanel
          viewConfigStorageKey={`${viewConfigStorageKey}-${UserActivityTabOption.ROLES}`}
        />
      ) : null}
      {shouldDisplayUserGroupsActivityLog && isUserGroupsAvailable ? (
        <UserGroupsActivityLogTablePanel
          viewConfigStorageKey={`${viewConfigStorageKey}-${UserActivityTabOption.USER_GROUPS}`}
        />
      ) : null}
    </>
  );
};
