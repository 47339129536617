import { WorkflowTaskStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { IconLoading, Pill } from "@validereinc/common-components";
import {
  WorkflowStepType,
  WorkflowTaskStatus,
  WorkflowTaskType,
  WorkflowType,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";
import { getSystemActionStatus } from "./WorkflowstepStatusPill.helpers";

export const WorkflowStepStatusPill = ({
  workflow,
  workflowSteps,
  workflowStep,
  isLoading,
  shouldShowIcon,
}: {
  workflowStep: WorkflowTaskType;
  workflowSteps: WorkflowTaskType[];
  workflow: WorkflowType;
  isLoading?: boolean;
  shouldShowIcon: boolean;
}) => {
  const isStepSystemAction =
    workflowStep.step_type === WorkflowStepType.SYSTEM_ACTION;

  const status = (() => {
    if (!isStepSystemAction) {
      return workflowStep.status;
    }
    return getSystemActionStatus({
      workflow,
      workflowSteps,
      workflowStep,
    });
  })();

  const isSystemActionRunning = (() => {
    if (!isStepSystemAction) {
      return false;
    }
    return status === WorkflowTaskStatus.OPEN;
  })();

  return (
    status && (
      <Pill
        variant={WorkflowTaskStatusToPillVariantMap[status] || "default"}
        iconLeft={
          shouldShowIcon && isSystemActionRunning ? (
            <IconLoading
              speed="slow"
              size={12}
            />
          ) : null
        }
        isLoading={isLoading}
      >
        {isSystemActionRunning ? "Running" : toStartCaseString(status)}
      </Pill>
    )
  );
};
