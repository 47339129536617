import { useNavigate } from "#src/Routers/hooks";
import PermissionAwareExportButton from "#src/batteries-included-components/Buttons/PermissionAwareExportButton/PermissionAwareExportButton";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#src/components/Redux/reducers/tableStateReducer";
import { useExportUsers } from "#src/components/hooks/adapters/useUsers";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { RoleContext } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { linkToEditRoleUsersPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/users/edit";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  HeaderType,
  useToast,
} from "@validereinc/common-components";
import {
  Resources,
  RoleUserMembershipType,
  RolesAdapter,
} from "@validereinc/domain";
import React, { useContext, useMemo, useState } from "react";

export const RoleUsersTablePanel = () => {
  const queryClient = useQueryClient();
  const { tableConfigStorageKey } = useStorageKey("roles-users");
  const { toast } = useToast();
  const navigate = useNavigate();
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const [userToRemove, setUserToRemove] = useState<
    RoleUserMembershipType["user"] | null
  >(null);
  const { roleQuery, roleMembersQuery, isCurrentUserInRole } =
    useContext(RoleContext) ?? {};
  const [tableState, setTableState] = useTableSortingAndPagination();
  const { items, pagination, sorting } = useClientSideSortingAndPagination(
    roleMembersQuery?.data ?? [],
    tableState
  );

  const removeUserFromRoleMutation = useMutation({
    mutationFn: ({
      roleId,
      user,
    }: {
      roleId: string;
      user: { id: string; name: string };
    }) => {
      return RolesAdapter.members.delete({
        roleId,
        userId: user.id,
      });
    },
    onSuccess: (_, { user }) => {
      queryClient.invalidateQueries({ queryKey: ["roles"] });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: [Resources.ACTIVITY] });
      toast.push({
        intent: "success",
        description: `Successfully removed ${user.name ?? "user"} from role`,
      });
    },
    onError: (_, { user }) => {
      toast.push({
        intent: "success",
        description: `Failed to remove ${user.name ?? "user"} from role`,
      });
    },
    onMutate: () => {
      setUserToRemove(null);
    },
  });

  const eligibleUserIdsToExport = useMemo(
    () => roleMembersQuery?.data?.map((membership) => membership.user_id) ?? [],
    [roleMembersQuery?.data]
  );

  const { mutate: handleExport, isLoading: isExporting } = useExportUsers({
    filters: {
      id: eligibleUserIdsToExport,
    },
    sortBy: tableState.sortBy.replace("user.", ""), // Sending sort_by as 'user.name' or 'user.email' will fail the request
    sortDirection: tableState.sortDirection,
  });

  const headers: Array<HeaderType<RoleUserMembershipType>> = [
    {
      label: "Name",
      key: "user.name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToUserDetailPage(item?.user_id)}>
          {item?.user.name}
          {currentUserId === item?.user_id ? " (You)" : ""}
        </RoutingLink>
      ),
    },
    {
      label: "Email",
      key: "user.email",
      renderComponent: ({ item }) => item?.user.email ?? "-",
    },
    {
      label: "Effective Date",
      key: "created_at",
      tooltip: "When the user was assigned this role",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const getItemActions = ({ item }: { item?: RoleUserMembershipType }) => {
    return [
      {
        label: "Remove",
        buttonProps: {
          onClick: () => setUserToRemove(item?.user ?? null),
          icon: "trash",
          disabled: !item,
        },
      },
    ];
  };

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        actionRowWhenNoRowsSelected={[
          <PermissionAwareExportButton
            key="export-users"
            onClick={handleExport}
            isExporting={isExporting}
            isDisabled={!eligibleUserIdsToExport.length}
          />,

          <Button
            key="assign-users"
            onClick={() => {
              if (!roleQuery?.data?.id) {
                return;
              }

              navigate({
                pathname: linkToEditRoleUsersPage(roleQuery.data.id),
              });
            }}
          >
            Assign Users
          </Button>,
        ]}
        dataTableProps={{
          headers,
          items,
          pagination,
          sorting,
          onPaginationChange: setTableState,
          onSortChange: setTableState,
          isLoading: roleMembersQuery?.isLoading,
          getItemActions,
        }}
        panelProps={{ title: "Members", loaded: !roleMembersQuery?.isLoading }}
      />

      <Dialog
        isOpen={!!userToRemove}
        onClose={() => setUserToRemove(null)}
        title="Remove User"
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() => {
              if (!roleQuery?.data?.id || !userToRemove) {
                return;
              }

              removeUserFromRoleMutation.mutate({
                roleId: roleQuery.data.id,
                user: {
                  id: userToRemove.id,
                  name: userToRemove.name,
                },
              });
            }}
            variant="error"
            disabled={!roleQuery?.data?.id || !userToRemove}
          >
            Remove User
          </Button>,
        ]}
      >
        Are you sure you want to remove{" "}
        {isCurrentUserInRole ? "yourself" : userToRemove?.name ?? "this user"}{" "}
        from this role?
      </Dialog>
    </>
  );
};
