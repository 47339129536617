import { TemplatedReportsGeneratedReportsRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]/generated";
import { RoutePath } from "#src/utils/route";

export const TemplatedReportsGeneratedReportDetailsRoutePath =
  TemplatedReportsGeneratedReportsRoutePath.concat(
    new RoutePath<"reportId", "version" | "taskId">({
      title: "Report",
      path: "/:reportId",
    })
  );
